import React from "react";
import { Redirect, Route } from "react-router-dom";

const TwoFactorPrivateRoute = ({ component: Component, ...rest }) => {
    return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("isAuthenticatedOTP") !== null &&
        localStorage.getItem("isAuthenticatedOTP") === "1" ? (
          <Component {...props} />
        ) : localStorage.getItem("token") !== null ? (
          <Redirect
            to={{
              pathname: "/maindashboard",
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default TwoFactorPrivateRoute;
