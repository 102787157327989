import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Notestable from '../../components/notestable/notestable';
import Notesform from '../../components/notesform/notesform';
import './dashboard.css';
import { Link } from "react-router-dom";
import axios from "axios";
import Header from '../../components/NewHeader/Header';
import Logout from '../../components/logout/Logout';
const Dashboard = () => {
	const [notesData, setNotesData] = useState([]);
	const [contactsData, setContactsData] = useState([]);
	const [suggestionsData, setSuggestionsData] = useState([]);
	const [releaseMyInformation, setReleaseMyInformation] = useState({});

	useEffect(() => {
		getAllItems();
		getAllContacts();
		getReleaseMyInformation();
	}, []);

	function getAllItems() {
		var token = localStorage.getItem('token');
		axios
			.get(
				process.env.REACT_APP_SERVER +
				`inventory/getbyuserid?nUserID=${localStorage.getItem('userid')}&nContactID=0`,
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((res) => {
				setNotesData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function getAllContacts() {
		const data = {
			nUserID: localStorage.getItem('userid'),
		};
		var token = localStorage.getItem('token');
		axios
			.post(
				process.env.REACT_APP_SERVER + 'contact/listall',
				data,
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((res) => {
				const data =
					res && res.data && res.data[0].length
						? res.data[0].map((r) => {
							return {
								...r,
								sharedwith: 0,
								releaseto: 0,
							};
						})
						: [];
				setContactsData(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function getAllSuggestions() {
		var token = localStorage.getItem('token');
		axios
			.get(
				process.env.REACT_APP_SERVER + `inventory/getsuggestions`,
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((res) => {
				setSuggestionsData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function getReleaseMyInformation() {
		var token = localStorage.getItem('token');
		const data = {
			nUserID: localStorage.getItem('userid'),
		};
		axios
			.post(
				process.env.REACT_APP_SERVER +
				'user/incapacitateddeceasedsettingsget',
				data,
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((res) => {
				setReleaseMyInformation(
					res && res.data && res.data[0] ? res.data[0] : {}
				);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<section className="dashbord-section">
			<Header title="Item List" />
			<Row className="mx-auto text-center mobile-button back-logout-btn pt-2">
				<Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start profile-left-btn">
					<Link to="/maindashboard" className="btn btn-light pl-3 pr-3 pt-2 pb-2" >Back</Link>
				</Col>
				<Col lg={6} md={6} sm={6} xs={6} className="p-2 text-end profile-right-btn">
					<Logout />
				</Col>
			</Row>
			<Row className="bg-white p-4 ">
				<Col md={7}>
					<h4 className="p-2 mb-4 custom-text-color">Items</h4>
					<Notestable notesData={notesData} />
				</Col>
				<Col md={5}>
					<Notesform
						contactsData={contactsData}
						getAllItems={getAllItems}
						suggestionsData={suggestionsData}
						releaseMyInformation={releaseMyInformation}
						getReleaseMyInformation={getReleaseMyInformation}
					/>
				</Col>
			</Row>
		</section>
	);
}
export default Dashboard;