import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const headCells = [
	{
		id: 'fname',
		disablePadding: true,
		label: 'File Name',
		align: 'left',
	},
	{
		id: 'fsize',
		numeric: true,
		disablePadding: false,
		label: 'File Size',
		align: 'left',
	},
	{
		id: 'uploadDate',
		numeric: true,
		disablePadding: false,
		label: 'Upload Date',
		align: 'center',
	},
	{
		id: 'remove',
		numeric: true,
		disablePadding: false,
		label: '',
		align: 'center',
	},
];

function EnhancedTableHead() {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => (
					<TableCell key={index} align={headCell.align}>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
function getFormatedDate(uploadDate) {
	var myDate = new Date(uploadDate);

	var year = myDate.getFullYear();

	var month = myDate.getMonth() + 1;
	if (month <= 9) month = '0' + month;

	var day = myDate.getDate();
	if (day <= 9) day = '0' + day;

	return month + '/' + day + '/' + year;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	container: {
		maxHeight: 500,
		overflowX: 'scroll',
	},
	customCell: {
		padding: '8px 16px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	customHeaderCell: {
		fontSize: '16px',
	},
}));

const FileAttachedModal = ({
	fileAttachedModalShow,
	setFileAttachedModalShow,
	itemName,
	onChangeFiles,
	existingFiles,
	removedExistingFiles,
	files,
	removedFiles,
	saveMedia,
	rowid,
}) => {
	const classes = useStyles();

	const calculateTotalFileSize = () => {
		let existingTotalSize =
			(existingFiles || []).reduce(
				(total, lotObj) => total + Number(lotObj.filesize),
				0
			) || 0;
		let totalSize =
			(files || []).reduce((total, lotObj) => total + Number(lotObj.size), 0) ||
			0;
		return existingTotalSize + totalSize;
	};

	console.log(saveMedia)
	return (
		<>
			<Modal
				size="lg"
				show={fileAttachedModalShow}
				onHide={() => {
					setFileAttachedModalShow(false);
				}}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="ShareRelease-Modal-Container"
			>
				<Modal.Header closeButton className="ShareRelease-Modal-Header">
					<Modal.Title>Files Attached to Item</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-left">
						<div className="pt-3 pb-3">
							<b>Item Attachments</b>
						</div>
						{itemName ? (
							<div className="ShareRelease-Container">{itemName}</div>
						) : null}
					</div>
					<div>
						<Form.Group controlId="exampleForm.ControlInput2">
							<label>
								<b>Upload: </b>
							</label>
							<Form.File
								id="custom-file"
								label="Custom file input"
								custom
								onChange={(e) => onChangeFiles(e)}
							/>
						</Form.Group>
					</div>
					<div>
						<Paper className={classes.paper}>
							<TableContainer className={classes.container}>
								<Table
									className={classes.table}
									aria-labelledby="tableTitle"
									aria-label="enhanced table"
									stickyHeader
								>
									<EnhancedTableHead />
									<TableBody>
										{existingFiles.map((row, index) => {
											return (
												<TableRow hover tabIndex={-1} key={index}>
													<TableCell
														component="th"
														scope="row"
														align="left"
														id={index}
													>
														{row.filename}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														align="left"
														id={index}
													>
														{row.filesize}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														align="center"
														id={index}
													>
														{getFormatedDate(row.created)}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														align="center"
														id={index}
													>
														<Button
															variant="danger"
															className="mx-2 btn-sm-height"
															onClick={() => removedExistingFiles(row.id)}
														>
															Remove
														</Button>
													</TableCell>
												</TableRow>
											);
										})}
										{files && files.length
											? files.map((row, index) => {
													return (
														<TableRow hover tabIndex={-1} key={index}>
															<TableCell
																component="th"
																scope="row"
																align="left"
																id={index}
															>
																{row.name}
															</TableCell>
															<TableCell
																component="th"
																scope="row"
																align="left"
																id={index}
															>
																{row.size}
															</TableCell>
															<TableCell
																component="th"
																scope="row"
																align="center"
																id={index}
															>
																{getFormatedDate(row.lastModified)}
															</TableCell>
															<TableCell
																component="th"
																scope="row"
																align="center"
																id={index}
															>
																<Button
																	variant="danger"
																	className="mx-2 btn-sm-height"
																	onClick={() => removedFiles(index)}
																>
																	Remove
																</Button>
															</TableCell>
														</TableRow>
													);
											  })
											: null}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</div>
					<div className="d-flex align-items-center justify-content-end">
						Total File Size:{' '}
						<div className="Count-Container ml-2">
							{calculateTotalFileSize()}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button
						variant="primary"
						className="custom-btn"
						onClick={() => {
							saveMedia(rowid);
							setFileAttachedModalShow(false);
						}}
					>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default FileAttachedModal;
