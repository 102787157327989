import React, {useEffect, useState} from 'react'
import Header from "../../components/NewHeader/Header";
import {Button, RadioGroup, TextField} from "@material-ui/core";
import {Col, Row, Table} from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import {Link, useParams} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import axios from "axios";
import  "./ViewReleasedWish.css";
import genie_img from '../../assets/images/genie_image.jpg'
import fileAttach from '../../assets/images/ic_attatch.png';
import Add from '../../assets/images/Add.png';
import ic_users from '../../assets/images/ic_users.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import Back from "../../assets/images/Back_white.png";
import {format} from "date-fns";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const ViewReleasedWish = () => {

    const id = localStorage.getItem('userid')
    const token = localStorage.getItem('token')
    const [Releasedewishes, setReleasedWishes] = useState([])
    const [mediaDetails, setmediaDetails] = useState([])
    const idDetails = useParams()
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [showLegend, setShowLegend] = useState(false);

    const handleCloseDailog = () => {
        setShowLegend(!showLegend)
    }

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(true)
        axios.get(process.env.REACT_APP_SERVER + `inventory/getreleasedtowhom?nUserID=${id}`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            setReleasedWishes(res.data)
            setOpen(false)
        }).catch((err) => {
            console.log(err)
        })
        getMedia()
    },[])

    const getMedia = () => {
        const token = localStorage.getItem('token');
        axios
            .post(process.env.REACT_APP_SERVER + 'inventory/getmedia',
                {nInvItemID: idDetails.id},
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then((res) => {
                setmediaDetails(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const downloadImage = (media) => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userid');
        axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${media.s3filename}&nUserID=${userId}`,
            {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                let link = document.createElement('a');
                link.setAttribute('href', res.data.url);
                link.setAttribute('download', media.filename);
                link.click();
            })
    }

    const filterD = Releasedewishes.find(r => r.id == idDetails.id)
    const styles = {
        RadioBtn: {
            display: 'flex', flexDirection: 'row'
        },
        textfield: {
            maxWidth: '900px'
        }
    }

    return (
        <>
            {/* <div>
              <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                  <CircularProgress color="inherit" />
              </Backdrop>
          </div>*/}

            <section className="ViewWishes-section">
                <Header title="WISH DETAILS" />
                <Row className="mx-auto text-center mobile-button back-logout-btn pt-2">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex"
                    >
                        <Link to="/Wishes-Released-to-me" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-end profile-right-btn"
                    >
                        <Logout />
                    </Col>
                </Row>
                <div>
                    {showLegend && (
                        <div className={'HighLightIcon'}>
                            <div className="border h-auto legend-content p-2">
                                <img src={Add} width="20px" height="20px" className="mr-2"/>
                                Indicates you can Add: Shared With or Release To Contact,a File <br/>
                                <img src={ic_users} width="20px" height="20px" className="mr-2"/>
                                Indicates you have assigned a Contact to Share With or Release To <br/>
                                <img src={fileAttach} width="20px" height="20px" className="mr-2"/>
                                Indicates a File is attached to the Wish <br/>
                                <div className="releaseDays-label">* Released Days = 0 indicates release Immediately</div>
                                <div className="releaseDays-label">* Shared With - Consider Security as your contacts can view just as you can the moment you share</div>
                                <div className="releaseDays-label">* Released To - Contacts you want the information Released to upon your demise	</div>
                                <HighlightOffIcon className="abc" onClick={handleCloseDailog}/>
                            </div>
                        </div>
                    )}
                    <img src={genie_img} className="genie ml-5" onClick={handleCloseDailog} />
                </div>
                <div className="Should">
                    <h5 style={{ marginBottom: '10px' }}><strong>{filterD?.fname} {filterD?.lname}:</strong> is Sharing With You</h5>
                    <h5 style={{ marginBottom: '10px' }} className="withDescriptionTitle">This Wish item should be released should I become:</h5>
                    <div className={'mb-3'}>
                        <div className={"d-flex align-items-center mb-1"}>
                            <h6 className="m-0">Incapaciated</h6>
                            <RadioGroup style={styles.RadioBtn}>
                <span className="ml-1">
                  <Radio
                      checked={filterD?.isincapacitated === 1}
                      value="Immediately"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "Immediately" }}
                  />
                  Yes
                </span>
                                <span>
                  <Radio
                      checked={filterD?.isincapacitated === 0}
                      value="After"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "After" }}
                  />
                  No
                </span>
                            </RadioGroup>
                        </div>
                        <div className={"d-flex align-items-center mb-1"}>
                            <h6 style={{ width: "91px", margin: '0px' }}>Deceased</h6>
                            <RadioGroup style={styles.RadioBtn}>
                <span className="ml-1">
                  <Radio
                      checked={filterD?.isdeceased === 1}
                      value="Immediately"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "Immediately" }}
                  />
                  Yes
                </span>
                                <span>
                  <Radio
                      checked={filterD?.isdeceased === 0}
                      value="After"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "After" }}
                  />
                  No
                </span>
                            </RadioGroup>
                        </div>
                    </div>
                </div>
                <h5 className={"d-flex justify-content-left my-1 mr-auto withDescriptionTitle"}>Description</h5>
                <div className={"d-flex justify-content-center withDescriptionField mb-3"}>
                    <TextField
                        style={styles.textfield}
                        id="itemName"
                        value={filterD?.itemname}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={7}
                    />
                </div>
                <h5 className={"d-flex justify-content-left my-1 mr-auto withDescriptionTitle"}>Notes</h5>
                <div className={"d-flex justify-content-center mt-3 m-3 withDescriptionField"}>
                    <div className="notescontent" dangerouslySetInnerHTML={{
                        __html: filterD?.itemnotes
                    }}>
                    </div>
                </div>
                    <div className={'withDescriptionTitle'}>
                        <h5>Attachments:</h5>
                        <Table responsive="sm" className={'mt-2'}>
                            <thead>
                            <tr style={{backgroundColor: '#7030a0', color: 'white'}}>
                                <th>File Name</th>
                                <th>File Size</th>
                                <th>Update Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                mediaDetails.map((media) =>
                                    <tr>
                                        <td>{media.filename}</td>
                                        <td>{media.filesize}</td>
                                        <td>
                                            {format(new Date(media.created), 'MM/dd/yyyy')}
                                            <Button variant="contained" color="primary"
                                                    className="mx-2 btn-sm-height float-right"
                                                    onClick={() => downloadImage(media)}
                                            >Download</Button>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                </div>
            </section>
        </>
    );
}
export default ViewReleasedWish