import React from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '@pathofdev/react-tag-input/build/index.css';
import ShareReleaseModal from './ShareReleaseModal';

const useStylesBootstrap = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
	},
}));

const Section3 = ({
	existingSharedReleaseWithTags,
	shareReleaseContacts,
	itemName,
	rowid,
	handleIsShareToChange,
	handleIsReleaseToChange,
	saveIsShareIsReleaseto,
}) => {
	const [ShareReleaseModalShow, setShareReleaseModalShow] = React.useState(
		false
	);
	const classes = useStylesBootstrap();

	const saveIsShareToIsReleaseChange = (event) => {
		saveIsShareIsReleaseto(rowid);
		setShareReleaseModalShow(false);
	};

	const getShareReleaseContacts = (isShareIsRelease) => {
		let getContactsList = [];
		(rowid ? existingSharedReleaseWithTags : shareReleaseContacts).map(
			(contact) =>
				contact[isShareIsRelease]
					? getContactsList.push(contact.fname + ' ' + contact.lname)
					: null
		);
		return getContactsList;
	};
	const getShareContactsList = getShareReleaseContacts('sharedwith');
	const getReleaseContactsList = getShareReleaseContacts('releaseto');

	const shareContactsList =
		(getShareContactsList || []).reduce(
			(contactList, lotObj) => contactList + ',' + lotObj,
			''
		) || '';
	const releaseContactsList =
		(getReleaseContactsList || []).reduce(
			(contactList, lotObj) => contactList + ',' + lotObj,
			''
		) || '';

	return (
		<div className="Section-Container">
			<div className="justify-content-center m-2">
				<Button
					variant="success"
					className="btn-sm-height"
					onClick={() => setShareReleaseModalShow(true)}
					disabled={rowid ? false : true}
				>
					Shared With /Released To
				</Button>
				<div className="d-flex align-items-center justify-content-around m-2">
					<div className="d-flex align-items-center justify-content-evenly">
						<div>Shared With: </div>
						<div className="Count-Container align-items-center ml-2 mr-2">
							<b>{(getShareContactsList || []).length}</b>
						</div>{' '}
						<div className="align-items-end">
							<Tooltip
								arrow
								classes={classes}
								placement="top"
								title={
									shareContactsList
										? shareContactsList.slice(1)
										: 'No Contacts Assigned'
								}
							>
								<span>Contacts</span>
							</Tooltip>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-evenly">
						<div>Release To: </div>
						<div className="Count-Container align-items-center ml-2 mr-2">
							<b>{(getReleaseContactsList || []).length}</b>
						</div>{' '}
						<div className="align-items-end">
							<Tooltip
								arrow
								classes={classes}
								placement="top"
								title={
									releaseContactsList
										? releaseContactsList.slice(1)
										: 'No Contacts Assigned'
								}
							>
								<span>Contacts</span>
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
			{ShareReleaseModalShow ? (
				<ShareReleaseModal
					ShareReleaseModalShow={ShareReleaseModalShow}
					setShareReleaseModalShow={setShareReleaseModalShow}
					itemName={itemName}
					rowid={rowid}
					existingSharedReleaseWithTags={existingSharedReleaseWithTags}
					shareReleaseContacts={shareReleaseContacts}
					handleIsShareToChange={handleIsShareToChange}
					handleIsReleaseToChange={handleIsReleaseToChange}
					saveIsShareToIsReleaseChange={saveIsShareToIsReleaseChange}
				/>
			) : null}
		</div>
	);
};

export default Section3;
