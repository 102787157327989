import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import Back from '../../assets/images/Back_white.png';
import pattern from "../../assets/images/LandingPage/patterns/pattern.jpg";
import pattern_1 from "../../assets/images/LandingPage/patterns/pattern_1.jpg";
import '../NewLandingPage/NewLandingPage.css'

const Termsofuse = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleBack = () => {
        history.goBack()
    }

    return (
        <>
            <div className="privacy-policy-section position-relative">
                <header>
                    <div className="header-section">
                        <h1 className="text-center">TERMS OF USE</h1>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <div className="background-pattern-img background-loop"
                             style={{backgroundImage: `url(${pattern})`}}/>
                        <div className="background-pattern-gradient"/>
                        <div className="background-pattern-bottom">
                            <div className="image"
                                 style={{backgroundImage: `url(${pattern_1})`}}/>
                        </div>
                    </div>
                </header>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Button onClick={handleBack} className="btn custom-link-btn btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Button>
                    </Col>
                </Row>
                <Container className="bg-white mt-4 p-4">
                    <Row>
                        <div className="work_container">
                            <div>
                                <strong className={'text-center d-block privacy_policy_title'}>KEYS TO MY WISHES TERMS OF USE</strong>
                                <p>&nbsp;</p>
                                <p className={'privacy_policy_title'}><strong>Last updated November 10, 2021</strong></p>
                                <p>Keys To My Wishes is an electronic information storage vault where the wishes and
                                    location of your important documents and property can be stored in the cloud. Keys
                                    To My Wishes allows users to pay for a service plan that is priced based on the
                                    frequency with which you log in to our website or mobile application to “keep in
                                    touch,” verifying that you are alive and not incapacitated. If you have a service
                                    plan and have not logged in to our website or mobile application for “check-in,”
                                    within a certain period of time, we will attempt to contact you. If we are unable to
                                    communicate with you, we will “Break the Glass.” Breaking the Glass means that Keys
                                    to My Wishes has been contacted and informed that a user has become incapacitated or
                                    died.</p>
                                <p><u><strong>ACCEPTANCE OF AGREEMENT</strong></u></p>
                                <p>These Terms of Use apply to Keys To My Wishes (“KTMW,” “we,” “our,” or “us”) website
                                    located at <a
                                        href={'https://www.keystomywishes.com/'}>www.keystomywishes.com</a> (the “Site”)
                                    and include any future modifications and/or addenda.</p>
                                <p>The following terms of use, together with any documents incorporated by reference
                                    (this "Agreement") govern your use of the Site and any other sites, products,
                                    services, features, contents, mobile sites, and applications/or offered by us from
                                    time to time that link or otherwise refer to this Agreement, (collectively,
                                    including the Site, the "Services").</p>
                                <p>Your use of the Services is also subject to additional guidelines or rules posted in
                                    certain areas of the Service</p>
                                <p>KTMW may incorporate additional guidelines, rules, and/or supplemental terms which
                                    are also incorporated into this Agreement. Failure to comply in full with the
                                    Agreement will result in cessation of access and use privileges and may result in
                                    legal actions taken against offending individuals and/or organizations in our sole
                                    discretion. We reserve the right to block your access to the Services for violation
                                    of this Agreement. This Agreement may be updated at any time without notice. You
                                    agree to review the Agreement periodically to be aware of such modifications. Your
                                    continued use of the Services constitutes acceptance of any changes to this
                                    Agreement.</p>
                                <p>Please read this Agreement carefully before you start to use the Services. <b>BY
                                    ACCESSING AND/OR USING THE SERVICES, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY
                                    THIS AGREEMENT AND KTMW'S PRIVACY NOTICE (WHICH IS INCORPORATED HEREIN BY REFERENCE)
                                    AND TO COMPLY WITH ALL APPLICABLE LAWS, RULES, AND REGULATIONS (COLLECTIVELY,
                                    "APPLICABLE LAWS").</b> If you do not want to agree to this Agreement and the
                                    Privacy
                                    Notice, you must not access or use the Service</p>
                                <p><u><strong>DEFINITIONS</strong></u></p>
                                <p>The word "User(s)” means any Person (also referred to as "you" or "your") visiting,
                                    accessing, or using the Services. The word "Person(s)" means any natural person,
                                    sole proprietorship, corporation, partnership, or other entity whatsoever. The word
                                    "or" includes the word "and." The words "includes" or "including" means "includes,
                                    but is not limited to" or "including, but not limited to." </p>
                                <p><u><strong>USE LIMITATIONS</strong></u></p>
                                <p><u><strong>ELIGIBILITY</strong></u></p>
                                <p>The Services are offered and available to Users who reside in the United States. By
                                    using our Services, you represent that you are at least thirteen (13) years of age,
                                    and if you are under eighteen (18), that your parent or legal guardian agrees to be
                                    bound by this Agreement, and that you have not been previously removed from or
                                    prohibited from using KTMW’s Services. For all Users and Persons over the age of 18
                                    that visit, access, and/or using the Services, you represent and warrant that you
                                    are of legal age to form a binding contract with KTMW and meet all of the foregoing
                                    eligibility requirements. If you do not meet all of these requirements, you must not
                                    access or use the Services</p>
                                <p>Users and the Person executing this Agreement on behalf of any User that is a sole
                                    proprietorship, corporation, partnership, or other entity, represents that such
                                    Person is duly authorized by all necessary and appropriate corporate or other action
                                    to execute the Agreement on behalf of User. </p>
                                <p>To access the Services or some of the resources therein, you may be asked to provide
                                    certain registration details or other information. It is a condition of your use of
                                    the Services that all the information you provide is accurate, current, and
                                    complete. You agree that all information you provide to register for a Service or
                                    otherwise, including through the use of any interactive features on the Services, is
                                    governed by the Privacy Notice, and you consent to all actions we take with respect
                                    to your personal information consistent with the Privacy Notice. For purposes of
                                    this Agreement, "personal information" means information that relates to, describes,
                                    is capable of being associated with, or could reasonably be linked, directly or
                                    indirectly, with a particular individual or household.</p>
                                <p><u><strong>ELECTRONIC COMMUNICATIONS</strong></u></p>
                                <p>Visiting the Site, registering for the Service, or sending emails to KTMW constitutes
                                    electronic communications. You hereby consent to KTMW or our affiliates to contact
                                    you by telephone, through text message, by U.S. mail, or electronic mail (e-mail) to
                                    the contact information that you have provided to us.</p>
                                <p><u><strong>PROHIBITED USES</strong></u></p>
                                <p>You may only use the Services for lawful purposes and in accordance with this
                                    Agreement. You specifically agree to not:</p>
                                <ul className={'pl-3'}>
                                    <li>Violate any Applicable Laws while using the Services;</li>
                                    <li>Use the Services for the purpose of exploiting, harming, or attempting to
                                        exploit or harm any Person in any way, including minors by exposing them to
                                        inappropriate content, asking for personal information, or otherwise
                                    </li>
                                    <li>Use the Services to transmit, or procure the sending of, any advertising or
                                        promotional material, including any "junk mail", "chain letter", "spam", or any
                                        other similar solicitation
                                    </li>
                                    <li>Impersonate or attempt to impersonate KTMW, a KTMW employee, another User, or
                                        any other Person (including by using e-mail addresses or user names associated
                                        with any of the foregoing);
                                    </li>
                                    <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of
                                        the Services, or which, as determined by us, may harm KTMW or Users of the
                                        Services or expose them to liability;
                                    </li>
                                    <li>Attempt to disable, overburden, damage, or impair the Site or interfere with any
                                        other party's use of the Services, including their ability to engage in
                                        real-time activities through the Services;
                                    </li>
                                    <li>Take any action that imposes an unreasonable or disproportionately large load
                                        on KTMW's technical infrastructure;
                                    </li>
                                    <li>Copy the Services and/or any of the content, data, and/or materials therein for
                                        any other unauthorized purpose without our prior written consent;
                                    </li>
                                    <li>Use any data mining, robot, spider, crawler, cancelbots, or other devices,
                                        processes, or means to access the Services and/or facilitate the misuse of the
                                        Services for any purpose, including monitoring or copying any of the content,
                                        data, or materials that are part of the Services;
                                    </li>
                                    <li>Reverse-engineer, disassemble, or decompile the Services and/or any content,
                                        data, and/or materials therein, or attempt to do the same;
                                    </li>
                                    <li>Use any device, software, or routine that interferes with the proper working of
                                        the Services or otherwise attempt to interfere with the proper working of the
                                        Services;
                                    </li>
                                    <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material
                                        which is malicious or technologically harmful or otherwise attempt to gain
                                        unauthorized access to, interfere with, damage, or disrupt any parts of the
                                        Services, the server(s) on which the Services are stored, or any server,
                                        computer, or database connected to the Services;
                                    </li>
                                    <li>Attack the Services via a denial-of-service attack, a distributed
                                        denial-of-service attack, or another similar attack; or
                                    </li>
                                    <li>Participate in coordinated efforts or group activities with others in order
                                        violate or to exceed these use limitations. Failure to comply with these
                                        limitations will result in cessation of access and use privileges and may result
                                        in legal action against the offending individuals and/or organizations at the
                                        discretion of KTMW.
                                    </li>
                                </ul>
                                <p><u><strong>GEOGRAPHIC RESTRICTIONS</strong></u></p>
                                <p>The owner of the Services is based in the United States. We provide the Services for
                                    use only by Persons located in the United States. We make no claims that the
                                    Services or any of its content is accessible or appropriate outside of the United
                                    States. Access to the Services may not be legal by certain Persons or in certain
                                    countries. If you access the Services from outside the United States, you do so on
                                    your own initiative and are responsible for compliance with local laws.</p>
                                <p><u><strong>PRICING, BILLING, FEES, & TRANSACTIONS</strong></u></p>
                                <p>KTMW may charge fees for services offered. If you have paid for a service and would
                                    like to discontinue your service, please email us at
                                    support@keystomywishes.com.</p>
                                <p><u><strong>NOTICES</strong></u></p>
                                <p>You agree that any notices that KTMW may be legally required to send to you will be
                                    effective upon either: (a) KTMW's sending an e-mail message to the e-mail address
                                    you have on file with KTMW; or (b) KTMW publishing such notices on the Site. You
                                    agree that all agreements, notices, disclosures, and other communications that we
                                    provide to you electronically satisfy any legal requirement that such communications
                                    be in writing.</p>
                                <p><u><strong>FORCE MAJEURE</strong></u></p>
                                <p>A Force Majeure is defined as an Act of God, pandemic, epidemic, contagion, disease,
                                    fire, flood, earthquake, storm, act of terrorism, war, protest, civil disobedience,
                                    riot, rebellion, accident, explosion, crime, change in law or regulation, any
                                    disruption, outage or malfunction of interference in communication, network,
                                    equipment, or software, act of any military, civil or regulatory authority, any
                                    disruption or delay in supplies, power or other utilities, any labor disputes or
                                    shortage, natural disaster, actions or decrees of governmental bodies, strikes, or
                                    embargo. </p>
                                <p><u><strong>DISCLAIMER OF WARRANTIES, TERMS, & LIABILITY</strong></u></p>
                                <p>THE SERVICES, INCLUDING ANY CONTENT, DATA, AND/OR MATERIALS ACCESSIBLE ON THE
                                    SERVICES, ARE PROVIDED "AS IS" AND "AS AVAILABLE" AND THERE MAY BE DELAYS,
                                    OMISSIONS, AND/OR INACCURACIES IN SUCH ITEMS. KTMW INDEMNIFIED PARTIES (AS DEFINED
                                    BELOW) CANNOT AND DO NOT GUARANTEE THE ACCURACY, SEQUENCE, COMPLETENESS, TIMELINESS,
                                    MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF THE SERVICES AND/OR THE
                                    CONTENT, DATA, AND/OR MATERIALS MADE AVAILABLE THROUGH THE SERVICES OR BY ANY FORCE
                                    MAJEURE OR ANY OTHER CAUSE BEYOND THEIR REASONABLE CONTROL AND HEREBY EXPRESSLY
                                    DISCLAIM ANY WARRANTY THEREFORE.</p>
                                <p>KTMW INDEMNIFIED PARTIES SHALL NOT BE LIABLE TO YOU OR TO ANYONE ELSE FOR ANY LOSS OR
                                    INJURY CAUSED IN WHOLE OR IN PART BY ITS NEGLIGENCE, OMISSION IN PROCURING,
                                    COMPILING, INTERPRETING, EDITING, WRITING, REPORTING, TRANSMITTING, OR DELIVERING
                                    ANY DATA THROUGH THE SERVICES OR FOR INTERRUPTION IN THE SERVICES. IN NO EVENT, WILL
                                    KTMW INDEMNIFIED PARTIES BE LIABLE TO YOU OR ANYONE ELSE FOR YOUR USE OF OR
                                    INABILITY TO USE THE SERVICES, THE SITE, ANY SITES LINKED TO OR FROM THE SERVICES,
                                    ANY DATA ON THE SERVICES, OR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE
                                    UPON SUCH INFORMATION OR DATA OR FOR ANY CONSEQUENTIAL, SPECIAL OR SIMILAR DAMAGES,
                                    INCLUDING BUT NOT LIMITED TO LOST PROFITS, DAMAGES RESULTING FROM INCONVENIENCE, OR
                                    LOSS OF USE OF THE SERVICE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS
                                    SERVICE MAY BE DISCONTINUED OR LIMITED AT ANY TIME. THE FOREGOING DOES NOT AFFECT
                                    ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                                <p>KTMW makes no warranties of any kind regarding any third-party sites or services
                                    ("Third-Party Services") to which you may be directed or linked to from the
                                    Services. Links are included solely for your convenience, and KTMW makes no
                                    representations or warranties with regard to the accuracy, availability,
                                    suitability, or safety of information provided in such Third-Party Services.
                                    Inclusion of any Third-Party Service or a link thereto on the Services does not
                                    imply approval or endorsement by KTMW of the Third-Party Service. When you engage
                                    with a provider of a Third-Party Service, you are interacting with the third-party,
                                    not with KTMW. When you access Third-Party Services, you understand that you do so
                                    at your own risk. If you choose to use a Third-Party Service and share information
                                    with it, the provider of the Third-Party Service may use and share your data in
                                    accordance with its Privacy Notice and your privacy settings on such Third-Party
                                    Service. You should not provide any personal information to or through any
                                    Third-Party Service unless you know and are comfortable with the party with whom you
                                    are interacting. In addition, the provider of the Third-Party Service may use other
                                    parties to provide portions of the application or service to you, such as
                                    technology, development or payment services. </p>
                                <p><u><strong>INDEMNIFICATION</strong></u></p>
                                <p>You agree to indemnify and hold KTMW, its parents, subsidiaries, affiliates,
                                    licensors, advertisers and sponsors, agents, directors, officers, and employees and
                                    third-party information providers (collectively, the "Company Indemnified Parties")
                                    harmless from and against any and all Losses resulting or arising from any claim,
                                    action or proceeding brought by any third party in connection with or relating to
                                    your access to or use of the Services (or the use of the Services by another Person
                                    using your password) or violation of this Agreement. "Losses" means any and all
                                    penalties, claims, actions, suits, costs, judgments, settlements, and expenses of
                                    whatever nature, whether incurred by or issued against an indemnified party or a
                                    third party, including: (a) indirect, special, punitive, consequential, or
                                    incidental losses or damages (including, but not limited to, trading losses, loss of
                                    anticipated profits, loss by reason of shutdown in operation or increased expenses
                                    of operation, or other indirect loss or damage); and (b) administrative costs,
                                    investigatory costs, litigation costs, expert costs, and auditors' fees and costs,
                                    and attorneys' fees, costs, and disbursements (including in-house personnel). </p>
                                <p><u><strong>PROPRIETARY RIGHTS & PROTECTION</strong></u></p>
                                <p>All of the data, information, content, materials, services, and software displayed
                                    on, transmitted through, or used in connection with the Services, including, for
                                    example, reviews, text, photographs, images, illustrations, HTML, source and object
                                    code, software, Data (as defined below), etc., as well as its trade dress, layout,
                                    presentation, selection, and arrangement, are owned by KTMW (collectively, the
                                    "Content"). KTMW actively protects its rights to the Content to the fullest extent
                                    of the law. You may not use such Content except as provided in this Agreement.</p>
                                <p>You may use the Services online and solely for your personal, non-commercial use. No
                                    other use is permitted. You may not, for example, republish the Content on any
                                    Internet, Intranet, or Extranet site or incorporate the Content in any database,
                                    compilation, archive, or cache. You may not distribute any of the Content to others,
                                    whether or not for payment or other consideration, and, unless explicitly permitted
                                    on the Services, you may not modify, copy, frame, reproduce, sell, publish,
                                    transmit, display, download, share, or otherwise use any portion of the Content
                                    without the prior written consent of KTMW. For information or requests to use the
                                    Content for any purpose other than as permitted in this paragraph, please email us
                                    at support@keystomywishes.com. Any use of the Services not expressly permitted by
                                    this Agreement is a breach of this Agreement and may violate copyright, trademark,
                                    and/or other laws.</p>
                                <p>The Services contain data and other information including written words
                                    (collectively, the "Data"). The Data may be generated by KTMW or gathered by KTMW
                                    from other sources. You acknowledge and agree that the Data are highly proprietary
                                    in nature and that unauthorized copying, transfer, or use may cause KTMW or its
                                    affiliates, agents, information providers, and licensors irreparable injury that
                                    cannot be adequately compensated for by means of monetary damages. You agree that
                                    any breach of the Agreement may be enforced by KTMW by means of equitable relief
                                    (including, but not limited to, injunctive relief) in addition to any other rights
                                    and remedies available to it. You may not frame or utilize framing techniques that
                                    involve any Content, trademark, logo, copyrighted material, or other proprietary
                                    information (including images, text, page layout, or form) of any portion of the
                                    Services without our express prior written consent.</p>
                                <p><u><strong>DURATION & SURVIVAL</strong></u></p>
                                <p>Even after your access to the Services is terminated by you or by KTMW, this
                                    Agreement will remain in full force and effect with respect to your past and future
                                    use of the Services. If any of the provisions of this Agreement, or application
                                    thereof to any Person or circumstance, shall to any extent be held invalid or
                                    unenforceable, the remainder of this Agreement, or the application of such terms or
                                    provisions to Persons or circumstances other than those as to which they are held
                                    invalid or unenforceable, shall not be affected thereby and each such term and
                                    provision of this Agreement shall be valid and enforceable to the fullest extent
                                    permitted by law. </p>
                                <p><u><strong>CHANGES TO THE SERVICES OR THE AGREEMENT</strong></u></p>
                                <p>KTMW may, at any time, change or discontinue any aspect or feature of a Service,
                                    including Content and hours of availability. We may update the Content, including,
                                    but not limited to, any Data on the Services from time to time, but Content is not
                                    necessarily complete or up-to-date. Any of the material on the Services may be
                                    out-of-date at any given time, and we are under no obligation to update such
                                    material.</p>
                                <p>You agree to be bound by future revisions of this Agreement. It is your
                                    responsibility to visit the "Terms of Use" link at the bottom of the
                                    www.keystomywishes.com homepage periodically to review the most current terms and
                                    conditions. KTMW may also impose limits on certain features and services or restrict
                                    your access to parts or all of a Service without notice or liability. We reserve the
                                    right to withdraw or amend the Services, and any material we provide on the
                                    Services, in our sole discretion without notice. We will not be liable if for any
                                    reason all or any part of the Services are unavailable at any time or for any
                                    period. From time to time, we may restrict access to some or all parts of the
                                    Services to Users.</p>
                                <p>KTMW may revise and update this Agreement from time to time in our sole discretion.
                                    All changes are effective immediately when we post them and apply to all access to
                                    and use of the Services thereafter. Your continued use of any Service following the
                                    posting of a revised Agreement means that you accept and agree to the changes. You
                                    are expected to check this page from time to time so you are aware of any changes,
                                    as they are binding on you.s</p>
                                <p><u><strong>DISSEMINATION, DISCONTINUANCE, OR MODIFICATION</strong></u></p>
                                <p>You understand that, at any time, KTMW may discontinue disseminating any feature of
                                    the Services. You will not hold KTMW liable for any resulting liability, loss, or
                                    damages that may arise therefrom. You acknowledge that KTMW, in its sole discretion,
                                    may from time to time make modifications to its system(s), the Services, or
                                    Data.</p>
                                <p><u><strong>PRIVACY</strong></u></p>
                                <p>Your use of the Services is also governed by KTMW's Privacy Notice. KTMW does not
                                    knowingly allow access to or collect information from children under the age of
                                    thirteen (13), without verifiable parental or guardian consent. If you are not over
                                    the age of thirteen (13), please do not use the Services.</p>
                                <p><u><strong>GOVERNING LAW & JURISDICTION</strong></u></p>
                                <p>This Agreement shall be governed by and construed in accordance with laws of the
                                    California, without giving effect to any choice of law or conflict of law provision
                                    or rule that would cause the application of the laws of any jurisdiction other than
                                    the State of California. The Parties agree to resolve any disputes peaceably and
                                    amicably first. Should any dispute arise over the application or interpretation of
                                    the Agreement, the Parties shall meet to discuss their difference and arrive at a
                                    mutual Agreement. If a mutual Agreement cannot be reached within sixty (60) days by
                                    employing the above methods, the Parties shall consult a private mediator agreeable
                                    to both Parties. Reasonable fees for the mediator shall be split equally between the
                                    Parties. Each Party is responsible for their own attorney’s fees resulting in the
                                    mediation. If any such dispute cannot be resolved through mediation with the 90-day
                                    period, any and all claims and actions arising of or relating to this Agreement or
                                    relationship of the Parties, shall be exclusively litigated in Los Angeles County,
                                    California. Each Party shall be responsible for their own attorney’s fee resulting
                                    from such litigation.</p>
                                <p><u><strong>CLASS ACTION WAIVER</strong></u></p>
                                <p>BOTH YOU AND KTMW WAIVE THE RIGHT TO BRING ANY CONTROVERSY, CLAIM, OR DISPUTE ARISING
                                    OUT OF OR RELATING IN ANY WAY TO YOUR USE OF THE SERVICES OR PURCHASES THROUGH THE
                                    SERVICES AS A CLASS, CONSOLIDATED, REPRESENTATIVE, COLLECTIVE, OR PRIVATE ATTORNEY
                                    GENERAL ACTION, OR TO PARTICIPATE IN A CLASS, CONSOLIDATED, REPRESENTATIVE,
                                    COLLECTIVE, OR PRIVATE ATTORNEY GENERAL ACTION REGARDING ANY SUCH CLAIM BROUGHT BY
                                    ANYONE ELSE.</p>
                                <p><u><strong>ENTIRE AGREEMENT</strong></u></p>
                                <p>This Agreement, the Privacy Notice, and the Class Action Waiver, any supplemental
                                    terms, applicable Third-Party Terms (as defined below) and any other required
                                    documents for use of the Services, constitute the sole and entire agreement between
                                    you and the Organization with respect to the Services and supersede all prior and
                                    contemporaneous understandings, agreements, representations, and warranties (both
                                    written and oral) with respect to the Services. </p>
                                <p><u><strong>FEEDBACK, COMMENTS, & CONCERNS</strong></u></p>
                                <p>All feedback, comments, concerns, requests for technical support, and other
                                    communications relating to the Services should be directed to
                                    support@keystomywishes.com. For any ideas, suggestions, recommendations, requests,
                                    or other feedback that you provide to KTMW (collectively, "Feedback") you grant KTMW
                                    a perpetual, irrevocable, transferable, worldwide, fully-paid, royalty-free license
                                    to create derivative works and fully use and exploit such Feedback without any
                                    obligation to you (monetary or otherwise).</p>
                                <p><u><strong>TRADEMARK & COPYRIGHT OWNERSHIP NOTICE</strong></u></p>
                                <p>The Services contain proprietary, original content that is protected by U.S.
                                    copyright and international treaties. KTMW retains all intellectual property rights.
                                    KTMW will pursue legal action against anyone who misappropriates its name,
                                    trademarks, or content. This notice constitutes fair warning. All trademarks used
                                    herein are the exclusive property of their respective trademark owners and may not
                                    be used in any way without written consent of their owner. Without limiting the
                                    foregoing, the following notices will apply to the Services:</p>
                                <p>The trademarks and their corresponding logos are owned by Keys To My Wishes.</p>
                                <p>© 2021 Keys To My Wishes <u>21550 W. Oxnard St., Woodland Hills, CA 91367.</u></p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Termsofuse;

