import React, { useEffect } from 'react';
import '@pathofdev/react-tag-input/build/index.css';
import { Button } from 'react-bootstrap';
import { Radio, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			width: '50px',
			minWidth: '50px',
			textAlign: 'center',
			border: 'solid 2px',
			height: '32px',
		},
	},
}));

const ReleaseMyInfo = ({
	nIncapacitated,
	nDeceased,
	getReleaseMyInformation,
}) => {
	const classes = useStyles();
	const [
		selectedIncapacitatedValue,
		setIncapacitatedSelectedValue,
	] = React.useState('');
	const [selectedPassedAwayValue, setPassedAwaySelectedValue] = React.useState(
		''
	);
	const [daysIncapacitated, setDaysIncapacitated] = React.useState(0);
	const [daysPassedAway, setDaysPassedAway] = React.useState(0);

	useEffect(() => {
		if (nIncapacitated === 1) {
			setIncapacitatedSelectedValue('Immediately');
			setDaysIncapacitated(0);
		} else {
			setIncapacitatedSelectedValue(
				nIncapacitated > 0 ? 'After' : 'Immediately'
			);
			setDaysIncapacitated(Number(nIncapacitated));
		}
	}, [nIncapacitated]);

	useEffect(() => {
		if (nDeceased === 1) {
			setPassedAwaySelectedValue('Immediately');
			setDaysPassedAway(0);
		} else {
			setPassedAwaySelectedValue(nDeceased > 0 ? 'After' : 'Immediately');
			setDaysPassedAway(Number(nDeceased));
		}
	}, [nDeceased]);

	const handleChangeIncapacitated = (event) => {
		setIncapacitatedSelectedValue(event.target.value);
		setDaysIncapacitated(0);
	};
	const handleChangePassedAway = (event) => {
		setPassedAwaySelectedValue(event.target.value);
		setDaysPassedAway(0);
	};
	const handleDaysIncapacitateChange = (event) => {
		if (event.target.value > 0 && event.target.value <= 30)
			setDaysIncapacitated(event.target.value);
	};
	const handleDaysPassedAwayChange = (event) => {
		if (event.target.value > 0 && event.target.value <= 30)
			setDaysPassedAway(event.target.value);
	};

	const saveReleaseMyInfo = () => {
		var token = localStorage.getItem('token');
		axios
			.post(
				process.env.REACT_APP_SERVER +
					'user/incapacitateddeceasedsettings',
				{
					nUserID: localStorage.getItem('userid'),
					nIncapacitated:
						selectedIncapacitatedValue === 'Immediately'
							? 1
							: Number(daysIncapacitated),
					nDeceased:
						selectedPassedAwayValue === 'Immediately'
							? 1
							: Number(daysPassedAway),
				},
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((res) => {
				getReleaseMyInformation();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="Section-Container">
			<div className="justify-content-center m-2">
				<div>Upon notification release my information in case of:</div>
				<div className="d-flex align-items-center justify-content-around w-100">
					<div>
						<div className="d-flex align-items-center  m-2">
							<span className="custom-label">Incapacitated:</span>
							<span>
								<Radio
									checked={selectedIncapacitatedValue === 'Immediately'}
									onChange={handleChangeIncapacitated}
									value="Immediately"
									color="default"
									name="radio-button-demo"
									inputProps={{ 'aria-label': 'Immediately' }}
								/>
								Immediately
							</span>
							<span className="mx-1">OR</span>
							<div className="d-flex align-items-center justify-content-between">
								<span>
									<Radio
										checked={selectedIncapacitatedValue === 'After'}
										onChange={handleChangeIncapacitated}
										value="After"
										color="default"
										name="radio-button-demo"
										inputProps={{ 'aria-label': 'After' }}
									/>
									After
								</span>
								<div className="align-items-center ml-2 mr-2 releaseMyInfo-container">
									<form className={classes.root}>
										<TextField
											id="outlined-basic"
											variant="outlined"
											value={daysIncapacitated}
											type="number"
											onChange={handleDaysIncapacitateChange}
											disabled={selectedIncapacitatedValue === 'Immediately'}
										/>
									</form>
								</div>
								Days
							</div>
						</div>
						<div className="d-flex align-items-center  m-2">
							<span className="custom-label">Deceased:</span>
							<span>
								<Radio
									checked={selectedPassedAwayValue === 'Immediately'}
									onChange={handleChangePassedAway}
									value="Immediately"
									color="default"
									name="radio-button-demo"
									inputProps={{ 'aria-label': 'Immediately' }}
								/>
								Immediately
							</span>
							<span className="mx-1">OR</span>
							<div className="d-flex align-items-center justify-content-between">
								<span>
									<Radio
										checked={selectedPassedAwayValue === 'After'}
										onChange={handleChangePassedAway}
										value="After"
										color="default"
										name="radio-button-demo"
										inputProps={{ 'aria-label': 'After' }}
									/>
									After
								</span>
								<div className="align-items-center ml-2 mr-2 releaseMyInfo-container">
									<form className={classes.root}>
										<TextField
											id="outlined-basic"
											variant="outlined"
											value={daysPassedAway}
											type="number"
											onChange={handleDaysPassedAwayChange}
											disabled={selectedPassedAwayValue === 'Immediately'}
										/>
									</form>
								</div>
								Days
							</div>
						</div>
					</div>
					<div>
						<Button
							variant="primary"
							className="btn-sm-height"
							onClick={saveReleaseMyInfo}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReleaseMyInfo;
