import React, {useRef} from "react";
import "./OTPVerification.css";
import OtpInput from "react-otp-input";
import {Link, useHistory} from "react-router-dom";
import {Alert, Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axios from 'axios'
import IdleTimer from "react-idle-timer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import logo_new from '../../assets/images/LandingPage/keys_to_my_wishes.png'
import playstore from "../../assets/images/androidApp.png"
import ios_Store from "../../assets/images/appleApp.png"

const OtpVerification = () => {
    const id = useParams();
    const IdelTimerRef = useRef(null)
    const [otp, setOptp] = React.useState();
    const [otpisValid, setotpisValid] = React.useState(false);
    const history = useHistory();
    const decodedData = atob(id.EncodedData).split('/')

    const onIdle = () => {
        history.push('/')
    }

    const handleChange = (otp) => {
        setOptp(otp);
    };

    const HandleValidate = () => {
        axios.post(process.env.REACT_APP_SERVER + 'usercontactsharedwithaccessverify', {
            nUserID: decodedData[0],
            nContactID: decodedData[1],
            otpcode: otp,
        }).then((res) => {
            if (res.data[0].retValue < 1) {
                setotpisValid(true)
            } else {
                history.push(`/New-Wishes-Shared-me/${id.EncodedData}`);
            }
        }).catch((err) => {
            setotpisValid(true);
        })
    };
    
    const handleplaystore = () => {
        alert('App will be available soon');
    }

    return (
        <div className={"otpContainer"}>
            <IdleTimer
                ref={IdelTimerRef}
                timeout={10 * 60000}
                onIdle={onIdle}
            />
            <div className="VerificationGenie">
            </div>

            <div className="d-flex justify-content-center align-items-center flex-column container">
                <div className="text-center otp_header">
                    <img className="logo_keystomywish" src={logo_new}/>
                    <div className="d-flex justify-content-center align-content-center pt-3 pb-3">
                        <Link to='/'>
                            <Button className="exit_button">
                            <span
                                className={'d-flex align-items-center justify-content-center'}><ExitToAppIcon
                                className={'exitAppICon'}/>&nbsp;Exit</span>
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="">
                    <div className="card">
                        {otpisValid && <Alert variant={"danger"}>OTP IS INVALID!</Alert>}
                        <h5 className="m-0">Two Factor Authentication</h5>
                        <span>Enter the code we just sent to your mobile/email</span>
                        <div className="d-flex justify-content-center mt-5 otpInput optbox">
                            <OtpInput
                                value={otp}
                                onChange={handleChange}
                                numInputs={6}
                                isInputNum={true}
                                separator={<span>&nbsp;</span>}
                            />
                        </div>
                        <div className="mt-4 text-center">
                            <button
                                className="btn btn-primary px-4 validate exit_button"
                                onClick={HandleValidate}
                            > Submit
                            </button>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center' style={{paddingTop: '25px',}}>
                        <img src={playstore} style={{width: '150px', height: '45px'}} onClick={handleplaystore}/>
                        &nbsp;&nbsp;&nbsp;
                        <img src={ios_Store} style={{width: '150px', height: '45px'}} onClick={handleplaystore}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpVerification;
