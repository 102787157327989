import React from 'react';
import Section2 from '../Section2';
import './AdditemDiscriptionWizard.css'
import OptionalSection from "../../../components/newNotesform/OptionalSection";

const AddItemDescriptionWizard = ({
                                      itemName,
                                      setItemName,
                                      suggestionsData,
                                      getAllSuggestions,
                                      stepNumber,
                                      optionalData,
                                      setOptionalData,
                                      handleChangeField,
                                      setItemNotes
                                  }) => {
    return (
       <div className="wizardadditem">
           <Section2
               getAllSuggestions={getAllSuggestions}
               itemName={itemName}
               setItemName={setItemName}
               suggestionsData={suggestionsData}
               isItemNameInput={true}
               isItemNoteInput={false}
               isViewSendTextEmail={false}
               setItemNotes={setItemNotes}
               isViewSuggetion={true}
               itemNameLabel="What is your Wish Item?"
               stepNumber={stepNumber}
           />
           <OptionalSection
               optionalData={optionalData}
               setOptionalData={setOptionalData}
               handleChangeField={handleChangeField}
           />
       </div>
    );
};

export default AddItemDescriptionWizard;
