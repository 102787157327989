import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
	Checkbox,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const headCells = [
    {
        id: 'fname',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
    },
    { id: 'lname', numeric: true, disablePadding: false, label: 'Last Name' },
    {
        id: 'sharedwith',
        numeric: true,
        disablePadding: false,
        label: 'Shared With',
    },
    {
        id: 'releaseto',
        numeric: true,
        disablePadding: false,
        label: 'Released To',
    },
];

function EnhancedTableHead() {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => (
					<TableCell key={index} align={'center'}>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	container: {
		maxHeight: 500,
		overflowX: 'hidden',
	},
	customCell: {
		padding: '8px 16px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	customHeaderCell: {
		fontSize: '16px',
	},
}));

const ShareReleaseModal = ({
	ShareReleaseModalShow,
	setShareReleaseModalShow,
	itemName,
	rowid,
	existingSharedReleaseWithTags,
	shareReleaseContacts,
	handleIsShareToChange,
	handleIsReleaseToChange,
	saveIsShareToIsReleaseChange,
}) => {
	const classes = useStyles();
	return (
		<>
			<Modal
				size="lg"
				show={ShareReleaseModalShow}
				onHide={() => {
					setShareReleaseModalShow(false);
				}}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="ShareRelease-Modal-Container"
			>
				<Modal.Header closeButton className="ShareRelease-Modal-Header">
					<Modal.Title>Shared With - Release To Select</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-left">
						<div className="pt-3 pb-3">
							<b>Item is Shared With and/or be Released To</b>
						</div>
						{itemName ? (
							<div className="ShareRelease-Container">{itemName}</div>
						) : null}
					</div>
					<div>
						<Paper className={classes.paper}>
							<TableContainer className={classes.container}>
								<Table
									className={classes.table}
									aria-labelledby="tableTitle"
									aria-label="enhanced table"
									stickyHeader
								>
									<EnhancedTableHead />
									<TableBody>
										{(rowid
											? existingSharedReleaseWithTags
											: shareReleaseContacts
										)
											/*.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
											.map((row, index) => {
												return (
													<TableRow hover tabIndex={-1} key={index}>
														<TableCell
															component="th"
															scope="row"
															align="center"
															id={index}
														>
															{row.fname}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
															align="center"
															id={index}
														>
															{row.lname}
														</TableCell>
														<TableCell align="center">
															<Checkbox
																id={row.contactid.toString()}
																checked={!!row.sharedwith}
																color="primary"
																inputProps={{
																	'aria-label': 'secondary checkbox',
																}}
																onChange={handleIsShareToChange}
															/>
														</TableCell>
														<TableCell align="center">
															<Checkbox
																id={row.contactid.toString()}
																checked={!!row.releaseto}
																onChange={handleIsReleaseToChange}
																color="primary"
																inputProps={{
																	'aria-label': 'secondary checkbox',
																}}
															/>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button
						variant="primary"
						className="custom-btn"
						onClick={() => saveIsShareToIsReleaseChange()}
					>
						Save Info
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ShareReleaseModal;
