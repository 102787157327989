import React, {useEffect} from 'react'
import "./FileListing.css"
import "./FileListing.css"
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import genie_img from "../../assets/images/genie_image.jpg";
import axios from "axios";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import Header from "../NewHeader/Header";
import Logout from "../logout/Logout";
import pattern_1 from "../../assets/images/LandingPage/patterns/pattern_1.jpg";


const FileListing = () => {

    const [List, setList] = React.useState([]);
    const [totalSize, setTotalSize] = React.useState([]);
    useEffect(() => {

        var token = localStorage.getItem("token");
        var userId = localStorage.getItem("userid");
        axios
            .get(process.env.REACT_APP_SERVER + `user/userfilelistingget?nUserID=${userId}`, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                setList(res.data);
                let totalSize = 0;
                for(let i = 0;i < res.data.length;i++) {
                    totalSize = parseFloat(totalSize) + parseFloat(res.data[i].filesize)
                }
                setTotalSize(totalSize);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const downloadImage = async (row) => {
        var token = localStorage.getItem('token');
        const userId = localStorage.getItem('userid');
        axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${row.s3filename}&nUserID=${userId}`,
            {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                let link = document.createElement('a');
                link.setAttribute('href', res.data.url);
                link.setAttribute('download', row.filename);
                link.click();
            })

    };

    const bytesIntoHumanReadable = (bytes) =>{
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        const gigabyte = megabyte * 1024;
        const terabyte = gigabyte * 1024;

        if ((bytes >= 0) && (bytes < kilobyte)) {
            return Math.floor(bytes) + " B";

        } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
            return Math.floor(bytes / kilobyte) + " KB";

        } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
            return Math.floor(bytes / megabyte) + " MB";

        } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
            return Math.floor(bytes / gigabyte) + " GB";

        } else if (bytes >= terabyte) {
            return Math.floor(bytes / terabyte) + " TB";

        } else {
            return Math.floor(bytes) + " Bytes";
        }
    }

    const products = List.map((item,index) => {
        return {
            key:index,
            itemname: item?.itemname,
            filename: item.filename,
            filesize: bytesIntoHumanReadable(item.filesize),
            created: moment(item.created).format('M/D/YYYY hh:mm:ss'),
            download: (
                <Button
                    variant="info"
                    className="mx-2 btn-sm-height"
                    onClick={() => downloadImage(item)}
                >
                    Download
                </Button>
            )
        };
    });

    const columns = [
        {
            dataField: "itemname",
            text: "WISH",
            sort: true,
        },
        {
            dataField: "filename",
            text: "FILE NAME",
            sort: true
        },
        {
            dataField: "filesize",
            text: "SIZE",
            sort: true
        },
        {
            dataField: "created",
            text: "UPLOADED",
            sort: true
        },
        {
            dataField: "download",
            text: "",
        }
    ];

    const [showLegend, setShowLegend] = React.useState(false);
    const [LegendData, setLegendData] = React.useState('');
    const handleClose = () => {
        setShowLegend(!showLegend)
    }
    const getLegendData = () => {
        setShowLegend(!showLegend)
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=31`)
            .then((res) => {
                setLegendData(res.data[0])
            }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <Modal show={showLegend} onHide={handleClose}>
                <Modal.Header closeButton className={'modelHeaders modal-header"'}>
                    <Modal.Title style={{fontSize: '28px'}}><div dangerouslySetInnerHTML={{__html: LegendData.theheader}}/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'ml-3'}
                         dangerouslySetInnerHTML={{__html: LegendData.thetext}}
                    >
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={handleClose} style={{backgroundColor:'#7030a0',color:'white',textTransform:'lowercase'}}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>

            <Header title="MY FILE LISTING"/>
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <Link to="/newDashboard" className="btn custom-link btn-primary">
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </Link>
                </Col>
                <Col
                    lg={6} md={6} sm={6} xs={6} className="btnall"
                >
                    <Logout/>
                </Col>
            </Row>
            <Col md={7}>
                <div
                    className="d-flex mb-4 justify-content-between"
                    style={{alignItems: "flex-end"}}
                    onClick={getLegendData}
                >
                    <img
                        src={genie_img}
                        className="genie"
                    />
                </div>
            </Col>
            <div className="listing">
              <div className='float-right'>
                  <span style={{paddingRight:'100px', fontWeight: 'bold',fontSize: '17px'}}>Total Files : <strong>{List.length}</strong></span>
                  <span style={{fontWeight: 'bold',fontSize: '17px'}}>Total Size : <strong>{bytesIntoHumanReadable(totalSize)}</strong></span>
              </div>
                <div className="table-responsive text-center overflow-auto w-100" style={{height : 'calc(100vh - 210px)'}}>
                    <BootstrapTable
                        keyField="id"
                        data={products}
                        columns={columns}
                    />
                </div>
            </div>
        </>
    )
}
export default FileListing;