import React, {useEffect, useState, useRef, createRef} from "react";
import "./Login.css";
import {Link, useHistory} from "react-router-dom";
import logo from "../../../assets/images/LandingPage/logo.png";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import axios from "axios";
import {Alert} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {GoogleLogin} from 'react-google-login'
import FacebookLoginWithButton from 'react-facebook-login';

const Login = () => {
        let history = useHistory();
        const [username, setUsername] = useState(localStorage.getItem('email') !== null ? localStorage.getItem('email') : '');
        const [password, setPassword] = useState();
        const [loginerror, setemailerror] = useState(false);
        const [errormessage, seterrormessage] = useState("");
        const [sDeviceID, setCurrentIp] = useState(null);
        const [hidden, setHidden] = useState(true);
        const inputRef = useRef();

        useEffect(() => {
            getCurrentIp();
            inputRef.current.focus();
            seterrormessage("Wrong username/password");
        }, []);

        const submitClickBtn = (e) => {
            e.preventDefault();
            const data = {
                email: username,
                password: password,
                sDeviceID,
                sDeviceType: "WEB",
            };
            axios.post(process.env.REACT_APP_SERVER + "auth/login", data, {
                headers: {"Content-Type": "application/json",},
            }).then((res) => {
                document.cookie = `serviceplanid=${
                    res.data.users.serviceplanid
                }; expires=${new Date(Date.now() + 10800000).toUTCString()}; path=/`;
                localStorage.setItem("dashboardLayout", res.data.users.checkinbuttononly);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("login", true);
                localStorage.setItem("userid", res.data.users.id);
                localStorage.setItem("email", res.data.users.email);
                localStorage.setItem("name", res.data.users.fname + ' ' + res.data.users.lname);
                localStorage.setItem('accountnumber', res.data.users.accountnumber);
                localStorage.setItem("isLogin", 1);
                const user = res.data.users
                if (user.newuser === 1 && user.twofactorauth === 1) {
                    localStorage.setItem('isAuthenticatedOTP', '0');
                    localStorage.setItem('dashboardLayout', 0);
                } else if (user.newuser === 1) {
                    localStorage.setItem('isBtnClicked', false)
                    localStorage.setItem('isAuthenticatedOTP', '1');
                    localStorage.setItem('dashboardLayout', 1);
                } else {
                    localStorage.setItem('isAuthenticatedOTP', res.data.users.twofactorauth === 0 ? '1' : '0');
                    localStorage.setItem('dashboardLayout', 0);
                }
                history.push("/maindashboard");
            }).catch((err) => {
                setemailerror(true);
                seterrormessage("Wrong Username/Password");
            });
        };

        const getCurrentIp = () => {
            axios.get("https://geolocation-db.com/json/")
                .then((res) => {
                    setCurrentIp(res.data.IPv4);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const handleNewPass = () => {
            history.push('/new-password')
        }

        const responseGoogle = (response) => {
            axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
                email: response.profileObj.email,
                name: response.profileObj.googleId
            }).then((res) => {
                localStorage.setItem("login", true);
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('email', res.data.users.email)
                localStorage.setItem('name', response.profileObj.name)
                localStorage.setItem("isLogin", 1);
                localStorage.setItem("userid", res.data.users.id);
                localStorage.setItem('accountnumber', res.data.users.accountnumber);
                history.push('/maindashboard')
            }).catch((err) => {
                console.log(err)
            })
        }

        const responseFacebook = (response) => {
            axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
                email: response.email,
                name: response.id
            }).then((res) => {
                localStorage.setItem("login", true);
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('email', res.data.users.email)
                localStorage.setItem('name', response.name)
                localStorage.setItem("isLogin", 1);
                localStorage.setItem("userid", res.data.users.id);
                localStorage.setItem('accountnumber', res.data.users.accountnumber);
                history.push('/maindashboard')
            }).catch((err) => {
                console.log(err)
            })
        }

        return (
            <>
                <div className="login_form_page">
                    <div className={`navigation`}>
                        <div className="navigation-wrapper navigation_Header">
                            <div className="container">
                                <div className="navigation-inner login-header">
                                    <div className="navigation-logo">
                                        <Link to="/">
                                            <img src={logo} alt="orions-logo" height="60px"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contact-form-section login_form">
                        <div className="contact-form-section-wrapper">
                            <div className="container">
                                <div className="row gx-5 contact-form-section-row justify-content-center">
                                    <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-8 col-12 offset-1">
                                        <div className="contact-form drop-shadow-2">
                                            <div className="contact-form-wrapper">
                                                <div className="section-heading section-heading-2 center">
                                                    <div className="main-heading c-blue">
                                                        <h1>Login</h1>
                                                        <Link to="/" className="back_btn">
                                                            <KeyboardBackspaceIcon className="back_icon"/>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <form onSubmit={submitClickBtn}>
                                                    <div>
                                                        {loginerror === true && (
                                                            <Alert
                                                                key="wrong-login"
                                                                variant="danger"
                                                                className="errorcolor"
                                                            >
                                                                {errormessage}
                                                            </Alert>
                                                        )}
                                                    </div>
                                                    <div className="form-floating">
                                                        <TextField
                                                            inputRef={inputRef}
                                                            autoComplete={'off'}
                                                            value={username}
                                                            type="email"
                                                            id="Username/Email"
                                                            label="Username/Email"
                                                            fullWidth
                                                            onChange={(e) => setUsername(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-floating">
                                                        <TextField
                                                            id="password"
                                                            label="Password"
                                                            autoComplete={'off'}
                                                            type={hidden ? "password" : "text"}
                                                            fullWidth
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            required
                                                        />
                                                        {hidden ? (
                                                            <VisibilityOffIcon
                                                                className="pass_show"
                                                                onClick={() => setHidden(!hidden)}
                                                            />
                                                        ) : (
                                                            <VisibilityIcon
                                                                className="pass_show"
                                                                onClick={() => setHidden(!hidden)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="LoginAction-Container justify-content-end">
                                                        <div
                                                            className="forgotPassword-Link c-blue"
                                                            onClick={handleNewPass}
                                                        >
                                                        <span className="d-flex justify-content-end">
                                                          Forgot Password?
                                                        </span>
                                                        </div>
                                                    </div>
                                                    {/*<div className={'reCaptcha'}>*/}
                                                    {/*    <form onSubmit={() => {*/}
                                                    {/*        recaptchaRef.current.execute();*/}
                                                    {/*    }}>*/}
                                                    {/*        <ReCAPTCHA*/}
                                                    {/*            ref={recaptchaRef}*/}
                                                    {/*            sitekey="6Lee2TkdAAAAAAJcowizQ13Wc795E_qWQeDZJwoZ"*/}
                                                    {/*            type={'image'}*/}
                                                    {/*            onChange={onChange}*/}
                                                    {/*        />*/}
                                                    {/*    </form>*/}
                                                    {/*    {isCaptchaValid[1] &&*/}
                                                    {/*    <span className={'CaptchaError'}>please fill the captcha</span>}*/}
                                                    {/*</div>*/}
                                                    <button type="submit" className="button button-3">
                                                      <span className="button-inner">
                                                        <span className="button-content">
                                                          <span className="text">
                                                              Login
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </button>
                                                </form>
                                                <div className="googleLogin">
                                                    <GoogleLogin
                                                        className={'googleLoginBtn'}
                                                        clientId="766078001860-4nnidh2hnfgqu02ekgsa7g9v59bo0k6d.apps.googleusercontent.com"
                                                        buttonText="Login With Google"
                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        cookiePolicy={'single_host_origin'}
                                                    />
                                                </div>
                                                <div>
                                                    <FacebookLoginWithButton
                                                        className={'FbLoginButton'}
                                                        appId="949279355668718"
                                                        autoLoad={false}
                                                        fields="name,email,picture"
                                                        onClick={responseFacebook}
                                                        callback={responseFacebook}
                                                        icon="fa-facebook"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
;

export default Login;
