import React, {Component} from "react";
import {Link} from "react-router-dom";
import logo from "../../../src/assets/images/LandingPage/logo.png";
import pattern from "../../../src/assets/images/LandingPage/patterns/pattern.jpg";
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Modal, Button, Row, Alert } from "react-bootstrap";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import "./inviteForm.css"
import genieComment from "../../assets/images/your_wish_command.png"
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GoogleLogin from 'react-google-login';
import ViewFull from "../../assets/images/viewfull.png";
import GoogleIcon from "../../assets/images/search.png";
import FacebookLoginWithButton from "react-facebook-login";
import FacebookIcon from "../../assets/images/LandingPageNew/Facebook_icon.svg";
import PhoneNumberField from "../../components/PhoneNumber";

const emailAddess = window.location.pathname.substr(1).split('/')

class InviteForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            firstname: "",
            lastname: "",
            phonenumber: "",
            password: "",
            cPassword: "",
            email:emailAddess ? emailAddess[2] : null,
            signuperrormessage: false,
            signuperrorpassword: false,
            signupsucess: false,
            showWelcomeModel: false,
            hidden: true,
            confirmHidden: true,
            showphone: false,
            MessageSignUp:"",
            isLoggedIn: false,
            userInfo: {
                name: "",
                emailId: "",
            },
            isTermsAccepted:false,
            phoneError: false,
        }
    }


    submitSignupBtn = (e) => {
        e.preventDefault();
        const {
            firstname,
            lastname,
            email,
            phonenumber,
            password,
            cPassword,
            phoneError
        } = this.state;

        const Pass_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&^_~+-.])[A-Za-z\d!@#$%&^-_~+-.]{8,}$/;
        const data = {
            password_confirmation: cPassword,
            password: password,
            email: email,
            firstname: firstname,
            lastname: lastname,
            phonenumber,
        };

        if(!phoneError) {
            if (!Pass_REGEX.test(password)) {
                this.handleAleart();
            } else if (password !== cPassword) {
                this.setState({
                    signuperrorpassword: true,
                    signuperrormessage: false,
                    signupsucess: false,
                });
            } else {
                this.createUser(data);
            }
        }
    };



    createUser = (data) => {
        if (this.state.isTermsAccepted){
            axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=32`)
                .then((res) => {
                    const allData = {
                        ...data,
                        emailtext: res.data[0].thetext
                    }
                    axios.post(process.env.REACT_APP_SERVER + "auth/register", allData)
                        .then((res) => {
                            this.setState({
                                firstname: "",
                                lastname: "",
                                email: "",
                                phonenumber: "",
                                password: "",
                                cPassword: "",
                                signuperrormessage: false,
                                signuperrorpassword: false,
                                signupsucess: true,
                                showWelcomeModel: true,
                            });
                        })
                        .catch((err) => {
                            this.setState({signuperrorpassword: false});
                            this.setState({signuperrormessage: true});
                            this.setState({signupsucess: false});
                        });
                }).catch((err) => {
                console.log(err)
            })
        }
    }


    responseGoogle = (response) => {
        axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
            email: response.profileObj.email,
            name: response.profileObj.name,
            accessToken: response.accessToken,
            type: 'google'
        }).then((res) => {
            localStorage.setItem("login", true);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('email', res.data.users.email)
            localStorage.setItem('name', res.data.users.name)
            localStorage.setItem("isLogin", 1);
            localStorage.setItem("userid", res.data.users.id);
            this.props.history.push('/maindashboard')
        }).catch((err) => {
            console.log(err)
        })
    }

    responseFacebook = (response) => {
        axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
            email: response.email,
            name: response.name,
            accessToken: response.accessToken,
            type: 'facebook'
        }).then((res) => {
            localStorage.setItem("login", true);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('email', res.data.users.email)
            localStorage.setItem('name', res.data.users.name)
            localStorage.setItem("isLogin", 1);
            localStorage.setItem("userid", res.data.users.id);
            this.props.history.push('/maindashboard')
        }).catch((err) => {
            console.log(err)
        })
    }
    
    handleClose = () => this.setState({show: false});

    handleAleart = () => {
        this.setState({show: true});
    };

    closeWelcomeModel = () => {
        this.setState({showWelcomeModel: false});
    };

    toggleVisibility = () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    };

    toggleConfirmVisibility = () => {
        this.setState({
            confirmHidden: !this.state.confirmHidden,
        });
    };

    render() {
        const {
            show,
            firstname,
            lastname,
            email,
            phonenumber,
            password,
            cPassword,
            signuperrormessage,
            signuperrorpassword,
            signupsucess,
            phoneError
        } = this.state;

        return (
            <>
                <Modal show={show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password Rule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={"font-weight-bold"}>
                            Your password must be at least 8 characters long.
                        </Row>
                        <div className={"ml-3"}>
                            <Row>It cannot begin with a number or special character</Row>
                            <Row>It must contain at least 1 upper case letter</Row>
                            <Row>It must contain at least 1 lower case letter</Row>
                            <Row>It must contain at least 1 number</Row>
                            <Row>It must contain at least 1 special character</Row>
                            <Row>Valid Special Characters are:</Row>
                            <strong className={"ml-4"}>! @ # $ % ^ - _ ~ + .</strong>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleClose}>
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    className="Welcome_modal"
                    show={this.state.showWelcomeModel}
                    onHide={this.closeWelcomeModel}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title className="d-flex justify-content-center position-relative">
                            <div className="icon-box">
                                <i className="material-icons">&#xE876;</i>
                            </div>
                        </Modal.Title>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <div
                                className="background-pattern-img background-loop"
                                style={{backgroundImage: `url(${pattern})`}}
                            />
                            <div className="background-pattern-gradient"/>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="mb-4">
                            <div className={"modal_title"}>
                                <h1>Welcome</h1>
                            </div>
                            <div>Your account has been created successfully.</div>
                        </div>
                        <Link to={"/login"} className="button button-standard">
                            <div className="button-inner">
                                <div className="button-content">
                                    <h4>Get Started</h4>
                                </div>
                            </div>
                        </Link>
                    </Modal.Body>
                </Modal>
                <div className="invite_signup_page">
                    <div className="position-absolute genie_comment">
                        <img src={genieComment} width={200} className={"genie_img_invite"}/>
                    </div>
                    <div className={`navigation navigation-invite`}>
                        <div className="navigation-wrapper navigation_Header">
                            <div className="container">
                                <div className="navigation-inner login-header">
                                    <div className="navigation-logo">
                                        <Link to="/">
                                            <img src={logo} alt="orions-logo" height="60px"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form-section signup-form">
                        <div className="Form-invite-contain">
                            <div className="container">
                                <div className="row gx-5 contact-form-section-row justify-content-center">
                                    <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-10 col-12 offset-1">
                                        <form onSubmit={this.submitSignupBtn}>
                                        <div className="email_input">
                                            <TextField
                                                style={{border:'none'}}
                                                id="Email Address"
                                                label="Email Address"
                                                fullWidth
                                                value={email}
                                                // disabled
                                            />
                                        </div>
                                        <div className="contact-form drop-shadow-2">
                                            <div className="contact-form-wrapper contact-form-wrapper-invite">
                                                <div className="section-heading section-heading-2 center">
                                                    <div className="main-heading c-blue">
                                                        <h1>Invite Form</h1>
                                                    </div>
                                                </div>
                                                    {phoneError && (
                                                        <Alert key="wrong-login" variant="danger">
                                                            The phone number does not appear to be valid!
                                                        </Alert>
                                                    )}
                                                    {signuperrormessage && (
                                                        <Alert key="wrong-login" variant="danger">
                                                            Sorry that Email already exists.
                                                        </Alert>
                                                    )}
                                                    {signuperrorpassword && (
                                                        <Alert key="wrong-login" variant="danger">
                                                            Your passwords do not match!
                                                        </Alert>
                                                    )}
                                                    {signupsucess && (
                                                        <Alert key="wrong-login" variant="success">
                                                            User created successfully
                                                        </Alert>
                                                    )}

                                                    <div className={"mt-3"}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="First Name"
                                                            label="First Name"
                                                            fullWidth
                                                            value={firstname}
                                                            onChange={(e) =>
                                                                this.setState({firstname: e.target.value})
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                    <div className={"mt-3"}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="Last Name"
                                                            label="Last Name"
                                                            fullWidth
                                                            value={lastname}
                                                            onChange={(e) => this.setState({lastname: e.target.value})}
                                                            required
                                                        />
                                                    </div>
                                                    <div className={"mt-3 invite-Number"}>
                                                        <PhoneNumberField
                                                            className={"PhoneInput"}
                                                            phoneNumber={phonenumber}
                                                            setPhoneNumber={(phonenumber) =>this.setState({phonenumber})}
                                                        />
                                                    </div>
                                                    <div className={"mt-3"}>
                                                        <TextField
                                                            variant="outlined"
                                                            type={this.state.hidden ? "password" : "text"}
                                                            id="password"
                                                            label="Password"
                                                            fullWidth
                                                            value={password}
                                                            onChange={(e) =>
                                                                this.setState({password: e.target.value})
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <div className="d-flex cursor-pointer">
                                                                            {this.state.hidden ? (
                                                                                <VisibilityOffIcon
                                                                                    fill="gray"
                                                                                    className="pass_show_signup"
                                                                                    onClick={this.toggleVisibility}
                                                                                />
                                                                            ) : (
                                                                                <VisibilityIcon
                                                                                    fill="gray"
                                                                                    className="pass_show_signup"
                                                                                    onClick={this.toggleVisibility}
                                                                                />
                                                                            )}
                                                                            <ErrorOutlineIcon
                                                                                className="info-icon ml-2"
                                                                                onClick={this.handleAleart}
                                                                            />
                                                                        </div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                    <div className={"mt-3 position-relative"}>
                                                        <TextField
                                                            variant="outlined"
                                                            type={this.state.confirmHidden ? "password" : "text"}
                                                            id="Confirm password"
                                                            label="Confirm password"
                                                            fullWidth
                                                            value={cPassword}
                                                            onChange={(e) =>
                                                                this.setState({cPassword: e.target.value})
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        {this.state.confirmHidden ? (
                                                                            <VisibilityOffIcon
                                                                                className="pass_show_signup cursor-pointer"
                                                                                onClick={this.toggleConfirmVisibility}
                                                                            />
                                                                        ) : (
                                                                            <VisibilityIcon
                                                                                className="pass_show_signup cursor-pointer"
                                                                                onClick={this.toggleConfirmVisibility}
                                                                            />
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            required
                                                        />
                                                        <div className='pt-2 position-relative term_checkbox'>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="checkedB"
                                                                        color="primary"
                                                                        onChange={(e)=>this.setState({isTermsAccepted:e.target.checked})}
                                                                    />
                                                                }
                                                                label="I accepted the Terms of Service"
                                                            />
                                                                <Link to={'/terms-of-use'}>
                                                                    <img src={ViewFull} width={17} height={17} className='term_view' />
                                                                </Link>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="button button-3">
                                                        <span className="button-inner">
                                                          <span className="button-content">
                                                            <span className="text">Get Start</span>
                                                          </span>
                                                        </span>
                                                    </button>
                                                <div>
                                                    <div className="googleLogin">
                                                        <GoogleLogin
                                                            className={'googleLoginBtn'}
                                                            clientId="766078001860-4nnidh2hnfgqu02ekgsa7g9v59bo0k6d.apps.googleusercontent.com"
                                                            onSuccess={(response)=>this.responseGoogle(response)}
                                                            onFailure={(response)=>this.responseGoogle(response)}
                                                            cookiePolicy={'single_host_origin'}
                                                        >
                                                            <img src={GoogleIcon} alt="FacebookIcon" style={{marginRight: "10px"}} height="28px" />
                                                            <span>Continue with Google</span></GoogleLogin>
                                                    </div>
                                                    <div>
                                                        <FacebookLoginWithButton
                                                            className={'FbLoginButton'}
                                                            appId="949279355668718"
                                                            autoLoad={false}
                                                            fields="name,email,picture"
                                                            onClick={(response)=>this.responseFacebook(response)}
                                                            callback={(response)=>this.responseFacebook(response)}
                                                            textButton={"Continue with Facebook"}
                                                            icon={<img src={FacebookIcon} alt="FacebookIcon" style={{marginRight: "10px"}}/>}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showphone} onHide={() => this.setState({showphone: false})}>
                    <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                        <Modal.Title> Phone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> Enter Valid Phone Number!</Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.setState({showphone: false})}
                            style={{backgroundColor: "#7030a0", color: "white"}}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default InviteForm;
