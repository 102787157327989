import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import Section3 from './Section3';
import Section4 from './Section4';
import ReleaseMyInfo from './ReleaseMyInfo';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	tabContainer: {
		color: 'white',
		backgroundColor: '#7030a0',
	},
	SelectedtabContainer: {
		backgroundColor: 'rgba(245, 0, 87, 0.08)',
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<span>{children}</span>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}

const SectionTabBars = ({
	itemName,
	existingSharedReleaseWithTags,
	shareReleaseContacts,
	rowid,
	handleIsShareToChange,
	handleIsReleaseToChange,
	saveIsShareIsReleaseto,
	onChangeFiles,
	existingFiles,
	removedExistingFiles,
	files,
	removedFiles,
	saveMedia,
	nIncapacitated,
	nDeceased,
	getReleaseMyInformation,
}) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" className={classes.tabContainer}>
				<Tabs
					variant="fullWidth"
					value={value}
					onChange={handleChange}
					aria-label="nav tabs example"
					className={classes.SelectedtabContainer}
				>
					<LinkTab
						label="Shared/Release To"
						href="/SharedRelease"
						{...a11yProps(0)}
					/>
					<LinkTab label="File Attached" href="/fileAttach" {...a11yProps(1)} />
					<LinkTab
						label="Release My Info"
						href="/ReleaseInfo"
						{...a11yProps(2)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0} className="custom-tab-container">
				<Section3
					existingSharedReleaseWithTags={existingSharedReleaseWithTags}
					shareReleaseContacts={shareReleaseContacts}
					itemName={itemName}
					rowid={rowid}
					handleIsShareToChange={handleIsShareToChange}
					handleIsReleaseToChange={handleIsReleaseToChange}
					saveIsShareIsReleaseto={saveIsShareIsReleaseto}
				/>
			</TabPanel>
			<TabPanel value={value} index={1} className="custom-tab-container">
				<Section4
					onChangeFiles={onChangeFiles}
					existingFiles={existingFiles}
					removedExistingFiles={removedExistingFiles}
					files={files}
					removedFiles={removedFiles}
					itemName={itemName}
					saveMedia={saveMedia}
					rowid={rowid}
				/>
			</TabPanel>
			<TabPanel value={value} index={2} className="custom-tab-container">
				<ReleaseMyInfo
					nIncapacitated={nIncapacitated}
					nDeceased={nDeceased}
					getReleaseMyInformation={getReleaseMyInformation}
				/>
			</TabPanel>
		</div>
	);
};

export default SectionTabBars;
