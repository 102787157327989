import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import genie_img from "../../assets/images/genie_image.jpg";
import {Button} from "@material-ui/core";
import './NewInvitedUser.css'
import axios from "axios";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {Modal, Row, Col} from "react-bootstrap";


const NewInvitedUser = () => {
    const id = useParams()
    const decodedData = atob(id.EncodedData).split('/')
    const [sharedewishes, setSharedWishes] = useState([]);
    const styles = {
        HandleRow: {
            maxWidth: "900px",
            borderColor: '#aaadb0'
        },
    };

    useEffect(() => {
        const server = process.env.REACT_APP_SERVER
        axios.get(server + `getsharedwithdistributedtoheader?nUserID=${decodedData[0]}&nContactID=${decodedData[1]}&nWhichOne=1`)
            .then((res) => {
                setSharedWishes(res.data);
            }).catch((err) => {
            console.log(err)
        })
    }, []);

    const [showLegend, setShowLegend] = React.useState(false);

    const [LegendData, setLegendData] = React.useState('');

    const handleLegendDailog = () => {
        getLegendData()
        setShowLegend(true)
    }

    const handleCloseLegend = () => {
        setShowLegend(false)
    }
    const getLegendData = () => {
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=26`)
            .then((res) => {
                setLegendData(res.data[0])
            }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <Modal show={showLegend} onHide={handleCloseLegend}>
                <Modal.Header closeButton className={'modelHeader'}>
                    <Modal.Title style={{fontSize: '28px', lineHeight: '1'}}>
                        <div dangerouslySetInnerHTML={{__html: LegendData.theheader}}></div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'ml-3'}
                         dangerouslySetInnerHTML={{__html: LegendData.thetext}}
                    >
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={handleCloseLegend}
                            style={{backgroundColor: '#7030a0', color: 'white', textTransform: 'lowercase'}}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <section className="wisheys-shared-section">
                <Row className="mx-auto text-center mobile-button back-logout-btn Shared-me_details">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to='/'>
                            <Button style={{backgroundColor: '#7030a0', color: 'white', textTransform: 'capitalize'}}>
                                <span
                                    className={'d-flex align-items-center justify-content-center'}><ExitToAppIcon/>&nbsp;Exit</span>
                            </Button>
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="btnall">
                        <img src={genie_img} className="genie_model" style={{float: 'right'}}
                             onClick={handleLegendDailog}/>
                    </Col>
                </Row>
                <div className="mx-2 Shared_details_content">
                    <div className="m-auto" style={{maxWidth: '900px'}}>
                        {
                            sharedewishes.length < 1 ? null :
                                <h4 className="">{sharedewishes[0]?.fname} {sharedewishes[0]?.lname} is sharing the
                                    following
                                    wishes with you</h4>
                        }
                    </div>
                    <div
                        className="main-container-wishes border_box card"
                        style={styles.HandleRow}
                    >
                        {sharedewishes.length === 0
                            ? <div style={{height: '50vh'}}>
                                <h1 className={'d-flex justify-content-center text-secondary align-items-center h-100'}
                                    style={{fontSize: '20px'}}>No Data
                                    Found</h1>
                            </div>
                            : sharedewishes.map((filteredWish) => {
                                return (
                                    <div className={"m-2"}>
                                        <div
                                            className={"main-container-wishes card"}
                                            style={styles.HandleRow}
                                        >
                                            <div className={""}>
                                                <div className={"main-wishes"}>
                                                    <img
                                                        src={`https://reactnotes.s3.us-east-2.amazonaws.com/${filteredWish.s3filename}`}
                                                    />
                                                    <div>
                                                        <h5 className={"mt-3 ml-2"}>
                                                            {filteredWish.fname} {filteredWish.lname}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className={"mt-1 WishesbyUser"}>
                                                    <p className={"ml-3 mt-1"} style={{maxHeight: "50px"}}>
                                                        {filteredWish.itemname.split("\n").map((str) => (
                                                            <p>{str}</p>
                                                        ))}
                                                    </p>
                                                    <Link
                                                        to={`/View-Wishes/${window.btoa(decodedData[0] + '/' + filteredWish.id)}`}>
                                                        <Button variant="contained" color="primary">
                                                            Details...
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NewInvitedUser;