import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {
    Checkbox,
    Paper, Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

function LookupsEnhancedTableHead({isInputDisabled}) {
    return (
        <TableHead>
            <TableRow>
                {!isInputDisabled ? (
                    <TableCell key={1} align={'left'} style={{fontSize: '16px'}}/>
                ) : null}
                <TableCell key={0} align={'left'} style={{fontSize: '16px'}}>
                    Suggestions
                </TableCell>
                {/*{LookupsHeadCells.map((headCell, index) => (
					// <TableCell key={index} align={'left'} style={{ fontSize: '16px' }}>
					// 	{headCell.label}
					// </TableCell>
				))}*/}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 700,
        overflowX: 'scroll',
    },
    customCell: {
        padding: '8px 16px',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    customHeaderCell: {
        fontSize: '16px',
    },
}));

const LookupsModal = ({
  Lookups,
  setSelectedSuggetion,
  setLookups,
  suggestionsData,
  selectedSuggetion,
  handleSelectSuggetions,
  handleSaveSuggetions,
  isInputDisabled,
}) => {
    const classes = useStyles();
    return (
        <>
            <Modal
                size="lg"
                show={Lookups}
                className={"modal-style lookups_modal"}
                onHide={() => {
                    setSelectedSuggetion('');
                    setLookups(false);
                }}
                centered
            >
                <Modal.Header closeButton className="ShareRelease-Modal-Headers">
                    <Modal.Title>List of Items You May Want to Consider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sharedRelease">
                        <Paper className={classes.paper}>
                            <TableContainer className={classes.container}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    <LookupsEnhancedTableHead isInputDisabled={isInputDisabled}/>
                                    <TableBody>
                                        {suggestionsData.map((row, index) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={index}>
                                                    {!isInputDisabled ? (
                                                        <TableCell
                                                            align="right"
                                                            className={classes.customCell}
                                                        >
                                                            <Radio
                                                                checked={selectedSuggetion === row.id}
                                                                onChange={() => handleSelectSuggetions(row.id.toString())}
                                                                id={row.id.toString()}
                                                                color="primary"
                                                                inputProps={{
                                                                    'aria-label': 'secondary checkbox',
                                                                }}
                                                            />
                                                        </TableCell>
                                                    ) : null}
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        id={index}
                                                        className={classes.customCell}
                                                        onClick={() => handleSelectSuggetions(row.id.toString())}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        {row.sSubject}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center pt-0">
                    <Button
                        variant="primary"
                        className="custom-btn"
                        disabled={!selectedSuggetion}
                        onClick={handleSaveSuggetions}
                    >
                        Ok
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    );
};

export default LookupsModal;
