import React from 'react';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import images from 'react-payment-inputs/images';
import './billing.css';
import { Link } from 'react-router-dom';
import Header from '../../components/NewHeader/Header';
import Logout from '../../components/logout/Logout';
const Billing = () => {
    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
    } = usePaymentInputs();

    return (
        <>
            <Header title="Billing" />
            <Row className="mx-auto  mobile-button back-logout-btn pt-2">
                <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
                    <Link to="/maindashboard" className="btn btn-light font-weight-bold border  pl-3 pr-3 pt-2 pb-2" >Back</Link>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
                    <Logout />
                </Col>
            </Row>
            <Container>
                <Row className="mt-4 pt-4">
                    <Col md={3}>
                    </Col>
                    <Col md={6}>
                        <div className="mx-auto text-center">
                            <Form className="text-left">
                                <Form.Check
                                    type="radio"
                                    label="One month only"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Per month"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Per Year"
                                />
                                <label><b>Payment Mode</b></label>
                                <Form.Row className="mb-4">
                                    <Col>
                                        <Form.Control placeholder="First name" />
                                    </Col>
                                    <Col>
                                        <Form.Control placeholder="Last name" />
                                    </Col>
                                </Form.Row>
                                <PaymentInputsWrapper {...wrapperProps}>
                                    <svg {...getCardImageProps({ images })} />
                                    <input {...getCardNumberProps()} />
                                    <input {...getExpiryDateProps()} />
                                    <input {...getCVCProps()} />
                                </PaymentInputsWrapper>
                                <Button variant="primary" type="submit" className="btn-lg btn-block custom-btn mt-4">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Billing;