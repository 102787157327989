import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import { Form, FormControl, FormGroup, Button, Alert, Table, Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import $ from "jquery";
const Myprofilecontactlist = (params) => {
    const [contactdata, setContactdata] = useState([]);
    const [states, setStates] = useState();

    function GetContactData() {
        const data = {
            nUserID: localStorage.getItem('userid')
        };
        var token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_SERVER + 'contact/listall', data,
            { headers: { Authorization: `Bearer ${token}` } }).
            then(res => {
                setContactdata(res.data[0]);

            }).catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_SERVER + 'getstates').
            then(res => {
                setStates(res.data);
            });
    }

    useEffect(() => {
        GetContactData()

    }, []);

    useEffect(() => {
        contactdata && contactdata.forEach(els => {
            if (els.isICE == 1) {
                $(`.tablecheckbox[name=${els.contactid}]`).prop("checked", true)
            }
        });
    });

    return (
        <section className={params.checkbox == 'true' ? 'contact-table' : ''}>
            <h4 className="mb-4 custom-text-color">{params.headingtop}
                {
                    params.showbutton == "1" &&
                    <Link to="/contacts" className="btn custom-btn btn-primary float-right">Maintance</Link>
                }
            </h4>
            <Table hover bordered responsive className={params.checkbox == 'true' ? 'contact-table' : ''}>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        {
                            params.checkbox == 'true' ? " " : params.userName == 'false' ? '' : <th>User Name</th>
                        }

                        <th>Phone #</th>
                        {params.checkbox == 'true' ? <th>ICE</th> : ''}
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {(contactdata || []).map(ele =>
                        <tr key={ele.id} onClick={() => params.selectTableRow(ele)}>
                            <td>{ele.fname}</td>
                            <td>{ele.lname}</td>
                            {params.checkbox == 'true' ? " " : params.userName == 'false' ? '' : <td>{ele.username}</td>}
                            <td>{ele.phonenumber}</td>
                            {params.checkbox == 'true' ? <td><input type="checkbox" name={ele.contactid} className="tablecheckbox" readOnly /></td> : ''}
                            <td>{ele.email}</td>
                        </tr>
                    )}

                </tbody>
            </Table>
        </section>
    );
}
export default Myprofilecontactlist