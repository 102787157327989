import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "@pathofdev/react-tag-input/build/index.css";
import ShareReleaseAlbumModel from "./ShareReleaseAlbumModel";
import Add from "../../../assets/images/Add.png";
import ic_users from "../../../assets/images/ic_users.png";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const ShareAlbumContainer = ({ itemDetails, getAllItems }) => {
    let history = useHistory();

    const [ShareModalShow, setShareModalShow] = React.useState(false);
    const [existingSharedWith, setExistingSharedWith] = useState([]);
    const classes = useStylesBootstrap();

    function handleShareModalShow() {
        getExistingSharedWithList(true);
    }

    const saveIsShareToIsReleaseChange = (event) => {
        saveIsShareIsReleaseto(itemDetails.WishID);
    };

    function getExistingSharedWithList(isModalOpen) {
        var token = localStorage.getItem("token");
        var sWhichOne = 'SHARED'
        axios
            .get(
                process.env.REACT_APP_SERVER +
                `contact/getisshareisreleaseto?nUserID=${localStorage.getItem(
                    "userid"
                )}&WishID=${itemDetails.WishID}&sWhichOne=${sWhichOne}`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                const data = (res.data || []).length ? res.data : [];
                setExistingSharedWith(
                    data.map((r) => {
                        return {
                            ...r,
                            editsharedwith: r.sharedwith,
                        };
                    })
                );
                setShareModalShow(isModalOpen);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function saveIsShareIsReleaseto() {
        const allSelectedSharedWith = existingSharedWith.filter(
            (f) => f.editsharedwith === 1
        );
        let data;
        if (allSelectedSharedWith.length) {
            data = {
                WishID: history.location.search.replace("?", ""),
                sContacts: allSelectedSharedWith
                    .map((s) => s.contactid)
                    .join(",")
                    .toString(),
                type: "SharedWith",
            };
        } else {
            data = {
                WishID: history.location.search.replace("?", ""),
                sContacts: "",
                type: "SharedWith",
            };
        }

        assignExistingSharedToReleasedTo(data.WishID, data.sContacts, data.type);
        getExistingSharedWithList(false);
    }

    function assignExistingSharedToReleasedTo(
        WishID,
        sContacts,
        sSharedOrReleaseTo
    ) {
        const data = {
            nUserID: localStorage.getItem("userid"),
            nInvItemID: itemDetails.WishID,
            sContacts: sContacts,
            sSharedOrReleaseTo: sSharedOrReleaseTo,
        };

        var token = localStorage.getItem("token");
        return axios
            .post(
                process.env.REACT_APP_SERVER + "inventory/addsharedwithreleaseto",
                data,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                getAllItems();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleIsShareToChange = (id, checked) => {
        const newContactsData = existingSharedWith.map((contact) => {
            return {
                ...contact,
                ...(contact.contactid === Number(id)
                    ? { editsharedwith: checked ? 1 : 0 }
                    : {}),
            };
        });
        setExistingSharedWith(newContactsData);
    };

    function isSaveButtonDisabled() {
        return !existingSharedWith.some(
            (sharedRelease) =>
                sharedRelease.editsharedwith !== sharedRelease.sharedwith
        );
    }

    const closeShareReleaseModalShow = () => {
        const newContactsData = existingSharedWith.map((contact) => {
            return {
                ...contact,
                editsharedwith: contact.sharedwith,
            };
        });
        setExistingSharedWith(newContactsData);
        setShareModalShow(false);
    };

    return (
        <>

            {!!itemDetails.hasSharedWith ? (
                <Tooltip
                    arrow
                    classes={classes}
                    placement="top"
                    title={`Wish is Shared With:${itemDetails.sharedwith}`}
                >
                    <img
                        src={ic_users}
                        alt="shared-with-icon"
                        className="w-5"
                        style={{ width: "35px", height: "35px",cursor:"pointer"}}
                        onClick={() => handleShareModalShow()}
                    />
                </Tooltip>
            ) : (
                <div className='d-flex justify-content-center'>
                    <Tooltip
                        arrow
                        classes={classes}
                        placement="top"
                        title={itemDetails.sharedwith}
                    >
                        <img
                            src={Add}
                            alt="Add"
                            className="w-5"
                            style={{ width: "25px",paddingTop:'10px',cursor:'pointer'}}
                            onClick={() => handleShareModalShow()}
                        />
                    </Tooltip>
                </div>
            )}

            {ShareModalShow ? (
                <ShareReleaseAlbumModel
                    ShareReleaseModalShow={ShareModalShow}
                    closeShareReleaseModalShow={closeShareReleaseModalShow}
                    rowid={itemDetails.WishID}
                    existingSharedReleaseWithTags={existingSharedWith}
                    shareReleaseContacts={[]}
                    handleIsShareToChange={handleIsShareToChange}
                    saveIsShareToIsReleaseChange={saveIsShareToIsReleaseChange}
                    isShowShareColumn={true}
                    isShowReleaseColumn={false}
                    isSaveButtonDisabled={isSaveButtonDisabled}
                />
            ) : null}
        </>
    );
};

export default ShareAlbumContainer;
