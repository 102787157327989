import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import { Form, FormControl, FormGroup, Button, Alert, Table, Row, Col, Container } from 'react-bootstrap';
import Myprofileform from '../../components/myprofileform/myprofileform';
import Userloginhistory from '../../components/userloginhistory/userloginhistory';
import Myprofilecontactlist from '../../components/myprofilecontactlist/myprofilecontactlist';
import Paymenthistory from '../../components/paymenthistory/paymenthistory';
const Userhistory = () => {
    const [userListData, setUserListData] = useState('')
    function selectTableRow(row) {
        setUserListData(row);
    }
    return (
        <section className="user-history">
            <Row className="p-4">
                <Col md={4}>
                    <Myprofilecontactlist headingtop="User list" showbutton="0" checkbox="false" selectTableRow={(row) => selectTableRow(row)} />
                </Col>
                <Col md={2}>
                    <Myprofileform userListData={userListData} />
                </Col>
                <Col md={3}>
                    <Userloginhistory />
                    <Myprofilecontactlist headingtop="User contacts" showbutton="1" checkbox="true" />
                </Col>
                <Col md={3}>
                    <Paymenthistory />
                </Col>
            </Row>
        </section>
    )
}

export default Userhistory;