import React, {useState, useEffect} from 'react';
import { useHistory} from "react-router-dom";
import {Form, Button, Alert} from 'react-bootstrap';
import axios from 'axios';
import Cookie from "../../utils/cookie";

const MaintenanceLogin = () => {
    let history = useHistory();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loginerror, setemailerror] = useState(false);
    const [errormessage, seterrormessage] = useState('');
    const [sDeviceID, setCurrentIp] = useState(null)

    useEffect(() => {
        seterrormessage('Wrong username/password');
    },[]);

    const submitClickBtn = (e) => {
        e.preventDefault()
        getCurrentIp()
        const data = {
            email: username,
            password: password,
            sDeviceID,
            sDeviceType: 'WEB'
        };
        axios.post(process.env.REACT_APP_SERVER + 'auth/login', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            document.cookie = `serviceplanid=${res.data.users.serviceplanid}; expires=${new Date(
                Date.now() + 10800000
            ).toUTCString()}; path=/`;
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('login', true);
            localStorage.setItem('userid', res.data.users.id);
            localStorage.setItem('email', res.data.users.email);
            localStorage.setItem('name', res.data.users.name);
            localStorage.setItem('accountnumber', res.data.users.accountnumber);
            localStorage.setItem('isLogin', 1)
            history.push('/maindashboard');
        }).catch(err => {
            setemailerror(true);
            console.log(err);
            seterrormessage('Wrong Username/Password');
        });
    }


    const getCurrentIp = () => {
        axios.get('https://geolocation-db.com/json/')
            .then(res => {
                setCurrentIp(res.data.IPv4);
            }).catch((err) => {
            console.log(err)
        })
    }


    const coomingSoon = () => {
        alert("comming soon");
    }
    return (
        <>
            <div className="p-1">
                <Form onSubmit={submitClickBtn}>
                    {loginerror === true &&
                    <Alert key="wrong-login" variant="danger">
                        {errormessage}
                    </Alert>
                    }
                    <Form.Group controlId="exampleForm.ControlInput2">
                        <Form.Control type="text" placeholder="Username/Email" required
                                      onChange={e => setUsername(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                        <Form.Control type="password" placeholder="Password" required
                                      onChange={e => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="btn-lg btn-block custom-btn"
                            style={{height: '45px'}}>
                        <span>Login</span>
                    </Button>
                    <p className="text-left pt-2 pb-2">
                        {/* This is sample text, this is sample text, this is sample text, this is sample text, this is sample text, this is sample text, this is sample text, this is sample text*/}
                    </p>
                </Form>
            </div>
        </>
    );
}

export default MaintenanceLogin;