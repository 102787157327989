import React from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import andriodApp from '../../assets/images/androidApp.png'
import appleApp from '../../assets/images/appleApp.png'

const LandingFooter = () => {
    const styles = {
        img1: {
            marginBottom: '5px',
            height: '50px'
        },
        img2: {
            marginBottom: '5px',
            height: '50px',
            width: '169.22px'
        }
    }
    const coomingSoon = () =>{
        alert("comming soon");
    }
    return (
        <div className="landing-footer-section">
            <Container>
                <div className="inner-landing-footer d-flex">
                    <div className="w-50">
                        <ul>
                            <li>
                                <Link to="#">About Us</Link>
                            </li>
                            <li>
                                <Link to="/faq">FAQ</Link>
                            </li>
                            <li>
                                <img src={appleApp} className="img1" onClick={coomingSoon}/>
                                <img src={andriodApp} className="img2" onClick={coomingSoon}/>
                            </li>
                        </ul>
                    </div>
                    <div className="w-50">
                        <ul>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/how-it-works">HOW It Works</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-use">Terms of Agreement</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LandingFooter;
