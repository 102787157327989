import React, {useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const SessionExpired = () => {
    const history = useHistory()
    const [TokenExpired, setTokenExpired] = useState(true)
    const handleClosePlan = () => {
        setTokenExpired(false);
        localStorage.removeItem('token');
        history.push('/')
    }
    return (
        <>
            <Modal show={TokenExpired} onHide={handleClosePlan}>
                <Modal.Header closeButton className={'modelHeader'}>
                    <Modal.Title>Session Has Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your session has ended.
                    You will be re-directed to the Login page where you can log back in.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{backgroundColor: '#7030a0'}} onClick={handleClosePlan}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SessionExpired