import React from 'react';
import {TableCell, TableHead, TableRow} from "@material-ui/core";
import PropTypes from "prop-types";

const StandardTableHead = ({headCells}) => {
    return (
        <TableHead>
            <TableRow>
                {(headCells || []).map((headCell, index) => (
                    <TableCell key={index} align={headCell.align}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

StandardTableHead.propTypes = {
    headCells:PropTypes.array
}

export default StandardTableHead;