import React from "react";
import Header from "../../components/NewHeader/Header";
import {Col, Row} from "react-bootstrap";
import {Link,useParams,useHistory} from "react-router-dom";
import Back from "../../assets/images/Back_white.png";


const ViewMessage = () => {
    const {message} = useParams()
    const history = useHistory()
    const handleBack = () => {
        history.goBack()
    }
    return (
        <div>
            <Header title={'VIEW MESSAGE'}/>
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall">
                    <span className="btn custom-link btn-primary" onClick={handleBack}>
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </span>
                </Col>
            </Row>
            <div style={{padding:'40px 40px',fontSize:'20px',fontFamily:'Monotype Corsiva'}}>
               <span> {message}</span>
            </div>
        </div>
    )
}
export default ViewMessage;