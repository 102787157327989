import React, {useEffect, useState} from 'react';
// css
import './popupprivacy.css';
import axios from "axios";


const Popup = () => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsTerms, setIsTerms] = React.useState(false);

    // popup Privacy Policy & terms thetext
    const [privacyText, setPrivacytext] = useState('')
    const [termsText, setTermstext] = useState('')


    const nUserID = localStorage.getItem('userid')
    const token = localStorage.getItem('token')

    const OpenModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const closeModalTerms = () => {
        setIsTerms(false)
    }

    const OpenModalTerms = () => {
        setIsTerms(true)
    }


    useEffect(() => {
        callingApiForDailoge();
        dailogeData();
    }, [])

    const callingApiForDailoge = () => {
        axios.post(process.env.REACT_APP_SERVER + `user/userflagssave?nUserID=${nUserID}`, nUserID, {
            headers: {Authorization: `Bearer ${token}`}
        })
            .then((res) => {
                axios.get(process.env.REACT_APP_SERVER + `user/userflagsget?nUserID=${nUserID}`, {
                    headers: {Authorization: `Bearer ${token}`}
                }).then((res) => {
                    setIsOpen(res.data[0].PrivacyNeedsUpdate !== undefined && res.data[0].PrivacyNeedsUpdate === 1);
                    setIsTerms(res.data[0].TermsOfUseNeedsUpdate !== undefined && res.data[0].TermsOfUseNeedsUpdate === 1);
                }).catch((err) => {
                    console.log(err)
                })
            }).catch((err) => {
            console.log(err)
        })
    }

    const dailogeData = () => {
        //privacy
        axios.get(process.env.REACT_APP_SERVER + 'privacypolicy')
            .then((res) => {
                setPrivacytext(res.data);
            }).catch((err) => {
            console.log(err)
        })

        //terms
        axios.get(process.env.REACT_APP_SERVER + 'termofuse')
            .then((res) => {
                setTermstext(res.data);
            }).catch((err) => {
            console.log(err)
        })
    }

    const styles={
        dailogHandle:{
            maxHeight:'550px',
            overflowX:'hidden'
        }
    }

    return (
        <React.Fragment>
            {/* Privacy Policy popup*/}
            <span className="Privacy-Policy">
                <label onClick={OpenModal} className="privacy">
                    Privacy Policy
                </label>
                <div className={modalIsOpen ? 'overlay active' : 'overlay'} onClick={() => closeModal()}/>
                <div
                    className={modalIsOpen ? 'updatepolicy active' : 'updatepolicy'}
                >
                    <div className="modal-title-content">
                        <h4>Privacy Policy Version Update</h4>
                        <div className="close-icon" onClick={() => closeModal()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#7030a0">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M0 0h24v24H0z" opacity="0.05"/>
                                    <path fill="#7030a0"
                                          d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="mt-2 modal-content-list text-left p-3" >
                        <div dangerouslySetInnerHTML={{__html:privacyText[0]?.thetext}}>
                        </div>
                    </div>
                </div>
            </span>
            {/* terms of use popup */}
            <span className="Privacy-Policy">
                <span onClick={OpenModalTerms} >
                    {' '}and {' '}
                    <label className="privacy">
                      Terms of Use
                    </label>
                </span>
                <div className={modalIsTerms ? 'overlay active' : 'overlay'} onClick={() => closeModalTerms()}/>
                <div
                    className={modalIsTerms ? 'updatepolicy active' : 'updatepolicy'}
                    isOpen={true}
                >
                    <div className="modal-title-content">
                        <h4>Terms of Use Version Update</h4>
                        <div className="close-icon" onClick={() => closeModalTerms()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#7030a0">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M0 0h24v24H0z" opacity="0.05"></path>
                                    <path fill="#7030a0"
                                          d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="mt-3 modal-content-list text-left p-3">
                        <div dangerouslySetInnerHTML={{__html:termsText[0]?.thetext}}>
                        </div>
                    </div>
                </div>
            </span>
        </React.Fragment>
    )
}

export default Popup
