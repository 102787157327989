import React, {useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import './Layout/Section1.css'
import axios from 'axios'
import genie_img from "../../assets/images/genie_image.jpg";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Alert from 'react-bootstrap/Alert';

const headCells = [
    {
        id: 'fname',
        disablePadding: true,
        label: 'File Name',
        align: 'left',
    },
    {
        id: 'fsize',
        numeric: true,
        disablePadding: false,
        label: 'File Size',
        align: 'left',
    },
    {
        id: 'uploadDate',
        numeric: true,
        disablePadding: false,
        label: 'Upload Date',
        align: 'center',
    },
    {
        id: 'remove',
        numeric: true,
        disablePadding: false,
        label: '',
        align: 'center',
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell key={index} align={headCell.align}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 500,
        overflowX: 'scroll',
    },
    customCell: {
        padding: '8px 16px',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    customHeaderCell: {
        fontSize: '16px',
    },
}));

const FileAttachedModal = ({
                               fileAttachedModalShow,
                               setFileAttachedModalShow,
                               itemName,
                               onChangeFiles,
                               existingFiles,
                               removedExistingFiles,
                               files,
                               removedFiles,
                               saveTooltipAttachment,
                               saveMedia,
                               rowid,
                               setExistingFiles
                           }) => {
    const classes = useStyles();
    const [loading,setLoading] = useState(false);
    const calculateTotalFileSize = () => {
        let existingTotalSize =
            (existingFiles || []).reduce(
                (total, lotObj) => total + Number(lotObj.filesize),
                0
            ) || 0;
        let totalSize =
            (files || []).reduce((total, lotObj) => total + Number(lotObj.size), 0) ||
            0;
        return existingTotalSize + totalSize;
    };

    const alertMessage = ()=>{
        setTimeout(()=>{
            <Alert variant='success'>
            File is Downloaded
        </Alert>
        },[2000])
    }

    const downloadImage = async (row) => {
        setLoading(true);
        var token = localStorage.getItem('token');
        const userId = localStorage.getItem('userid');
        axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${row.s3filename}&nUserID=${userId}`,
            {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                let link = document.createElement('a');
                link.setAttribute('href', res.data.url);
                link.setAttribute('download', row.filename);
                link.click();
                alertMessage();
                setLoading(false);
            }).catch((err)=>{
            console.log(err)
        setLoading(false)})
    };


    const [deleteMessage, setDeleteMassage] = useState(false);
    const [deleteID, setDeleteID] = useState('');
    const handleCloseDeleteMessage = () => setDeleteMassage(false);
    const handleShowDeleteMessage = () => setDeleteMassage(true);

    const [isLocalDelete, setIsLocalDelete] = useState(false);
    const [index, setIndex] = useState('');

    const [LegendData, setLegendData] = React.useState('');
    const [showLegend, setShowLegend] = useState(false);

    const handleDeleteFile = () => {
        {
            if (isLocalDelete) {
                removedFiles(index);
                setIsLocalDelete(false);
                setIndex('');
                handleCloseDeleteMessage();
            } else {
                removedExistingFiles(deleteID);
                setDeleteID('');
                handleCloseDeleteMessage();
            }
        }
    };

    const handleLegendDailog = () => {
        getLegendData()
        setShowLegend(true)
    }

    const handleCloseLegend = () => {
        setShowLegend(false)
    }

    const getLegendData = () => {
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=20`)
            .then((res) => {
                setLegendData(res.data[0])
            }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <>
            <Modal show={deleteMessage} onHide={handleCloseDeleteMessage}>
                <Modal.Body> Are you sure you want to remove this attachment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteMessage}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDeleteFile}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteMessage} onHide={handleCloseDeleteMessage}>
                <Modal.Body> Are you sure you want to remove this attachment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteMessage}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDeleteFile}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={fileAttachedModalShow}
                onHide={() => {
                    setFileAttachedModalShow(false);
                    setExistingFiles([]);
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="ShareRelease-Modal-Container"
            >
                <Modal.Header closeButton className="ShareRelease-Modal-Header">
                    <Modal.Title>Files Attached to Wish</Modal.Title>
                </Modal.Header>
                <div className={'modelLegend'}>
                    <img src={genie_img} alt="genie_img" className="genie ml-2 mt-2" onClick={handleLegendDailog}/>
                </div>
                <Modal.Body>
                    <div className="text-left">
                        <div className="pt-3 pb-3">
                            <b>Item Attachments</b>
                        </div>
                        {itemName ? (
                            <div className="ShareRelease-Container">{itemName}</div>
                        ) : null}
                    </div>
                    <div>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <label>
                                <b>Upload: </b>
                            </label>
                            <Form.File
                                id="custom-file"
                                label="Select File to Upload..."
                                custom
                                onChange={(e) => onChangeFiles(e)}
                                multiple
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <Paper className={classes.paper}>
                            <TableContainer className={classes.container}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    <EnhancedTableHead/>
                                    <TableBody>
                                        {existingFiles.map((row, index) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={index}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        id={index}
                                                        style={{wordBreak: 'break-all'}}
                                                    >
                                                        {row.filename}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        id={index}
                                                    >
                                                        {row.filesize}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        id={index}
                                                    >
                                                        {moment(row.created).format('d/M/YYYY')}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        id={index}
                                                    >
                                                        <div className={'d-flex'}>
                                                            <Button
                                                                variant="danger"
                                                                className="mx-2 btn-sm-height"
                                                                onClick={() => {
                                                                    handleShowDeleteMessage();
                                                                    setDeleteID(row.id);
                                                                }}
                                                                disabled={loading}
                                                            >
                                                                Remove
                                                            </Button>
                                                            <Button
                                                                variant="info"
                                                                className="mx-2 btn-sm-height"
                                                                onClick={() => downloadImage(row)}
                                                                disabled={loading}
                                                            >
                                                                Download{loading && <Spinner animation="border" size="sm" style={{marginLeft:"5px"}}/>}
                                                            </Button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {files && files.length
                                            ? files.map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={index}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            id={index}
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            id={index}
                                                        >
                                                            {row.size}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                            id={index}
                                                        >
                                                            {moment(row.lastModified).format('d/M/YYYY')}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                            id={index}
                                                        >
                                                            <Button
                                                                variant="danger"
                                                                className="mx-2 btn-sm-height"
                                                                onClick={() => {
                                                                    setIsLocalDelete(true);
                                                                    setIndex(index);
                                                                    handleShowDeleteMessage(index);
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                            : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        Total File Size:{' '}
                        <div className="newCount-Container ml-2">
                            {calculateTotalFileSize()}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="primary"
                        className="custom-btn"
                        onClick={() => {
                            saveMedia(rowid);
                            saveTooltipAttachment(rowid)
                            setFileAttachedModalShow(false);
                            setExistingFiles([]);
                        }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showLegend} onHide={handleCloseLegend}>
                <Modal.Header closeButton className={'modelHeader'}>
                    <Modal.Title>{LegendData.theheader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="model-genie"
                         dangerouslySetInnerHTML={{__html: LegendData.thetext?.toString().replaceAll('\n', '<p>\n</p>')}}>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={handleCloseLegend}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FileAttachedModal;
