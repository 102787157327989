import React from "react";
import {ElementsConsumer,} from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';

const InjectedCheckoutForm = (props) => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <CheckoutForm
                    elements={elements}
                    stripe={stripe}
                    planPrice={props.planPrice}
                    nTimeZoneID={props.nTimeZoneID}
                    nServicePlanID={props.nServicePlanID}
                    selectedDays={props.selectedDays}
                    stripProdNumber={props.stripProdNumber}
                    pricingType={props.pricingType}
                    subscribePlan={props.subscribePlan}
                    description={props.description}
                />
            )}
        </ElementsConsumer>
    );
};


export default InjectedCheckoutForm;