import React, {useEffect, useState} from 'react';
import Draggable from "react-draggable";
import Close from "../../assets/images/close.png";
import PropTypes from "prop-types";
import axios from "axios";

const DraggebleModal = ({screen, isOpen, setOpenModal}) => {
    const [readmeData, setReadmeData] = useState();

    useEffect(() => {
        if (isOpen) {
            axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=${screen}`)
                .then((res) => {
                    setReadmeData(res.data[0]);
                }).catch((err) => {
                console.log(err)
            })
        }
    }, [isOpen === true])

    const toggleDraggable = () => {
        setOpenModal(!isOpen);
    }

    if (isOpen) {
        return (
            <div className="start_btn cursor-pointer">
                <Draggable>
                    <div className="datacenter w-100">
                        <div
                            style={{backgroundColor: "white"}}
                            className={"getstard"}
                        >
                            <div
                                className="lets_start"
                                style={{ height: "fit-content" }}
                            >
                                <div className="lest_model_title">
                                    <p
                                        style={{paddingRight: "160px"}}
                                        dangerouslySetInnerHTML={{__html: readmeData ? readmeData["theheader"] : ""}}
                                    />
                                </div>
                                <div className="pr-2 cursor-pointer" onClick={toggleDraggable}>
                                    <img src={Close} alt="Close" height={20} width={20}/>
                                </div>
                            </div>
                            <p
                                className="lest_model_body"
                                dangerouslySetInnerHTML={{__html: readmeData ? readmeData["thetext"] : ""}}
                            />
                        </div>
                    </div>
                </Draggable>
            </div>
        );
    }
    return null;
};

DraggebleModal.propTypes = {
    screen: PropTypes.number,
    isOpen: PropTypes.bool,
    setOpenModal: PropTypes.func,
}

DraggebleModal.defaultProps = {
    screen: 1
}

export default DraggebleModal;