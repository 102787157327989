import React from 'react';
import {
	Paper, styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import "./AdditemDiscriptionWizard.css"
import Checkbox from '@material-ui/core/Checkbox';


const headCells = [
	{
		id: 'sharedwith',
		numeric: true,
		disablePadding: false,
		label: 'Share With',
		align: 'left',
	},
	{
		id: 'releaseto',
		numeric: true,
		disablePadding: false,
		label: 'Released To',
		align: 'left',
	},
	{
		id: 'fname',
		numeric: false,
		disablePadding: true,
		label: 'First Name',
		align: 'left',
	},
	{
		id: 'lname',
		numeric: true,
		disablePadding: false,
		label: 'Last Name',
		align: 'left',
	},
	{
		id: 'ice',
		numeric: true,
		disablePadding: false,
		label: 'ICE',
		align: 'left',
	},
	{
		id: 'phone',
		numeric: true,
		disablePadding: false,
		label: 'Phone #',
		align: 'left',
	},
	{
		id: 'email',
		numeric: true,
		disablePadding: false,
		label: 'Email',
		align: 'left',
	},
];

const MUITableCell = styled(TableCell)({
	padding:"5px 10px"
});

function EnhancedTableHead({ isShowShareColumn, isShowReleaseColumn }) {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) =>
					(headCell.id === 'sharedwith' && isShowShareColumn) ||
					(headCell.id === 'releaseto' && isShowReleaseColumn) ||
					(headCell.id !== 'sharedwith' && headCell.id !== 'releaseto') ? (
						<TableCell key={index} align={headCell.align}>
							{headCell.label}
						</TableCell>
					) : null
				)}
			</TableRow>
		</TableHead>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	container: {
		maxHeight: 370,
		overflowX: 'scroll',
	},
	customCell: {
		padding: '8px 16px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	customHeaderCell: {
		fontSize: '16px',
	},
}));

const SharedWithReleasedToGridContainer = ({
	contactsList,
	handleIsShareToChange,
	handleIsReleaseToChange,
	isShowShareColumn = true,
	isShowReleaseColumn = true,
}) => {
	const classes = useStyles();
	return (
		<div className="shard_model mt-3">
			<Paper className={classes.paper}>
				<TableContainer className={classes.container}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						aria-label="enhanced table"
						stickyHeader
					>
						<EnhancedTableHead
							isShowShareColumn={isShowShareColumn}
							isShowReleaseColumn={isShowReleaseColumn}
						/>
						<TableBody>
							{contactsList
								.map((row, index) => {
									return (
										<TableRow
											hover
											tabIndex={-1}
											key={index}
											onClick={
												isShowShareColumn ?
													() => handleIsShareToChange(row.contactid.toString(), row.editsharedwith ? 0 : 1) :
													() => handleIsReleaseToChange(row.contactid.toString(), row.editreleaseto ? 0 : 1)}
											style={{cursor: 'pointer'}}
										>
											{isShowReleaseColumn ? (
												<TableCell align="left">
													<Checkbox
														id={row.contactid.toString()}
														checked={!!row.editreleaseto}
														color="primary"
														inputProps={{
															'aria-label': 'secondary checkbox',
														}}
													/>
												</TableCell>
											) : null}
											{isShowShareColumn ? (
												<TableCell align="left">
													<Checkbox
														id={row.contactid.toString()}
														checked={!!row.editsharedwith}
														color="primary"
														inputProps={{
															'aria-label': 'secondary checkbox',
														}}
													/>
												</TableCell>
											) : null}
											<TableCell
												component="th"
												scope="row"
												align="left"
												id={index}
											>
												{row.fname}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												align="left"
												id={index}
											>
												{row.lname}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												align="left"
												id={index}
											>
												{row.isICE === 1 ? 'Yes' : 'No'}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												align="left"
												id={index}
											>
												{row.phonenumber || '--'}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												align="left"
												id={index}
											>
												{row.email || '--'}
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
};

export default SharedWithReleasedToGridContainer;
