import React from 'react';
import { Button } from 'react-bootstrap';
import FileAttachedModal from './FileAttachedModal';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStylesBootstrap = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
	},
}));

const Section4 = ({
	onChangeFiles,
	existingFiles,
	removedExistingFiles,
	files,
	removedFiles,
	itemName,
	saveMedia,
	rowid,
}) => {
	const [fileAttachedModalShow, setFileAttachedModalShow] = React.useState(
		false
	);
	const classes = useStylesBootstrap();

	const getFileNames = () => {
		let getContactsList = [];
		existingFiles.map((contact) => getContactsList.push(contact.filename));
		files.map((contact) => getContactsList.push(contact.name));
		return getContactsList;
	};

	const fileNamesList =
		(getFileNames() || []).reduce(
			(nameList, lotObj) => nameList + ',' + lotObj,
			''
		) || '';

	return (
		<div className="Section-Container">
			<div className="d-flex align-items-center justify-content-evenly p-2 pl-5">
				<div className="d-flex align-items-center justify-content-evenly">
					File Attached:{' '}
					<div className="Count-Container ml-2">
						<b>{existingFiles.length + files.length}</b>
					</div>
				</div>
				<Tooltip
					arrow
					classes={classes}
					placement="top"
					title={
						fileNamesList ? fileNamesList.slice(1) : 'No File Attached Exist'
					}
				>
					<Button
						variant="secondary"
						className="btn-sm-height ml-2"
						onClick={() => {
							setFileAttachedModalShow(true);
						}}
						disabled={rowid ? false : true}
					>
						Uploads
					</Button>
				</Tooltip>
			</div>
			{fileAttachedModalShow ? (
				<FileAttachedModal
					fileAttachedModalShow={fileAttachedModalShow}
					setFileAttachedModalShow={setFileAttachedModalShow}
					itemName={itemName}
					onChangeFiles={onChangeFiles}
					existingFiles={existingFiles}
					removedExistingFiles={removedExistingFiles}
					files={files}
					removedFiles={removedFiles}
					saveMedia={saveMedia}
					rowid={rowid}
				/>
			) : null}
		</div>
	);
};

export default Section4;
