import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import './myprofileform.css';
import { Form, FormControl, FormGroup, Button, Alert, Table, Row, Col, Container } from 'react-bootstrap';

const Myprofileform = ({ userListData, profileForm }) => {
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email1, setEmail1] = useState('')
    const [phone1, setPhone1] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')


    return (
        <section className={profileForm ? "my-profile-form form-data-userHistory" : "form-data-userHistory"}>
            <h4 className=" mb-4 custom-text-color">User Details </h4>
            <Form>
                <Form.Group controlId="exampleForm.ControlInput2" className="float-none clearfix" >
                    <label>First Name:</label>
                    <Form.Control type="text" value={fname} placeholder="" onChange={(e) => setFname(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Last Name:</label>
                    <Form.Control type="text" value={lname} placeholder="" onChange={(e) => setLname(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Address:</label>
                    <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="" required />
                </Form.Group>
                <Form.Row>
                    <Col>
                        <label>City:</label>
                        <Form.Control type="text" placeholder="" value={city} onChange={(e) => setCity(e.target.value)} required />
                    </Col>
                    <Col>
                        <label>State:</label>
                        <Form.Control type="text" placeholder="" required />
                    </Col>
                </Form.Row>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Postal Code:</label>
                    <Form.Control type="text" className="postalCode" placeholder="" required />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Phone #1:</label>
                    <Form.Control type="phone" placeholder="" value={phone1} onChange={(e) => setPhone1(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Phone #2:</label>
                    <Form.Control type="phone" placeholder="" required />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Email #1:</label>
                    <Form.Control type="email" placeholder="" value={email1} onChange={(e) => setEmail1(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>Email #2:</label>
                    <Form.Control type="email" placeholder="" required />
                </Form.Group>
                <Form.Group >
                    {/* <label>Upload Profile:</label> <br/>
                    <input type="file" id="myFile" name="filename" /> */}
                    <div>
                        <label>Upload Profile:</label>
                    </div>
                    <input type="file" id="myFile" name="filename" /> 
                    {/* <label for="file" >choose a file</label> */}
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1 ">
                    <Form.Label >Note:</Form.Label>
                    <Form.Control as="textarea" className="textArea" rows={6} required />
                </Form.Group>
                <Button variant="primary" type="submit" className="btn-lg btn-block mt-4 custom-btn">
                    Submit
                </Button>
            </Form>
        </section >
    )
}
export default Myprofileform;