import React, {useEffect, useState} from 'react';
import {Col, Row, Container, Button} from 'react-bootstrap';
import axios from 'axios';
import Header from '../../components/NewHeader/Header';
import {useHistory} from "react-router-dom";
import Back from '../../assets/images/Back_white.png';
import pattern from "../../assets/images/LandingPage/patterns/pattern.jpg";
import pattern_1 from "../../assets/images/LandingPage/patterns/pattern_1.jpg";
import '../NewLandingPage/NewLandingPage.css'
import './privacypolicy.css'

const Privacypolicy = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleBack = () => {
        history.goBack();
    }

    return (
        <>
            <div className="privacy-policy-section position-relative">
                <header>
                    <div className="header-section">
                        <h1 className="text-center">PRIVACY POLICY</h1>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <div className="background-pattern-img background-loop"
                             style={{backgroundImage: `url(${pattern})`}}/>
                        <div className="background-pattern-gradient"/>
                        <div className="background-pattern-bottom">
                            <div className="image"
                                 style={{backgroundImage: `url(${pattern_1})`}}/>
                        </div>
                    </div>
                </header>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Button onClick={handleBack} className="btn custom-link-btn btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Button>
                    </Col>
                </Row>
                <Container className="bg-white mt-4 p-4">
                    <Row>
                        <div className="work_container">
                            <div>
                                <strong className={'text-center d-block privacy_policy_title'}>KEYS TO MY WISHES PRIVACY NOTICE</strong>
                                <p>&nbsp;</p>
                                <p className={'privacy_policy_title'}><strong>Last updated November 10, 2021</strong></p>
                                <p>This <b>Privacy Notice</b> applies to all information (including personal
                                    information)
                                    collected by Keys To My Wishes (“KTMW,” “we,” “our,” or “us”) through your use of
                                    our website, <a
                                        href="https://www.keystomywishes.com/">www.keystomywishes.com</a> (the <b>“Site”</b>).
                                    You may provide information to us
                                    offline, through any other sites, applications, software, features, events, surveys
                                    or studies, or content offered by us from time to time that link or otherwise refer
                                    to this Privacy Notice (collectively, including the Site, the "Services"). For
                                    purposes of this Privacy Notice, <b>"personal information"</b> means information
                                    that
                                    relates to, describes, is capable of being associated with, or could reasonably be
                                    linked, directly or indirectly, with a particular individual or household. We are
                                    committed to respecting and protecting the privacy of our users. </p>
                                <p>Please read this Privacy Notice carefully before you start to use the Services. <b>BY
                                    ACCESSING AND/OR USING THE SERVICES, YOU EXPRESSLY CONSENT AND AGREE TO BE BOUND AND
                                    ABIDE BY THIS PRIVACY NOTICE AND OUR TERMS OF USE INCORPORATED HEREIN BY REFERENCE,
                                    AND TO COMPLY WITH ALL APPLICABLE LAWS, RULES, AND REGULATIONS (COLLECTIVELY,
                                    "APPLICABLE LAWS"). </b> If you do not want to agree to this Privacy Notice and the
                                    Terms
                                    of Use, you must not access or use the Services. </p>
                                <p>This Privacy Notice applies to our collection, use, and retention (“processing”) of
                                    Personal Information, which identifies, or can be used by us or our behalf to
                                    identify an individual, household or device (“Personal Information”).</p>
                                <p>This Privacy Notice describes the types of information we collect on the Site, how we
                                    may use the information, with whom we may share it and the choices available to you
                                    regarding our use of the information. </p>
                                <p><b><u>DEFINITIONS</u></b></p>
                                <p>The word "<b>User</b>(s)” means any Person (also referred to as "<b>you</b>" or
                                    "<b>your</b>") visiting,
                                    accessing, or using the Services. The word "<b>Person</b>(s)" means any natural
                                    person,
                                    sole proprietorship, corporation, partnership, or other entity whatsoever. The word
                                    "<b>or</b>" includes the word "<b>and</b>." The words "includes" or
                                    "<b>including</b>" means "<b>includes</b>,
                                    but is not limited to" or "including, but not limited to."</p>
                                <p className="">
                                    <strong>Table of Contents</strong>
                                    <b>
                                        <ol className="pl-3 table_content">
                                            <li><a href="#question_1">WHAT TYPES OF INFORMATION DOES KEYS TO MY WISHES COLLECT AND HOW DO WE COLLECT IT?</a></li>
                                            <li><a href="#question_2">HOW DOES KEYS TO MY WISHES USE YOUR INFORMATION?</a></li>
                                            <li><a href="#question_4">WHO DOES KEYS TO MY WISHES SHARE YOUR INFORMATION WITH?</a></li>
                                            <li><a href="#question_5">HOW CAN I CORRECT OR UPDATE MY PERSONAL INFORMATION?</a></li>
                                            <li><a href="#question_6">HOW DOES KEYS TO MY WISHES PROTECT MY PERSONAL INFORMATION?</a></li>
                                            <li><a href="#question_7">HOW LONG DOES KEYS TO MY WISHES RETAIN YOUR INFORMATION?</a></li>
                                            <li><a href="#question_8">CHILDREN’S PRIVACY</a></li>
                                            <li><a href="#question_9">CHANGES TO OUR PRIVACY NOTICE AND COMMUNICATING CHANGES</a></li>
                                            <li><a href="#question_10">LEGAL DISCLAIMER</a></li>
                                            <li><a href="#question_11">THIRD-PARTY SERVICES</a></li>
                                            <li><a href="#question_12">SEVERABILITY</a></li>
                                            <li><a href="#question_13">CONTACT INFORMATION</a></li>
                                            <li><a href="#question_14">TRACKING TECHNOLOGY</a></li>
                                        </ol>
                                    </b>
                                </p>
                                <p id="question_1">
                                    <b>1. WHAT TYPES OF INFORMATION DOES KEYS TO MY WISHES COLLECT AND HOW DO WE COLLECT
                                        IT?</b> <br/><br/><u>From Users</u> <br/><br/><b>Identifiers:</b> Examples might
                                    include your name, email address, phone number,
                                    credit
                                    card information, mailing address, housing status, date of birth, level of
                                    education, or IP address. <br/><br/><b>Sensitive Personal Information:</b> Racial or
                                    National Origin, Personal and Family
                                    Medical History, Mental Illness History, Financial Information, or Insurance
                                    Information <br/><br/><b>Classification Characteristics:</b> Examples might include
                                    your age or sex; <br/><br/><b>Professional or Employment-Related
                                    Information:</b> Examples might include your
                                    employer, job position, salary, military status, etc. <br/><br/><u>INFORMATION ABOUT
                                    YOUR USE OF THE SERVICES</u> <br/><br/>The Services use cookies, web beacons,
                                    pixels, and other tracking technologies to
                                    collect information about your use of the Services, including without limitation,
                                    your browser type and version, IP address, operating system and platform, when you
                                    open email that we send you, and other similar information. This information helps
                                    us to provide the Services, including, without limitation, to improve and enhance
                                    the user experience and help us understand the behavior of our users. Cookies are
                                    messages placed on your computer or mobile device when you visit a website or page.
                                    For example, the messages store information so that our Services may immediately
                                    recognize you each time you visit. This permits us to personalize our Services for
                                    each user, allow access to the secure parts of our Services that individual users
                                    have registered for, and provide users with up-to-date information about their own
                                    account. <br/><br/>We may also use cookies to deliver ads, store visitor
                                    preferences, record session
                                    information (e.g., when a payment is made), record user-specific information on what
                                    pages users access or visit, track and record past activity at the Site (or on other
                                    Services) in order to provide better service when visitors return to our Site (or
                                    other Services), customize web page content based on visitors' browser type, or
                                    other information that the visitor sends. If you choose to disable cookies, you may
                                    find that certain sections and functionalities of the Services will not work
                                    properly or at all. <br/><br/> Web beacons (also called web bugs and clear GIFs) are
                                    used in combination with
                                    cookies to help us understand the behavior of our users. A web beacon is typically a
                                    transparent graphic image that is placed on our site or in an email. The use of a
                                    web beacon allows KTMW to record the simple actions of a user opening a page that
                                    contains a beacon. When a user's browser requests information from a website in this
                                    way, certain simple information can also be gathered, such as: the IP address of
                                    your computer, the time the material was viewed, the type of browser that retrieved
                                    the image, and the existence of cookies previously set by that server. Web beacons
                                    provide a convenient way of gathering statistics and managing cookies. <br/><br/>KTMW
                                    and other third parties may collect and store information (including personal
                                    information) locally on your device using mechanisms such as browser web storage
                                    (including HTML 5) and application data caches. If you want to adjust your settings
                                    or delete all local storage mechanisms, go to your browser's settings to do so. If
                                    information is stored in local storage in addition to a cookie, you will need to
                                    remove both the local storage and the cookie in order to fully delete the
                                    information. <br/><br/>KTMW may also use local shared objects, sometimes known as
                                    Flash cookies, to store
                                    your preferences or display content based upon what you view on our Services to
                                    personalize your visit. Advertisers and other third-party services also may use
                                    Flash cookies to collect and/or store information. Flash cookies are different from
                                    browser cookies because of the amount of, type of, and how data is stored. Cookie
                                    management tools provided by your browser will not remove Flash cookies. To learn
                                    how to manage flash cookies please visit the following website for more details: <a
                                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"
                                    target="_blank">
                                    http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html.
                                </a> <br/><br/>KTMW and other third parties may collect certain device information
                                    from you when you
                                    use the Services from a mobile device such as the type of device you use, unique
                                    device ID, geo-location (including your precise location), wireless mobile
                                    subscriber ISDN number, IP address, operating system, browser type, and information
                                    about your use of the Services. You may also manage the information transmitted from
                                    your device from your device's settings, including opting-out of cross-device
                                    tracking. However, if you prevent tracking on your device, it will be more
                                    difficult, and may be impossible, for you to use the Services or portions of the
                                    Services. <br/><br/>We may merge the data we collect from your activity on the
                                    Services with data
                                    available from third parties, including, without limitation, advertisers. We have
                                    implemented Google Analytics. You can use the Google Analytics Opt-Out Browser
                                    Add-on available at: <a href="https://tools.google.com/dlpage/gaoptout/"
                                                            target="_blank">
                                    https://tools.google.com/dlpage/gaoptout/</a> to disable tracking
                                    by Google Analytics <br/><br/><b><i>Inferences</i></b><br/><br/>KTMW may make
                                    inferences drawn from other personal information listed above, to
                                    create a profile reflecting your preferences, characteristics, psychological trends,
                                    predispositions, behavior, attitudes, intelligence, abilities, and/or aptitudes.</p>
                                <p id="question_2">
                                    <b>2. HOW DOES KEYS TO MY WISHES USE YOUR INFORMATION?</b> <br/><br/>
                                    We may use your Personal Information, Demographic Information, or other information
                                    that we collect about you: (1) to operate the Service, provide you with information,
                                    including sending you promotional materials, or services or processing transactions
                                    with you; (2) to improve the Services or create new service offerings, to customize
                                    your experience on any of the Services, or to serve you specific content that is
                                    most relevant to you; (3) to contact you with regard to your use of any of the
                                    Services and, in our discretion, changes to the any of the Services and/or any of
                                    the Services’ terms or policies; (4) for internal business purposes; and (5) for
                                    purposes disclosed at the time you provide your information or as otherwise set
                                    forth in this Privacy Notice or as otherwise required or permitted by applicable
                                    law. Without limiting the generality of the forgoing, we may use your information
                                    to:
                                    <br/><br/>
                                    Customize your visits to the Service, including (i) specifically targeting relevant
                                    advertisements, offers or content to you; (ii) understanding the usefulness of the
                                    advertisements or content that have been delivered to you; (iii) using your location
                                    information to display offers or verify certain actions; Communicate about, and
                                    administer your participation in, special events, programs, surveys, and other
                                    offers or promotions; Respond to and communicate with you about your requests,
                                    questions and comments; Operate, evaluate and improve our operations (including
                                    developing new services; managing our communications; identifying users of our
                                    Service; determining the effectiveness of our marketing and advertising; analyzing
                                    and enhancing our services and Service); Administer the Service, and diagnose
                                    technical and service problems;
                                    Perform data analyses and other processing (including market and consumer research,
                                    trend analysis, financial analysis, de-identification, encryption and tokenization
                                    of Personal Information); Protect against, identify and prevent fraud and other
                                    criminal activity, claims and other liabilities and harms; and comply with and
                                    enforce applicable legal requirements, relevant industry standards and our policies.
                                    <br/><br/>
                                    To Aggregate and Anonymize Data <br/><br/>
                                    KTMW may aggregate and/or anonymize any information collected through the Services
                                    for various purposes. Once aggregated or anonymized, the information will no longer
                                    be considered "personal information" because it will no longer be able to identify
                                    you and/or any of your specific devices used to access the Services. KTMW may use
                                    such information for any purpose (e.g., research and development purposes),
                                    including sharing such information with third parties.
                                    To Display and Target Advertisements <br/><br/>
                                    Your information is used to target ads and content on the Services and on
                                    third-party services. Some advertisements and other content may be delivered by
                                    third party advertising networks and advertisers that may use cookies and similar
                                    technologies and identifiers to collect and track information such as demographic
                                    information, inferred interests, aggregated information, and activity to assist them
                                    in delivering advertising that is more relevant to your interests. We may also
                                    provide certain personal information to service providers who will match your
                                    information to information provided by other third parties (e.g., cookies) in order
                                    to allow us to provide more tailored advertisements.
                                </p>
                                <p id="question_3">
                                    <b>3. FOR WHAT PURPOSES DOES KEYS TO MY WISHES USE OR SHARE YOUR PERSONAL
                                        INFORMATION?</b><br/><br/> Operational Purposes <br/><br/> Processing your
                                    personal information will likely be necessary to facilitate the
                                    day-to-day operation of KTMW. This may include: managing our relationship with you,
                                    our employees, other users/clients, vendors, Business Partners, and/or others;
                                    sharing intelligence with internal stakeholders; implementing training procedures;
                                    planning and allocating resources and budgets; performing data modelling;
                                    facilitating internal reporting; analyzing growth strategies; aggregating analytics;
                                    and/or processing personal information to create anonymized data.
                                    <br/><br/>Logistical Purposes <br/><br/>
                                    Processing your personal information will likely be necessary to enable KTMW's
                                    business operations to run more efficiently, e.g., establishing how to allocate
                                    resources or to predict future demand.
                                    <br/><br/>Research and Development Purposes <br/><br/>
                                    Processing your personal information will likely be necessary for us to deliver
                                    and/or improve our services. <br/><br/>
                                    Market Intelligence and Analytical Purposes <br/><br/>
                                    KTMW regularly needs to conduct market intelligence so that we can better promote
                                    our services by creating a better understanding of our users' preferences.
                                    <br/> <br/>Common metrics for evaluation could include monitoring pages and links
                                    accessed, ad performance and conversion tracking, number of posts, number of page
                                    views, patterns of navigation, time on a page, devices used, user reviews, where
                                    users are coming from, hardware used, operating system version, advertising
                                    identifiers, unique application identifiers, unique device identifiers, browser
                                    types, languages, wireless or mobile network information, etc. These metrics could
                                    be used to: personalize services and communications; determine which users should
                                    receive specialized communications based on how they use our service; create
                                    aggregate trend reports; determine the most effective advertising channels and
                                    messaging; and/or measure the audience for a certain communication.
                                    <br/><br/>
                                    Personalization Purposes <br/><br/>
                                    We may process personal information in order to enhance and personalize the
                                    experience we offer our current and/or prospective users of our services. <br/><br/>
                                    Monitoring Purposes <br/><br/>
                                    KTMW may need to process your personal information in order to identify recurring
                                    problems and/or analyze the patterns of behavior of users in order to monitor the
                                    performance of, and users' behavior on, our Services.
                                    <br/><br/>Direct Communication Purposes <br/><br/>
                                    Processing your personal information may be necessary for direct communication
                                    purposes to occasionally update users on the Services, including occasional
                                    communications regarding updates to our activities, services, and/or events.
                                    <br/><br/>Marketing Services <br/><br/>
                                    KTMW may need to process personal information in the context of marketing our
                                    services. <br/><br/>
                                    Fraud Detection and Prevention Purposes <br/><br/>
                                    Processing your personal information may be necessary for KTMW to help detect and
                                    prevent fraud, e.g., verifying that the registered address of the cardholder for a
                                    particular credit or debit card is the same as the cardholder's normal place of
                                    residence or work.
                                    <br/><br/>Updating User Details and Preferences Purposes <br/><br/>
                                    Processing your personal information is necessary to verify the accuracy of your
                                    user data and to create a better understanding of our past, present, and/or
                                    prospective users.
                                    <br/><br/>Network and Information Security Purposes <br/><br/>
                                    Processing your personal information may be necessary for the purposes of ensuring
                                    our network and information security, e.g., monitoring users' access to our network
                                    for the purpose of preventing cyber-attacks, inappropriate use of data, corporate
                                    espionage, hacking, system breaches, etc. This could include preventing unauthorized
                                    access to electronic communications networks and malicious code distribution and
                                    stopping "denial of service" attacks and damage to computer and electronic
                                    communication systems.
                                    <br/><br/> Business Continuity and Disaster Planning Purposes <br/><br/>
                                    KTMW may process your personal information because it is necessary to allow for the
                                    backup and protection of your information (e.g., utilizing cloud-based services to
                                    archive/protect data) in order to ensure that such information is not improperly
                                    lost or modified. Such processing is also necessary to archive/protect data in
                                    accordance with legal, regulatory, organizational, and/or contractual obligations.
                                    <br/><br/>Compliance with Laws and Regulations Purposes <br/><br/>
                                    KTMW is subject to binding legal or regulatory obligations and may need to process
                                    your personal information in order to comply with such laws or regulations. Examples
                                    could include: complying with reporting obligations; complying with screening
                                    obligations; responding to law enforcement requests; and/or responding to
                                    judicial/regulatory agency requests.
                                    <br/><br/> Reporting Potential Threats to Public Security and Safety
                                    Purposes <br/><br/>
                                    KTMW may need to use personal information to report possible criminal acts or
                                    threats to public security/safety that we identify as part of our processing
                                    activities to a competent authority.
                                    <br/><br/> To Fulfill other Purposes that you have consented to <br/><br/>
                                    Your information will also be used to fulfill any other purpose for which you
                                    provide the information or otherwise provide your consent for KTMW and/or other
                                    third parties to use such information – assuming such use is consistent with
                                    applicable laws and regulations. </p>
                                <p id="question_4">
                                    <b>4. WHO DOES KEYS TO MY WISHES SHARE YOUR INFORMATION WITH?</b><br/><br/>
                                    KTMW shares personal information as stated in this Privacy Notice and in accordance
                                    with Applicable Laws. KTMW does not rent or sell any user’s information. We do not
                                    share your personal information with others except as indicated in this Privacy
                                    Notice or when we inform you and give you an opportunity to "opt-out" of having your
                                    personal information shared. Below are categories of third-parties that we may share
                                    your information with:
                                    <br/><br/> SERVICE PROVIDERS <br/><br/>
                                    We may share your personal information with service providers who perform services
                                    on KTMW's behalf. Your information is generally shared with service providers so
                                    that KTMW can streamline and enhance users' experiences with the Services. Service
                                    providers could include parties that perform research services, provide operational
                                    support, provide marketing platforms, handle payments, create content for the
                                    Services, etc.
                                    <br/><br/> For example, your payment information may be shared with third parties
                                    who perform tasks required to process a payment.
                                    <br/><br/> GOVERNMENTAL ENTITIES <br/><br/>
                                    We may share your personal information with competent governmental authorities when
                                    required by law and/or as necessary to protect KTMW, its personnel, other users,
                                    and/or the Services.</p>
                                <p id="question_5">
                                    <b>5. HOW CAN I CORRECT OR UPDATE MY PERSONAL INFORMATION?</b><br/><br/>
                                    If your personal information changes (such as your mailing address), you can log
                                    into your account and correct it yourself. If you no longer desire to use our
                                    Services and wish to have your personal information removed from our system, you can
                                    log into the system and delete your account or contact us by email at
                                    support@keystomywishes.com.
                                    <br/><br/>
                                    With the exception of the mandatory administrative emails mentioned above
                                    (transactional and business relationship messages), recipients have an opportunity
                                    to update their email communication preferences.
                                    <br/><br/> <b><i>To Unsubscribe:</i></b> Users may unsubscribe from specific email
                                    categories by
                                    notifying us at support@keystomywishes.com.

                                </p>
                                <p id="question_6">
                                    <b>6. HOW DOES KEYS TO MY WISHES PROTECT MY PERSONAL INFORMATION?</b><br/><br/>
                                    SECURITY MEASURES & SAFEGUARDS <br/><br/>
                                    The security of our users' information is very important to us. When users submit
                                    personal information via the Services, that information is protected by generally
                                    accepted industry standards, and in accordance with applicable data privacy laws,
                                    including both online and offline security measures and safeguards. The KEYS TO MY
                                    WISHES uses a combination of administrative, technical, personnel, and physical
                                    measures to safeguard personal information in its possession against loss, theft,
                                    and unauthorized use, disclosure, or modification. However, no one can guarantee the
                                    complete safety of your information.
                                    <br/><br/><b>IF YOU HAVE REASON TO BELIEVE THAT YOUR INTERACTION WITH US IS NO
                                    LONGER SECURE, PLEASE IMMEDIATELY NOTIFY US OF THE PROBLEM.</b> However, please
                                    remember that no method of electronic storage or transmission over the internet is
                                    one hundred percent (100%) secure and, therefore, while we strive to use
                                    commercially reasonable means to protect your personal information, and will comply
                                    with applicable data privacy laws, we cannot guarantee the absolute security of such
                                    information. <br/><br/> PHISHING <br/><br/>Phishing is a term used to describe a
                                    type of Internet scam in which
                                    inauthentic communications that appear to be coming from a legitimate source are
                                    sent in an attempt to trick you into providing confidential information. These
                                    communications usually invoke a sense of urgency and often imply that your account
                                    is about to be closed unless immediate action is taken. As an example, phishing
                                    emails will often ask you to click on a link that leads you to a webpage that
                                    simulates that of the legitimate source, but it is really a page specially designed
                                    to get you to input your confidential information such as your account number,
                                    password, social security number, or credit card number. The thieves then use that
                                    information to commit fraud and identity theft.
                                    <br/><br/> If you ever question the legitimacy of an email or other communication
                                    from KTMW, please email us at support@keystomywishes.com.
                                    <br/><br/> If you believe you have been the victim of a scam, you can file a
                                    complaint with the Federal Trade Commission at: <a
                                    href="https://www.consumer.gov/idtheft/" target="_blank">
                                    www.consumer.gov/idtheft/</a>. </p>
                                <p id="question_7">
                                    <b>7. HOW LONG DOES KEYS TO MY WISHES RETAIN YOUR INFORMATION?</b><br/><br/>
                                    We will retain your information for the period necessary to fulfill the purposes for
                                    their collection as outlined in this Privacy Notice, unless a longer retention
                                    period is either mandated by the KTMW's contractual requirements, internal policies,
                                    or otherwise required or allowed by law. It is not always possible to completely
                                    remove or delete all of your information due to technical constraints, contractual,
                                    financial, or legal requirements. Where it is not possible to remove or delete all
                                    information, KTMW shall continue to safeguard your information as detailed herein.
                                </p>
                                <p id="question_8">
                                    <b>8. CHILDREN’S PRIVACY</b> <br/><br/>
                                    We understand the importance of protecting children’s privacy. We are a general
                                    audience service and do not use the Service to knowingly collect personal
                                    information from children under the age of thirteen (13) without verified parental
                                    or guardian consent.
                                    <br/><br/>KTMW complies with the Children’s Online Privacy Protection Act (COPPA),
                                    and if you believe we have information regarding your child who is under the age of
                                    13 that you have not authorized, you may email us at support@keystomywishes.com to
                                    request that we delete it.
                                    <br/><br/>If you are a child under thirteen (13) years of age, you are not permitted
                                    to use the Service, without parental or guardian consent, and should not send any
                                    information about yourself to us through this Service.

                                </p>
                                <p id="question_9">
                                    <b>9. CHANGES TO OUR PRIVACY NOTICE AND COMMUNICATING CHANGES</b><br/><br/>
                                    This Privacy Notice is subject to change at any time. All changes will be posted on
                                    the Site. If the changes are significant, we will notify you by prominently posting
                                    a notice on our Services (e.g., through a pop-up on our homepage). Changes will
                                    become effective as of the earlier of the date we post the revised privacy notice,
                                    send an email detailing any changes, or post such a notice. While we will do our
                                    best to inform you of any changes, we encourage you to review our Privacy Notice
                                    periodically to ensure that you remain informed of changes that may have occurred,
                                    including changes that relate to what information we collect, how we use it, and how
                                    we share it. <b>BY CONTINUING TO USE THE SERVICES AFTER A CHANGE HAS TAKEN PLACE,
                                    YOU ARE AGREEING THAT YOU HAVE READ AND HAVE AGREED TO THE MOST CURRENT VERSION OF
                                    THIS PRIVACY NOTICE AND EXPRESSLY CONSENT TO THE USE OF YOUR PERSONAL INFORMATION AS
                                    DETAILED HEREIN/THEREIN.</b>
                                </p>
                                <p id="question_10">
                                    <b>10. LEGAL DISCLAIMER</b><br/><br/>
                                    Though we make every effort to preserve user privacy, we may need to disclose your
                                    personal information to others. We may share your information to provide information
                                    to our representatives, service providers, advisors, corporate affiliates, and
                                    business partners, and in the event of a change in control of KTMW and/or its
                                    affiliates, your information may be shared with the organization obtaining ownership
                                    and control. Your information may be used to protect the rights, including legal
                                    rights, and safety of ourselves and others. Furthermore, disclosure of personal
                                    information may be required by law where we have a good-faith belief that such
                                    action is necessary to comply with a current judicial proceeding, a court order, or
                                    legal process. In such cases, we may raise or waive any legal objection or right
                                    available to us.
                                </p>
                                <p id="question_11">
                                    <b>11. THIRD-PARTY SERVICES</b><br/><br/>
                                    Other websites and services to which we link, through which you access the Services,
                                    or share information from the Services and which are not owned or controlled by us
                                    are not subject to the terms of this Privacy Notice. <b>WE ARE NOT RESPONSIBLE FOR
                                    THE PRIVACY PRACTICES OR THE CONTENT OF ANY OTHER WEB SITES OR SERVICES AND ANY
                                    VISIT YOU MAKE TO THOSE OTHER SERVICES IS AT YOUR OWN RISK.</b> Third parties that
                                    support the Services, including by providing advertising or other services, such as
                                    analytics, may use tracking technologies to collect information about your use of
                                    the Services or other third-party services. We do not control these third-party
                                    technologies or services and their use is governed by the privacy policies of third
                                    parties using such technologies. Such third party's use of any information you share
                                    is governed by the third party's privacy notice. </p>
                                <p id="question_12">
                                    <b>12. SEVERABILITY</b><br/><br/>
                                    If any provision of this Privacy Notice (including any attachment) is held to be
                                    invalid, illegal, or unenforceable by a court or regulatory authority of competent
                                    jurisdiction, the rest of this Privacy Notice shall remain valid and enforceable to
                                    the fullest extent permitted under Applicable Laws. To the extent that the main body
                                    of this Privacy Notice conflicts with the terms of addenda referenced herein, the
                                    addenda will control </p>
                                <p id="question_13">
                                    <b>13. CONTACT INFORMATION</b><br/><br/>
                                    If you have any questions about this Privacy Notice or our privacy practices, please
                                    send us an email to support@keystomywishes.com or by phone at (818) 642–1540. You
                                    may also write us at <u>21550 W. Oxnard St. Suite 300 Woodland Hills, CA 91367.</u>
                                </p>
                                <p id="question_14">
                                    <b>14. TRACKING TECHNOLOGY</b><br/><br/>
                                    There is currently no industry or legal standard for what a company should do when a
                                    Do Not Track signal is detected. Because of this, we currently do not respond to Do
                                    Not Track signals. <br/><br/>Regular cookies may generally be disabled or removed by
                                    tools available as part of most commercial browsers, and in some instances blocked
                                    in the future by selecting certain settings. Browsers offer different
                                    functionalities and options so you may need to set them separately. Also, tools from
                                    commercial browsers may not be effective with regard to Flash cookies (also known as
                                    locally shared objects), HTML5 cookies, or other Tracking Technologies. For
                                    information on disabling Flash cookies, go to Adobe’s website <a
                                    href="http://helpx.adobe.com/flash-player/kb/disable-third-party-local-shared.html"
                                    target="_blank">
                                    <b>HTTP://HELPX.ADOBE.COM/FLASH-PLAYER/KB/DISABLE-THIRD-PARTY-LOCAL-SHARED.HTML</b></a>
                                    <br/><br/> Please be aware that if you disable or remove these technologies, some
                                    parts of the Service may not work and that when you revisit the Service your ability
                                    to limit browser-based Tracking Technologies is subject to your browser settings and
                                    limitations. <br/><br/>One way to potentially identify cookies on our web site is to
                                    add the free Ghostery plug-in to your browser (<a href="https://www.ghostery.com/"
                                                                                      target="_blank"><b>WWW.GHOSTERY.COMOPENS
                                    IN A NEW WINDOW</b></a>), which according to Ghostery will display for you
                                    traditional, browser-based cookies associated with the web sites (but not mobile
                                    apps) you visit and privacy and opt-out policies and options of the parties
                                    operating those cookies. KTMW is not responsible for the completeness or accuracy of
                                    this tool or third-party choice notices or mechanisms. <br/><br/> This Privacy
                                    Notice is also part of our Terms of Use, which govern your use of our website. </p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Privacypolicy;
