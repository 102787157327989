import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import {
	Checkbox,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const headCells = [
	{
		id: 'fname',
		numeric: false,
		disablePadding: true,
		label: 'Contacts Shared With',
		align: 'left',
	},
	{
		id: 'sendText',
		numeric: true,
		disablePadding: false,
		label: 'Send Text',
		align: 'center',
	},
	{
		id: 'sendEmail',
		numeric: true,
		disablePadding: false,
		label: 'Send Email',
		align: 'center',
	},
];

function EnhancedTableHead() {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => (
					<TableCell key={index} align={headCell.align}>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	container: {
		maxHeight: 500,
		overflowX: 'hidden',
	},
	customCell: {
		padding: '8px 16px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	customHeaderCell: {
		fontSize: '16px',
	},
}));

const SendTextEmailModal = ({
	sendTextEmailModalShow,
	closeSendTextEmailModal,
	itemName,
	sharedContacts,
	handleSendTextChange,
	handleSendEmailChange,
	sendTextEmail,
	sendMessage,
	setSendMessage,
}) => {
	const classes = useStyles();
	return (
		<>
			<Modal
				size="lg"
				show={sendTextEmailModalShow}
				onHide={() => {
					closeSendTextEmailModal();
				}}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="ShareRelease-Modal-Container"
			>
				<Modal.Header closeButton className="ShareRelease-Modal-Header">
					<Modal.Title>Send Text/Email Message to Shared Contacts</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-left">
						<div className="pt-3 pb-3">
							This screen allows you to inform your contacts you made a change
						</div>
						{itemName ? (
							<div className="ShareRelease-Container">{itemName}</div>
						) : null}
					</div>
					<div>
						{(sharedContacts || []).length === 0 ? (
							<div className="text-center pt-3 pb-3">
								This item is not shared with any of your contacts.
							</div>
						) : (
							<>
								<Form.Group
									controlId="exampleForm.ControlInput2-SendMessage"
									className="d-flex flex-column"
								>
									<div className="w-100 d-flex align-items-center justify-content-between pb-2 align-items-start">
										<label>
											<b>The following message will be sent for this item?</b>
										</label>
									</div>
									<Form.Control
										as="textarea"
										placeholder=""
										rows={3}
										required
										value={sendMessage}
										onChange={(e) => setSendMessage(e.target.value)}
									/>
									<div className="w-100 d-flex align-items-center justify-content-end pb-2">
										<div>max 160 characters</div>
									</div>
								</Form.Group>

								<Paper className={classes.paper}>
									<TableContainer className={classes.container}>
										<Table
											className={classes.table}
											aria-labelledby="tableTitle"
											aria-label="enhanced table"
											stickyHeader
										>
											<EnhancedTableHead />
											<TableBody>
												{sharedContacts.map((row, index) => {
													return (
														<TableRow hover tabIndex={-1} key={index}>
															<TableCell
																component="th"
																scope="row"
																align="left"
																id={index}
															>
																{row.fname + ' ' + row.lname}
															</TableCell>
															<TableCell align="center">
																<Checkbox
																	id={row.contactid.toString()}
																	checked={!!row.isSendText}
																	color="primary"
																	inputProps={{
																		'aria-label': 'secondary checkbox',
																	}}
																	onChange={handleSendTextChange}
																/>
															</TableCell>
															<TableCell align="center">
																<Checkbox
																	id={row.contactid.toString()}
																	checked={!!row.isSendEmail}
																	onChange={handleSendEmailChange}
																	color="primary"
																	inputProps={{
																		'aria-label': 'secondary checkbox',
																	}}
																/>
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button
						variant="primary"
						className="custom-btn"
						onClick={() => sendTextEmail()}
					>
						SEND
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default SendTextEmailModal;
