import React, {useEffect} from "react";
import "./landing.css";
import TODO from "../../assets/images/Todo.svg";
import {Row} from "react-bootstrap";
import Automobile from "../../assets/images/automobile.svg";
import Doc from "../../assets/images/doc.svg";
import Home from "../../assets/images/home.svg";
import Family from "../../assets/images/family2.svg";
import Law from "../../assets/images/law.svg";
import Document from "../../assets/images/document.svg";
import Doc01 from "../../assets/images/doc01.svg";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import LoginForm from "../../components/loginform/loginform";

import Modal from "react-modal";
import SignupForm from "../../components/signupform/signupform";
import ServicePlan from "./servicePlan";
import LandingFooter from "./LandingFooter";
import {Link} from "react-router-dom";
import Splash from "../../assets/images/Splash.png";
import appleApp from "../../assets/images/appleApp.png";
import andriodApp from "../../assets/images/androidApp.png";

const LandingPage = () => {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }

    const coomingSoon = () => {
        alert("comming soon");
    }


    return (
        <>
            <section className={"landing-section"}>
                <div className="signupandlogingBtn">
                    <button onClick={openModal}>Login/Signup</button>
                </div>
                <div className="landing-inner-section">
                    <h1>Bringing Levity To The Inevitable!!!</h1>
                    <h3>We Keep In Touch With YOU!</h3>
                    <div className="popup-module">
                        <h2>If You Don't Check In ... We Will Check On You!</h2>
                        <div className="ml-3">
                            <Link>via Text Message, </Link>
                            <Link>Email,</Link>
                            <Link>Phone call to you and </Link>
                            <Link>Your ICE Contacts</Link>
                        </div>
                    </div>
                    <div className="landing-inner-section-image">
                        <Row className="pb-5">
                            <div className="border-section-02 common_border">
                                <div className="inner-div-bg">
                                    <img src={Automobile} alt={"auto-mopbile-img"}/>
                                </div>
                            </div>
                            <div className="border-section  common_border">
                                <div className="inner-div-bg">
                                    <img src={TODO} alt={"todo-img"}/>
                                </div>
                            </div>
                            <div className="border-section-03 common_border">
                                <div className="inner-div-bg">
                                    <img src={Doc} alt={"doc-img"}/>
                                </div>
                            </div>
                        </Row>

                        <Row className="d-flex landing-row">
                            <div>
                                <div className="border-section-04 common_border">
                                    <div className="inner-div-bg">
                                        <img src={Family}/>
                                    </div>
                                </div>
                            </div>
                            <div className="landing-center-div">
                                <h4>You decide what to</h4>
                                <h4>SHARE</h4>
                                <h4>Keep Private</h4>
                                <h4>What Information to Release & To Who</h4>
                            </div>
                            <div>
                                <div className="border-section-05 common_border">
                                    <div className="inner-div-bg">
                                        <img src={Home}/>
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <Row className="pt-5">
                            <div className="border-section-06 common_border">
                                <div className="inner-div-bg">
                                    <img src={Document}/>
                                </div>
                            </div>
                            <div className="border-section-8 common_border">
                                <div className="inner-div-bg">
                                    <img src={Law}/>
                                </div>
                            </div>
                            <div className="border-section-07 common_border">
                                <div className="inner-div-bg">
                                    <img src={Doc01}/>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                <div className="share-content-section">
                    <p>
                        {" "}
                        * Share with YOur Loved Ones * Store copies of your information in
                        our Secured Vault *
                    </p>
                    <p>
                        * In case you become Incapacitated or Deceased - Release your
                        information to your Love Ones *{" "}
                    </p>
                </div>
                {/* modal */}

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"

                >
                    <div
                        className="close-icon mr-3 "
                        onClick={() => closeModal()}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#4e5f7d">
                            <g fill="none">
                                <path d="M0 0h24v24H0z" opacity="0.05"/>
                                <path
                                    fill="#4e5f7d"
                                    d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                                />
                            </g>
                        </svg>
                    </div>

                    <div className="tbs">

                        <div className="modal-left-img">
                            <img src={Splash} className="landingPopupSideImage" alt={"landing-login-img"}/>
                            <div className="appContainer" style={{marginTop: '6px'}}>
                                <img src={appleApp} className="img1" onClick={coomingSoon}/>
                                <img src={andriodApp} className="img2" onClick={coomingSoon}/>
                            </div>
                        </div>
                        <Tabs
                            className="modal-right modal-inner-section-data overflow-hidden tbl"
                        >
                            <TabList>
                                <Tab>Login</Tab>
                                <Tab>Signup</Tab>
                            </TabList>
                            <TabPanel className="landing-form-section">
                                <LoginForm/>
                            </TabPanel>
                            <TabPanel className="landing-form-section">
                                <SignupForm/>
                            </TabPanel>
                        </Tabs>
                    </div>
                </Modal>
            </section>
            <ServicePlan openModal={openModal}/>
            <LandingFooter/>
        </>
    );
};

export default LandingPage;
