import React from 'react';
import Section2 from '../Section2';

const AddItemNotesWizard = (props) => {
	const {itemNotes, setItemNotes, isItemNameInput , isItemNotesInput , stepNumber} = props;
	return (
		<Section2
			itemNotes={itemNotes}
			setItemNotes={setItemNotes}
			isItemNameInput={isItemNameInput}
			isItemNotesInput={isItemNotesInput}
			isItemNoteInput={true}
			isViewSendTextEmail={false}
			isViewSuggetion={true}
			isViewRichCheckbox={true}
			stepNumber={stepNumber}
			itemNoteLabel="What instructions would you like to convey to your loved ones for this Item?"
		/>
	);
};

export default AddItemNotesWizard;
