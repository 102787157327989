import React, {useEffect} from 'react';
import HeaderNewLandingPage from "./HeaderNewLandingPage/HeaderNewLandingPage";
import FooterNewLandingPage from "./FooterNewLandingPage/FooterNewLandingPage";
import HelpingSection from "./helpingSection/HelpingSection";
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";


const LandingPageNew = () => {
    useEffect(() => {
        document.cookie = "acceptTermsAndConditions=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },[]);

    return (
        <div className="landing_page_content position-relative">
            <HeaderNewLandingPage/>
            <HelpingSection/>
            <FooterNewLandingPage/>
            <CookieConsent
                location="bottom"
                buttonText="X"
                cookieName="acceptTermsAndConditions"
                contentClasses={'test'}
                style={{ background: "#007bff", height: "50px", width: "720px", alignItems: "center", textAlign: "center", fontSize: "18px", left: "50%", transform: "translateX(-50%)" }}
                buttonStyle={{  background: "#007bff",color: "white", fontSize: "17x", fontWeight: "bold"}}
                expires={150}
            >
                By accessing this site, you agree to our Terms of Service and Privacy Notice.
            </CookieConsent>

            {/*  scroll To Top  */}
            <ScrollToTop top={'100'} className="scroll-top" component={<i className="fas fa-arrow-up"></i>} smooth color="white" />
        </div>
    )
}
export default LandingPageNew;