import React from 'react';
import {Button, Form, Table} from "react-bootstrap";
import {Paper, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 500,
    },
    customCell: {
        padding: '8px 16px',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    customHeaderCell: {
        fontSize: '16px',
    },
}));
const FileAttachment = ({files, onChangeFiles, setFiles}) => {
    const classes = useStyles();
    const handleShowDeleteMessage = (index) => {
        let newFiles = [];
        files.map((file, fIndex) =>
            index !== fIndex ? newFiles.push(file) : null
        );
        setFiles(newFiles);
    }
    return (
        <div>
            <div className="text-left">
                <div className="pt-3 pb-3">
                    <b>Item Attachments</b>
                </div>
            </div>
            <div>
                <Form.Group controlId="exampleForm.ControlInput2">
                    <label>
                        <b>Upload: </b>
                    </label>
                    <Form.File
                        id="custom-file"
                        label="Select File to Upload..."
                        custom
                        onChange={(e) => onChangeFiles(e)}
                        multiple
                    />
                </Form.Group>
            </div>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" style={{fontWeight:500}}>File Name</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" style={{fontWeight:500}}>File Size</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" style={{fontWeight:500}}>Upload Date</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" style={{fontWeight:500}}>Action</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {(files || []).map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                        id={index}
                                    >
                                        <Typography variant="body1" style={{fontWeight:400}}>{row.name}</Typography>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        id={index}
                                    >
                                        <Typography variant="body1" style={{fontWeight:400}}>{row.size}</Typography>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        id={index}
                                    >
                                        <Typography variant="body1" style={{fontWeight:400}}>
                                            {moment(row.lastModified).format('d/M/YYYY')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        id={index}
                                    >
                                        <Button
                                            variant="danger"
                                            className="mx-2 btn-sm-height"
                                            onClick={() => handleShowDeleteMessage(index)}
                                        >
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default FileAttachment;