// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDc2SWe9zGdic1XfQOUklJr4gnDTmwxKlM",
    authDomain: "wishlist-app-b70cd.firebaseapp.com",
    projectId: "wishlist-app-b70cd",
    storageBucket: "wishlist-app-b70cd.appspot.com",
    messagingSenderId: "766078001860",
    appId: "1:766078001860:web:6172411395796e268c5f91",
    measurementId: "G-TSJLK11KVH"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);