import React from "react"
import "./IntermediatePage.css"
import {Button} from "react-bootstrap";
import keys_to from "../../assets/images/Keys_to.png"
import long_to_do_list_cartoon from "../../assets/images/long_to_do_list_cartoon.jpg"
import keep from "../../assets/images/Keep.png"
import {Link} from "react-router-dom";


const IntermediatePage = () => {
    return (
        <div className="IntermediatePage">
            <div className="text_heading">
                <h3 style={{paddingTop:'20px'}}>We have logged you out for your protection after 30 minutes of idle time.</h3>
            </div>
            <div className="login_btn">
                <Link to='/'>
                    <Button>log in...</Button>
                </Link>
            </div>
            <div className="logo_img">
                <img src={keys_to} width="35%" alt="keys_to"/>
            </div>
            <div className="main_img">
                <img src={long_to_do_list_cartoon} width="50%" alt="long_to_do_list_cartoon"/>
            </div>
            <div className="logo_img_2">
                <img src={keep} width="30%" alt="keep"/>
            </div>
        </div>
    )
}
export default IntermediatePage