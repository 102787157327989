import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import { Form, FormControl, FormGroup, Button, Alert, Table, Row, Col, Container } from 'react-bootstrap';

const Userloginhistory = (params) => {
    const loginData = [
        {
            'id': 1,
            'logindate': "3-30-2021 04:30 PM",
            'ip': "123.123.123.123",
            'freq': "Yes",
        },
        {
            'id': 2,
            'logindate': "3-30-2021 05:30 PM",
            'ip': "123.123.123.123",
            'freq': "Yes",
        },
        {
            'id': 3,
            'logindate': "3-30-2021 06:30 PM",
            'ip': "123.123.123.123",
            'freq': "Yes",
        },
        {
            'id': 4,
            'logindate': "3-30-2021 07:30 PM",
            'ip': "123.123.123.123",
            'freq': "No",
        },
    ];


    return (
        <>
            <h4 className="mb-4 custom-text-color">User Login History </h4>
            <Table hover bordered responsive>
                <thead>
                    <tr>
                        <th>Login Date</th>
                        <th>IP Address</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loginData.map(ele => {
                            return (
                                <tr key={ele.id} >
                                    <td>{ele.logindate}</td>
                                    <td>{ele.ip}</td>
                                </tr>
                            )
                        })

                    }

                </tbody>
            </Table>
        </>
    )
}
export default Userloginhistory;