import React from 'react';
import ReactDOM from 'react-dom';
import {Row, Col, Container, Tabs, Tab} from 'react-bootstrap';
import Header from "../../components/NewHeader/Header";
import {Link} from "react-router-dom";

const howitworks = () => {
    return (
        <>
            <div className="pt-4 pl-4 pr-4">
                <Link to="/" className="btn custom-link btn-primary">
                    <span className="w-5"/> Back
                </Link>
            </div>
            <Container className="bg-white mt-4 p-4">
                <h1 className="text-center">How It Works</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ornare id velit eget fermentum.
                    Suspendisse feugiat sem id posuere accumsan. Etiam commodo iaculis felis sed commodo. Curabitur
                    purus libero, posuere et justo vel, consequat euismod augue. Ut efficitur sagittis hendrerit. Mauris
                    dignissim tempor neque, sed congue ipsum commodo eget. Nulla facilisi. Sed porta cursus auctor.
                    Aliquam tempor nisi id mauris sodales, ac facilisis diam convallis. Integer ut vehicula urna. </p>
                <p>Maecenas a tempor diam, in hendrerit nisi. Mauris nec egestas quam. Quisque sit amet gravida dolor.
                    Morbi quis cursus enim, eget cursus nunc. Aenean massa eros, consectetur eget consectetur et,
                    volutpat sit amet ligula. Sed pulvinar sagittis pellentesque. Maecenas condimentum luctus dui, sed
                    laoreet risus elementum non. Ut in nisi nibh. Maecenas nec accumsan neque, eu venenatis leo. Nam eu
                    aliquet libero. Nullam nisi metus, mollis nec est imperdiet, consequat molestie tortor. Phasellus
                    vitae euismod risus. Sed sit amet bibendum tellus. Duis feugiat vel neque sit amet mollis. Donec
                    venenatis, eros vitae venenatis tristique, ipsum augue aliquam eros, at pretium dui neque sit amet
                    eros. Donec sollicitudin nulla eget turpis faucibus finibus. </p>
                <p>Nulla ut nisi congue, bibendum nulla sed, commodo magna. Nam posuere urna quis metus aliquam varius.
                    Morbi pulvinar, dolor vitae aliquet suscipit, massa nunc tempor felis, rutrum volutpat ipsum dui at
                    enim. Sed dapibus rutrum ultricies. Nullam aliquet aliquet consectetur. Nulla et varius tortor.
                    Maecenas dignissim leo id pharetra bibendum. Quisque ac mauris placerat, tempus neque id, fermentum
                    urna. Nulla eu sapien semper, aliquet lectus eu, consequat tellus. Etiam efficitur vel turpis vel
                    viverra. Morbi at nisl ex. Phasellus malesuada orci et arcu laoreet malesuada. Vivamus ullamcorper
                    nisi vitae lacus porttitor tempus. Cras commodo erat enim, vehicula blandit nibh lacinia eget. </p>
                <p>Etiam imperdiet dui nec egestas porttitor. Pellentesque vulputate neque at odio blandit viverra.
                    Vivamus eleifend porta tortor, et elementum dolor luctus ut. Integer purus turpis, blandit vel eros
                    vel, eleifend pretium ipsum. Morbi rutrum ullamcorper leo. Integer finibus egestas ligula, eget
                    faucibus nulla placerat sit amet. Quisque tristique risus ligula, id fringilla ex eleifend in.
                    Mauris eleifend augue vitae dolor dictum laoreet. Morbi ut nunc orci. Maecenas mauris erat, eleifend
                    at lacus non, vehicula placerat tellus. Nunc ac justo a metus viverra viverra vel vitae mauris.
                    Maecenas laoreet tincidunt purus, id auctor leo convallis egestas. Maecenas laoreet consequat
                    ullamcorper. </p>
                <p>Aenean finibus sodales congue. Vivamus scelerisque non sapien eu rhoncus. Suspendisse faucibus lacus
                    augue, quis lacinia felis auctor id. Cras congue eu urna at aliquam. Donec efficitur quis tortor
                    rhoncus sollicitudin. Donec a neque aliquet, efficitur nibh in, condimentum ipsum. Aenean rhoncus,
                    nunc vel sollicitudin finibus, sapien lorem pharetra risus, a dictum orci sapien eu diam. Mauris
                    blandit facilisis tellus, venenatis mattis nibh egestas mollis. Aliquam eget pharetra arcu. In ac
                    condimentum nunc, ac imperdiet lorem. </p>
            </Container>
        </>
    )
}
export default howitworks;