import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import '@pathofdev/react-tag-input/build/index.css';

const DeleteModal = ({ deleteModalShow, setDeleteModalShow, removeItems }) => {
	return (
		<>
			<Modal
				show={deleteModalShow}
				onHide={() => setDeleteModalShow(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<div className="text-center">
						Are you sure you want to delete these?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						className="custom-btn"
						onClick={() => removeItems()}
					>
						Yes
					</Button>
					<Button
						variant="primary"
						className="custom-btn"
						onClick={() => setDeleteModalShow(false)}
					>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DeleteModal;
