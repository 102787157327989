import React from 'react'
import Header from "../../components/NewHeader/Header";
import {Button} from "@material-ui/core";
import {Link} from 'react-router-dom'
import {Col, Row} from "react-bootstrap";
import Logout from "../../components/logout/Logout";
import './layout/ChooseServicePlan.css'
import Back from "../../assets/images/Back_white.png";
import Standardfeatures from "../../components/standardfeatures/standardfeatures";

const FirstPlanSelection = () => {
    return (
        <>
            <section className="SERVICE PLAN">
                <Header title="Service Plan"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div className={'d-flex justify-content-center mt-5'}>
                    <Link to={'/chooseServicePlan'}>
                        <Button variant="contained" color="primary">
                            Select Service Plan
                        </Button>
                    </Link>
                </div>
                <Standardfeatures box={true} />
            </section>
        </>
    )
}

export default FirstPlanSelection;