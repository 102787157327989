import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Paper,
	Checkbox,
	Tooltip,
} from '@material-ui/core';

import './notestable.css';

const headCells = [
	{
		id: 'itemname',
		numeric: false,
		disablePadding: true,
		label: 'Wish Description Item',
		isShorting: true,
		align: 'left',
	},
	{
		id: 'hasSharedWith',
		numeric: true,
		disablePadding: false,
		label: 'Shared With',
		isShorting: false,
		align: 'center',
	},
	{
		id: 'hasReleaseTo',
		numeric: true,
		disablePadding: false,
		label: 'Released To',
		isShorting: false,
		align: 'center',
	},
	{
		id: 'hasAttachments',
		numeric: true,
		disablePadding: false,
		label: 'Has Attachments',
		isShorting: false,
		align: 'center',
	},
	{
		id: 'inCapacitated',
		numeric: true,
		disablePadding: false,
		label: 'In - capacitated',
		isShorting: false,
		align: 'center',
	},
	{
		id: 'isPassedAway',
		numeric: true,
		disablePadding: false,
		label: 'Passed Away',
		isShorting: false,
		align: 'center',
	},
	{
		id: 'created',
		numeric: true,
		disablePadding: false,
		label: 'Created',
		isShorting: true,
		align: 'center',
	},
	{
		id: 'updated',
		numeric: true,
		disablePadding: false,
		label: 'Updated',
		isShorting: true,
		align: 'center',
	},
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => {
					return headCell.isShorting ? (
						<TableCell
							key={index}
							align={headCell.align}
							padding={headCell.disablePadding ? 'none' : 'default'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								key={index}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden} key={index}>
										{order === 'desc'
											? 'sorted descending'
											: 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					) : (
						<TableCell key={index} align={'center'}>
							{headCell.label}
						</TableCell>
					);
				})}
			</TableRow>
		</TableHead>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	container: {
		maxHeight: 750,
	},
	customCell: {
		padding: '8px',
	},
	customRow: {
		cursor: 'pointer',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}));
const customUseStyles = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
	},
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis =
		(array || []).length && array.map((el, index) => [el, index]);
	(stabilizedThis || []).length &&
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
	return (stabilizedThis || []).length && stabilizedThis.map((el) => el[0]);
}

function getFormatedDate(uploadDate) {
	var myDate = new Date(uploadDate);

	var year = myDate.getFullYear();

	var month = myDate.getMonth() + 1;
	if (month <= 9) month = '0' + month;

	var day = myDate.getDate();
	if (day <= 9) day = '0' + day;

	return month + '/' + day + '/' + year;
}

const Notestable = ({ notesData }) => {
	const classes = useStyles();
	const customClasses = customUseStyles();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [selected, setSelected] = useState(null);

	const history = useHistory();
	let id = useLocation().search;
	const rowid = id.replace('?', '');

	useEffect(() => {
		setSelected(rowid ? Number(rowid) : null);
	}, [rowid]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (event, id) => {
		setSelected(id);
		history.push(`/dashboard/?${id}`);
	};

	const isSelected = (id) => selected === id;

	return (
		<>
			<Paper className={classes.paper}>
				<TableContainer className={classes.container}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						aria-label="enhanced table"
						stickyHeader
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected && selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={notesData.length}
						/>
						<TableBody>
							{(stableSort(notesData, getComparator(order, orderBy)) || []).map(
								(row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row.id)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											className={classes.customRow}
										>
											<TableCell
												component="th"
												id={labelId}
												scope="row"
												// padding="none"
												align="left"
												className={classes.customCell}
											>
												<div className="wrapped-1">{row.itemname}</div>
											</TableCell>
											<TableCell
												align="center"
												width={50}
												className={classes.customCell}
											>
												<Tooltip
													arrow
													classes={customClasses}
													placement="top"
													title={row.sharedwith}
												>
													<Checkbox
														checked={!!row.hasSharedWith}
														color="primary"
														inputProps={{ 'aria-label': 'secondary checkbox' }}
													/>
												</Tooltip>
											</TableCell>
											<TableCell
												align="center"
												width={50}
												className={classes.customCell}
											>
												<Tooltip
													arrow
													classes={customClasses}
													placement="top"
													title={row.releaseto}
												>
													<Checkbox
														checked={!!row.hasReleaseTo}
														color="primary"
														inputProps={{ 'aria-label': 'secondary checkbox' }}
													/>
												</Tooltip>
											</TableCell>
											<TableCell
												align="center"
												width={50}
												className={classes.customCell}
											>
												<Checkbox
													checked={!!row.hasAttachments}
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
												/>
											</TableCell>
											<TableCell
												align="center"
												width={50}
												className={classes.customCell}
											>
												<Checkbox
													checked={!!row.isincapacitated}
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
												/>
											</TableCell>
											<TableCell
												align="center"
												width={50}
												className={classes.customCell}
											>
												<Checkbox
													checked={!!row.isdeceased}
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
												/>
											</TableCell>
											<TableCell
												align="center"
												width={150}
												className={classes.customCell}
											>
												{getFormatedDate(row.created)}
											</TableCell>
											<TableCell
												align="center"
												width={150}
												className={classes.customCell}
											>
												{getFormatedDate(row.updated)}
											</TableCell>
										</TableRow>
									);
								}
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

export default Notestable;
