import React, { useState } from 'react';
import {Form, Button, Alert, Row, Modal, FormGroup} from 'react-bootstrap';
import axios from 'axios';
import Popup from "../termsandpolicypopup/popupprivacy";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './signupform.css'
import PhoneNumberField from "../PhoneNumber";

const Signupform = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(null);
    const [cPassword, setCPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [signuperrormessage, setsignuperrormessage] = useState(false);
    const [signuperrorpassword, setsignuperrorpassword] = useState(false);
    const [signupsucess, setsignupsucess] = useState(false);

    const [passwordError, setPasswordError] = React.useState(false);


    const handlePasswordBlur = () => {
        setPasswordError(false);
    };

    const submitSignupBtn = (e) => {
       const Pass_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&^_~+-.])[A-Za-z\d!@#$%&^-_~+-.]{8,}$/;
        e.preventDefault();
        const data = {
            password: password,
            password_confirmation: password,
            email: email,
            firstname: firstname,
            lastname: lastname,
            phonenumber: phonenumber
        };


        if (!Pass_REGEX.test(password)) {
            handleAleart()
        } else if (password !== cPassword) {
            setsignuperrorpassword(true)
            setsignuperrormessage(false)
        } else {
            axios.post(process.env.REACT_APP_SERVER + 'auth/register', data)
                .then(res => {
                    setsignuperrormessage(false)
                    setsignuperrorpassword(false)
                    setsignupsucess(true);

                    setFirstname('')
                    setLastname('')
                    setEmail('')
                    setPhonenumber('')
                    setPassword('')
                    setCPassword('')
                }).catch((err) => {
                setsignuperrormessage(true);
                setsignuperrorpassword(false)
            });
        }
    }


    const handleAleart = () => {
        setShow(true)
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Password Rule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={'font-weight-bold'}>Your password must be at least 8 characters long.</Row>
                    <div className={'ml-3'}>
                        <Row>It cannot begin with a number or special character</Row>
                        <Row>It must contain at least 1 upper case letter</Row>
                        <Row>It must contain at least 1 lower case letter</Row>
                        <Row>It must contain at least 1 number</Row>
                        <Row>It must contain at least 1 special character</Row>
                        <Row>Valid Special Characters are:</Row>
                        <strong className={'ml-4'}>! @ # $ % ^ - _ ~ + .</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="p-1">
                <Form onSubmit={submitSignupBtn}>
                    {
                        signuperrormessage
                            ? <Alert key="wrong-login" variant="danger">
                                Sorry that Email already exists.
                            </Alert>
                            : null
                    }
                    {
                        signuperrorpassword
                            ? <Alert key="wrong-login" variant="danger">
                                Your passwords do not match!
                            </Alert>
                            : null
                    }
                    {
                        signupsucess
                            ? <Alert key="wrong-login" variant="success">
                                User created successfully
                            </Alert>
                            :null
                    }
                    {/*<Form.Group controlId="exampleForm.ControlInput1">*/}
                    {/*    <Form.Control type="text" placeholder="Username" required*/}
                    {/*                  value={username} onChange={e => setUsername(e.target.value)}/>*/}
                    {/*</Form.Group>*/}
                    <Form.Group controlId="exampleForm.ControlInput3">
                        <Form.Control type="text" placeholder="First Name" required
                                      value={firstname} onChange={e => setFirstname(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                        <Form.Control type="text" placeholder="Last Name" required
                                      value={lastname}
                                      onChange={e => setLastname(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                        <PhoneNumberField
                            phoneNumber={phonenumber}
                            setPhoneNumber={setPhonenumber}
                        />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput2">
                        <Form.Control type="email" placeholder="Email" required
                                      value={email} onChange={e => setEmail(e.target.value)}/>
                    </Form.Group>
                    <FormGroup controlId="exampleForm.ControlInput3" className="pass_input">
                        <Form.Control type="password" placeholder="Password" required
                                      value={password} onChange={(e) => setPassword(e.target.value)}
                                      onBlur={handlePasswordBlur}
                        />
                        <ErrorOutlineIcon className="info-icon" onClick={handleAleart}/>
                    </FormGroup>
                    <Form.Group controlId="exampleForm.ControlInput4">
                        <Form.Control type="Password" placeholder="Confirm Password" required
                                      value={cPassword} onChange={e => setCPassword(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="btn-lg btn-block custom-btn"
                            style={{height: '45px'}}>
                        <span>Join</span>
                    </Button>
                    <p className="text-left pt-1">By clicking the Join button you are indicating that you have read
                        and
                        agree to the <Popup/>
                    </p>
                </Form>
            </div>
        </>
    );
}

export default Signupform;