import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LookupsModal from './LookupsModal';
import SendTextEmailModal from './SendTextEmailModal';

const Section2 = ({
                      isInputDisabled,
                      itemName,
                      setItemName,
                      itemNotes = '',
                      setItemNotes,
                      suggestionsData,
                      rowid,
                      existingSharedContacts,
                      getAllSuggestions
                  }) => {
    const [Lookups, setLookups] = React.useState(false);
    const [selectedSuggetion, setSelectedSuggetion] = useState('');
    const [notes, setNotes] = React.useState();
    const [sendTextEmailModalShow, setSendTextEmailModalShow] = React.useState(
        false
    );
    const [sharedContacts, setSharedContacts] = React.useState([]);
    const [sendMessage, setSendMessage] = useState('');
    useEffect(() => {
        setNotes(
            itemNotes
                ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(itemNotes))
                )
                : EditorState.createEmpty()
        );
    }, [itemNotes]);
    useEffect(() => {
        setSharedContacts(existingSharedContacts);
    }, [existingSharedContacts]);

    const handleSelectSuggetions = (event) => {
        setSelectedSuggetion(Number(event.target.id));
    };

    const handleSaveSuggetions = () => {
        setItemName(
            (
                suggestionsData.find(
                    (suggetion) => selectedSuggetion === suggetion.id
                ) || {}
            ).sSubject
        );
        setLookups(false);
    };

    const onEditorStateChange = (editorState) => {
        setNotes(editorState);
    };

    const onBlurHandler = (event, editorState) => {
        setItemNotes(editorState.getCurrentContent().getPlainText());
    };

    const handleSendTextChange = (event) => {
        const newContactsData = sharedContacts.map((contact) => {
            return {
                ...contact,
                ...(contact.contactid === Number(event.target.id)
                    ? {
                        isSendText: event.target.checked ? 1 : 0,
                    }
                    : {}),
            };
        });
        setSharedContacts(newContactsData);
    };

    const handleSendEmailChange = (event) => {
        const newContactsData = sharedContacts.map((contact) => {
            return {
                ...contact,
                ...(contact.contactid === Number(event.target.id)
                    ? {
                        isSendEmail: event.target.checked ? 1 : 0,
                    }
                    : {}),
            };
        });
        setSharedContacts(newContactsData);
    };

    const sendTextEmail = () => {
        setSendTextEmailModalShow(false);
        setSendMessage('');
        setSharedContacts(existingSharedContacts);
    };
    const closeSendTextEmailModal = () => {
        setSendTextEmailModalShow(false);
        setSendMessage('');
        setSharedContacts(existingSharedContacts);
    };


    const openSelectedSuggestion = () =>{
        setSelectedSuggetion('');
        setLookups(true);
    }
    return (
        <div className="box">
            {isInputDisabled ? <div>*Section 2</div> : null}
            <Form.Group
                // controlId="exampleForm.ControlInput2-Name"
                className="d-flex flex-column align-items-start"
            >
                <div className="w-100 d-flex align-items-center justify-content-between pb-2">
                    <label>
                        <b>Wish Description Item:</b>
                    </label>
                    <div>
                        <Button
                            variant="secondary"
                            className="btn-sm-height ml-2"
                            onClick={() => {
                                setSendTextEmailModalShow(true);
                            }}
                            disabled={rowid ? false : true}
                        >
                            Send Text/Email...
                        </Button>
                        <Button
                            variant="secondary"
                            className="btn-sm-height ml-2"
                            onClick={openSelectedSuggestion}
                        >
                            {isInputDisabled ? 'View Suggestion' : 'Import Suggestion'}
                        </Button>
                    </div>
                </div>
                <Form.Control
                    as="textarea"
                    placeholder=""
                    rows={3}
                    required
                    // value={itemName}
                    disabled={isInputDisabled}
                    onChange={(e) => setItemName(e.target.value)}
                />
            </Form.Group>
            <Form.Group
                controlId="exampleForm.ControlInput2-Notes"
                className="d-flex flex-column align-items-start"
            >
                <label className="mb-1">
                    <b>Notes:</b>
                </label>
                {isInputDisabled ? (
                    <Form.Control
                        as="textarea"
                        rows={9}
                        required
                        // value={itemNotes}
                        disabled={isInputDisabled}
                        onChange={(e) => setItemNotes(e.target.value)}
                    />
                ) : (
                    <Editor
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorState={notes}
                        onEditorStateChange={onEditorStateChange}
                        onBlur={onBlurHandler}
                    />
                )}
            </Form.Group>

            {Lookups ? (
                <LookupsModal
                    Lookups={Lookups}
                    setSelectedSuggetion={setSelectedSuggetion}
                    setLookups={setLookups}
                    suggestionsData={suggestionsData}
                    selectedSuggetion={selectedSuggetion}
                    handleSelectSuggetions={handleSelectSuggetions}
                    handleSaveSuggetions={handleSaveSuggetions}
                    isInputDisabled={isInputDisabled}
                />
            ) : null}
            {sendTextEmailModalShow ? (
                <SendTextEmailModal
                    sendTextEmailModalShow={sendTextEmailModalShow}
                    closeSendTextEmailModal={closeSendTextEmailModal}
                    itemName={itemName}
                    sharedContacts={sharedContacts}
                    handleSendTextChange={handleSendTextChange}
                    handleSendEmailChange={handleSendEmailChange}
                    sendTextEmail={sendTextEmail}
                    sendMessage={sendMessage}
                    setSendMessage={setSendMessage}
                />
            ) : null}
        </div>
    );
};

export default Section2;
