import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link,useHistory} from "react-router-dom";
import { Form,FormControl,FormGroup,Button,Alert,Table,Row,Col,Container} from 'react-bootstrap';

const Paymenthistory = () =>{
    const paymentData = [
        {
            'id': 1,
            'date':"04/1/2021",
            'cardno':"123321123321",
            'type':"Master Card",
            'amount': '$5.0'
        },
        {
            'id': 2,
            'date':"10/1/2021",
            'cardno':"425321123321",
            'type':"Master Card",
            'amount': '$5.0'
        },{
            'id': 3,
            'date':"31/1/2021",
            'cardno':"342121123321",
            'type':"Master Card",
            'amount': '$5.0'
        },{
            'id': 1,
            'date':"20/4/2021",
            'cardno':"2343321123321",
            'type':"Visa",
            'amount': '$5.0'
        },
    ];


    return(
        <>
        <h4 className=" mb-4 custom-text-color">User Payments: </h4>
           <Table hover bordered responsive>
                <thead>
                    <tr>
                    <th>Payment Date</th>
                    <th>Credit Card #</th>
                    <th>Type</th>
                    <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    paymentData.map(ele => {
                        return(
                    <tr key={ele.id} >
                    <td>{ele.date}</td>
                    <td>{ele.cardno}</td>
                    <td>{ele.type}</td>
                    <td>{ele.amount}</td>
                    </tr>
                        )
                    })
                    
                    }
                    
                </tbody>
            </Table> 
        </>
    )
}
export default Paymenthistory;