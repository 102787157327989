import React, {useEffect} from "react";
import Header from "../../components/NewHeader/Header";
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import axios from "axios";
import './CheckInPage.css'
import {makeStyles} from '@material-ui/core/styles';
import Back from "../../assets/images/Back_white.png";
import SessionExpired from '../../components/SessionExpired/SessionExpired'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const CheckInResponse = () => {
    const [BannderAd, setBannerAd] = React.useState('')
    useEffect(() => {
        getBannderAds();
    }, [])

    const nUserID = localStorage.getItem("userid");
    const token = localStorage.getItem("token");
    const userName = localStorage.getItem("name");
    const [open, setOpen] = React.useState(false);
    const [TokenExpired, setTokenExpired] = React.useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const getBannderAds = () => {
        const nID = localStorage.getItem('nID')
        if (nID === null) {
            localStorage.setItem('nID', 1)
        }
        setOpen(true)
        axios.get(process.env.REACT_APP_SERVER + `user/advertisementget?nUserID=${nUserID}&nID=${nID}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    localStorage.setItem('nID', res.data[0].nNextID)
                    setBannerAd(res.data[0].url)
                    setOpen(false)
                }
            }).catch((err) => {
            console.log(err)
        })
    }

    const styles = {
        OkayBtn: {
            width: '105px',
            borderRadius: '5px',
            backgroundColor: '#7030a0',
            position: 'relative',
            top: '50%'
        }
    }
    return (
        <>
            {/*<div>
                <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>*/}
            {
                TokenExpired
                && <SessionExpired/>
            }

            <section className="profile-main-section">
                <Header title="CHECK IN RESPONSE"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div className="mainContainer">
                    {localStorage.getItem('showads') === '1' &&<img src={BannderAd} alt="image"/>}
                    <p style={{marginTop: '95px'}}>We have received your Check In for the Day.</p>
                    <div className={'d-flex justify-content-center user_message'}>
                        <p>Thank you and have a wonderful Day</p><p className="ml-1">{userName}</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/maindashboard">
                            <Button className={'mt-3'} style={styles.OkayBtn}>Okay</Button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CheckInResponse