import React, {useState} from 'react';
import FileAttachedModal from '../newNotesform/FileAttachedModal';
import {Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Add from '../../assets/images/Add.png';
import ic_attach from '../../assets/images/ic_attatch.png'
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const FileAttachedContainer = ({itemDetails, getAllItems}) => {
    const [fileAttachedModalShow, setFileAttachedModalShow] = useState(false);
    const [existingFiles, setExistingFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const classes = useStylesBootstrap();

    const [open, setOpen] = React.useState(false);
    const classess = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    function handleFiledAttachModalShow() {
        getmedia(true);
    }

    const getFileNames = () => {
        let getContactsList = [];
        existingFiles.map((contact) => getContactsList.push(contact.filename));
        files.map((contact) => getContactsList.push(contact.name));
        return getContactsList;
    };

    const fileNamesList =
        (getFileNames() || []).reduce(
            (nameList, lotObj) => nameList + ',' + lotObj,
            ''
        ) || '';

    function getmedia(isModalOpen) {
        var token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_SERVER + 'inventory/getmedia',
            {nInvItemID: itemDetails.id},
            {headers: {Authorization: `Bearer ${token}`}}
        )
            .then((res) => {
                setExistingFiles((res.data || []).length ? res.data : []);
                if (isModalOpen) {
                    setFileAttachedModalShow(true);
                    setFiles([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    function saveMedia(nInvItemID) {
        if (files && files.length) {
            return files.map((f) => {
                var token = localStorage.getItem('token');

                var saveFiledata = new FormData();
                saveFiledata.append('nUserID', localStorage.getItem('userid'));
                saveFiledata.append('nInvItemID', nInvItemID);
                saveFiledata.append('imgfile', f);

                return axios
                    .post(process.env.REACT_APP_SERVER + 'uploads3file',
                        saveFiledata,
                        {headers: {Authorization: `Bearer ${token}`}}
                    )
                    .then((res) => {
                        var data = new FormData();
                        data.append('nUserID', localStorage.getItem('userid'));
                        data.append('nInvItemID', nInvItemID);
                        data.append('s3FileName', res.data.filename);
                        data.append('nFileSize', f.size);
                        data.append('sFileName', f.name);
                        setOpen(true)
                        axios.post(process.env.REACT_APP_SERVER + 'inventory/addmedia', data,
                            {headers: {Authorization: `Bearer ${token}`}}
                        ).then((res) => {
                            getAllItems()
                            setOpen(false)
                        })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            });
        }
        setFiles([]);
    }


    const saveTooltipAttachment = (inviteID) => {
        var token = localStorage.getItem("token");

        const data = {
            nUserID: localStorage.getItem("userid"),
            nInvItemID: inviteID
        };

        axios
            .post(process.env.REACT_APP_SERVER + 'user/albumsRecalcToolTip', data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const onChangeFiles = (e) => {
        let data = [];

        for (let j = 0; j < files.length; j++) {
            data.push(files[j])
        }

        for (let i = 0; i < e.target.files.length; i++) {
            data.push(e.target.files[i])
        }
        setFiles(data)
    };

    function removedFiles(index) {
        let newFiles = [];
        files.map((file, fIndex) =>
            index !== fIndex ? newFiles.push(file) : null
        );
        setFiles(newFiles);
    }

    function removedExistingFiles(mediaID) {
        if (mediaID) {
            const data = {
                nUserID: localStorage.getItem('userid'),
                nID: mediaID,
                nInvItemID: itemDetails.id,
            };
            var token = localStorage.getItem('token');

            axios
                .post(process.env.REACT_APP_SERVER + 'inventory/deletemedia',
                    data,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    let newFiles = [];
                    existingFiles.map((file, fIndex) =>
                        file.id !== mediaID ? newFiles.push(file) : null
                    );
                    setExistingFiles(newFiles);
                    getAllItems()
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <>
            <div>
                <Backdrop className={classess.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>

            <Tooltip
                arrow
                classes={classes}
                placement="top"
                title={
                    itemDetails.attachmentstip ? itemDetails.attachmentstip : 'No File Attached Exist'
                }
            >
                {itemDetails && !!itemDetails.hasAttachments ? (
                    <img
                        src={ic_attach}
                        alt="FileAttach"
                        className="w-5"
                        style={{width: '35px', height: '35px'}}
                        onClick={() => handleFiledAttachModalShow()}
                    />
                ) : (
                    <img
                        src={Add}
                        alt="Add"
                        className="w-5"
                        style={{width: '25px',paddingTop:'10px' }}
                        onClick={() => handleFiledAttachModalShow()}
                    />
                )}
            </Tooltip>

            {fileAttachedModalShow ? (
                <FileAttachedModal
                    fileAttachedModalShow={fileAttachedModalShow}
                    setFileAttachedModalShow={setFileAttachedModalShow}
                    itemName={itemDetails.itemName}
                    onChangeFiles={onChangeFiles}
                    existingFiles={existingFiles}
                    removedExistingFiles={removedExistingFiles}
                    files={files}
                    removedFiles={removedFiles}
                    saveMedia={saveMedia}
                    saveTooltipAttachment={saveTooltipAttachment}
                    rowid={itemDetails.id}
                    setExistingFiles={setExistingFiles}
                    setFiles={setFiles}
                />
            ) : null}
        </>
    );
};

export default FileAttachedContainer;
