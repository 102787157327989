import React, {useEffect, useState} from "react";
import Header from "../../components/NewHeader/Header";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import Select from "@material-ui/core/Select";
import {FormControl, FormControlLabel, RadioGroup} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import {Card} from "react-bootstrap";
import {Button} from "@material-ui/core";
import {Modal} from 'react-bootstrap'
import genie_img from '../../assets/images/genie_image.jpg'
import Back from '../../assets/images/Back_white.png';
import './TimeSlot.css'
import moment from 'moment';
import SessionExpired from '../../components/SessionExpired/SessionExpired'


const TimeSlot = () => {
    const [timezone, setTimeZone] = React.useState([]);
    const [timeSlot, setTimeSlot] = React.useState([]);
    const [nID, setSelectedSlot] = React.useState("");
    const [nTimeZoneID, setNTimeZoneID] = React.useState("");
    const [seletedData, setSelectedData] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [LegendData, setLegendData] = React.useState('');

    const [showLegend, setShowLegend] = React.useState(false);
    const [TokenExpired, setTokenExpired] = useState(false);

    const handleLegendDailog = () => {
        getLegendData()
        setShowLegend(true)
    }

    const handleCloseLegend = () => {
        setShowLegend(false)
    }

    const getLegendData = () => {
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=3`)
            .then((res) => {
                setLegendData(res.data[0])
            }).catch((err) => {
            console.log(err)
        })
    }

    const handleCloses = () => {
        setOpen(false);
    };


    const [isSaved, setIsSaved] = React.useState(false);

    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);


    useEffect(() => {
        getTimeZone();
        getTimeSlotList().then(() => {
            getSelectedData();
        });
    }, []);


    const token = localStorage.getItem("token");
    const nUserID = localStorage.getItem("userid");


    const getSelectedData = () => {
        axios
            .get(process.env.REACT_APP_SERVER + `user/usertimeslotget?nUserID=${nUserID}`, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                if(res.data.status){
                    setTokenExpired(true)
                }else {
                    setSelectedData(res.data);
                    setSelectedSlot(res.data[0].timeslotid.toString().trim());
                    setNTimeZoneID(res.data[0].timezoneid.toString().trim());
                }
            })
            .catch((err) => {
            });
    };

    const getTimeZone = () => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                `user/timezonesget`,
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            )
            .then((res) => {
                if(res.data.status){
                    setTokenExpired(true)
                }else {
                    setTimeZone(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTimeSlotList = () => {
        setOpen(true);
        return axios
            .get(process.env.REACT_APP_SERVER + `user/usertimeslotlistget`, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                if(res.data.status){
                    setTokenExpired(true)
                }else {
                    setTimeSlot(res.data);
                    setOpen(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // console.log("====>",timeSlot)

    const HandleSaveBtn = () => {
        setIsSaved(true)
        const data = {nUserID, nTimeZoneID, nID}
        setOpen(true);
        axios.post(process.env.REACT_APP_SERVER + `user/usertimeslotsave`, data, {
            headers: {Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setIsSaved(false)
                    handleShow();
                    setOpen(false);
                }
            }).catch((err) => {
            console.log(err)
        })
    }

    const styles = {
        mainForm: {
            minWidth: "290px",
            height: "50px",
            marginBottom: "5px",
        },
        saveBtn: {
            width: "140px",
            margin: "4px"
        }
    };
    return (
        <>
            {/* <div>
                <Backdrop className={classes.backdrop} open={open} onClick={handleCloses}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>*/}
            {
                TokenExpired
                && <SessionExpired/>
            }
            <Modal show={showLegend} onHide={handleCloseLegend}>
                <Modal.Header closeButton className={'modelHeader'}>
                    <Modal.Title>{LegendData.theheader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="model-genie"
                         dangerouslySetInnerHTML={{__html: LegendData.thetext}}
                    >
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={handleCloseLegend}
                            style={{backgroundColor: '#7030a0', color: 'white', textTransform: 'lowercase'}}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{backgroundColor: '#7030a0', color: 'white'}}>
                    <Modal.Title>SuccessFully Updated!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your data is Updated Successfully!</Modal.Body>
                <Modal.Footer>
                    <Button style={{backgroundColor: '#7030a0', color: 'white', textTransform: 'lowercase'}}
                            onClick={handleClose}>Okay</Button>
                </Modal.Footer>
            </Modal>
            <section className="timeslot-main-section">
                <Header title="TIME SLOTS"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div>
                    <img src={genie_img} className="genie ml-5" onClick={handleLegendDailog}/>
                </div>
                <div className="d-flex justify-content-center">
                    <FormControl component="fieldset">
                        <Card className="mx-auto mb-3 mainCardTimeSlot">
                            <Card.Header className="pt-4 pb-4 w-100 hederpassword ">Time Slot Selection</Card.Header>
                            <Card.Body className="cards">
                                <p>
                                    Time Slots are only used by People who use the Daily Service Plan.
                                </p>
                                <div className={'MainTimeZoneSelection'}>
                                    <div className="timezone d-flex justify-content-center">
                                        <Select
                                            style={{width: '83%'}}
                                            className="timezonedata"
                                            inputProps={{"aria-label": "Without label"}}
                                            variant="outlined"
                                            value={nTimeZoneID}
                                            onChange={(e) => setNTimeZoneID(e.target.value)}
                                        >
                                            {timezone.map((res) => (
                                                <MenuItem value={res.id}>{res.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <RadioGroup
                                        aria-label="timeslot"
                                        name="timeslot"
                                        value={nID}
                                        onChange={(e) => setSelectedSlot(e.target.value)}
                                        className={'mx-auto'}
                                        style={{width: '84%'}}
                                    >
                                        {timeSlot.map((e) => (
                                            <Card style={styles.mainForm}>
                                                <FormControlLabel
                                                    value={e.id.toString().trim()}
                                                    control={<Radio color="primary"/>}
                                                    style={{marginLeft: "5px"}}
                                                    label={<span style={{fontsize: '12px'}}>{e.name}
                                                        <span
                                                            className={'ml-2 font-weight-bold'}>{moment("1990-02-01 " + e.fromtime).format("hh:mmA")}{' '}{'-'}{' '}{moment("1990-02-01 " + e.totime).format("hh:mmA")}</span>
                                                    </span>}
                                                />
                                            </Card>
                                        ))}
                                    </RadioGroup>
                                </div>
                                <div className="btnsave">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={styles.saveBtn}
                                        onClick={HandleSaveBtn}
                                        disabled={isSaved}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </FormControl>
                </div>
            </section>
        </>
    );
};

export default TimeSlot;
