import React, {useState} from 'react'
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {Button, Modal} from "react-bootstrap";
import './Logout.css'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const Logout = () => {
    const history = useHistory();
    const logoutHandler = () => {
        let userId = localStorage.getItem('userid')
        const data = {
            nUserID: userId
        }
        var token = localStorage.getItem('token');
        axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}auth/logout`, data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then(res => {
            localStorage.clear();
            history.push("/")
        })
            .catch(e => {
                localStorage.clear();
                history.push("/")
            })
    }
    const [shows, setShows] = useState(false);
    const handleCloses = () => {
        setShows(false);
    };
    const handleopen = () => {
        setShows(true);
    };

    return (
        <>
        <div style={{float: 'right', display: 'none'}} className={'LogoutBtn'}>
            <Button className="btn btn-light border btn btn-primary " onClick={handleopen}>
                <span className={'d-flex align-items-center justify-content-center'}><ExitToAppIcon />&nbsp;LogOut</span>
            </Button>
        </div>

            <Modal show={shows} onHide={handleCloses}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to Logout?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        No
                    </Button>
                    <Button
                        style={{backgroundColor: "#7030a0"}}
                        onClick={() => logoutHandler()}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Logout
