import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Toast } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Section2 from '../../components/notesform/Section2';
import Section1 from '../../components/notesform/Section1';
import Header from '../../components/NewHeader/Header';
import Logout from '../../components/logout/Logout';
import './addNewItem.css';

const AddNewItem = () => {
	let id = useLocation().search;
	const history = useHistory();
	const rowid = id.replace('?', '');

	const [suggestionsData, setSuggestionsData] = useState([]);
	const [itemName, setItemName] = useState('');

	const [itemNotes, setItemNotes] = useState('');
	const [showTost, setShowTost] = useState(false);
	const [existingSharedContacts, setExistingSharedContacts] = useState([]);
	const [nIncapacitated, setnIncapacitated] = useState('');
	const [nDeceased, setnDeceased] = useState('');


	useEffect(() => {
		getAllShareContacts();
	}, [rowid]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowTost(false);
		}, 5000);
		return () => clearTimeout(timer);
	}, [showTost]);

	const toggleTost = () => setShowTost(!showTost);

	useEffect(() => {
		if (rowid) {
			getDetails();
		}
	}, [rowid]);

	const getDetails = () => {
		const data = {
			nUserID: localStorage.getItem('userid')	,
			nID: rowid,
		};
		var token = localStorage.getItem('token');
		axios
			.post(process.env.REACT_APP_SERVER + 'inventory/getdetails',data,
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((res) => {
				if ((res.data || []).length) {
					setItemName(res.data[0] !== undefined ? res.data[0].itemname : '');
					setItemNotes(
						res.data[0] !== undefined ? res.data[0].itemnotes : ''
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}


	// function getAllSuggestions() {
	// 	var token = localStorage.getItem('token');
	// 	axios
	// 		.get(
	// 			process.env.REACT_APP_SERVER + `inventory/getsuggestions`,
	// 			{ headers: { Authorization: `Bearer ${token}` } }
	// 		)
	// 		.then((res) => {
	// 			setSuggestionsData(res.data);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }

	function getAllShareContacts() {
		if (rowid) {
			var token = localStorage.getItem('token');
			axios
				.get(
					process.env.REACT_APP_SERVER +
					`contact/getisshareisreleaseto?nUserID=${localStorage.getItem(
						'userid'
					)}&nInvItemID=${rowid}`,
					{ headers: { Authorization: `Bearer ${token}` } }
				)
				.then((res) => {
					const data = (res.data || []).length ? res.data : [];
					let SharedContacts = [];
					if (data && data.length) {
						data.map((contact) => {
							return contact.sharedwith === 1
								? SharedContacts.push({
									...contact,
									isSendText: 0,
									isSendEmail: 0,
								})
								: null;
						});
					}
					setExistingSharedContacts(SharedContacts);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	const submitNewItem = (e) => {
		e.preventDefault();
		if (itemName.length >= 500) {
			toggleTost();
		} else {
			var token = localStorage.getItem('token');

			axios
				.post(
					process.env.REACT_APP_SERVER +
					'user/incapacitateddeceasedsettings',
					{
						nUserID: localStorage.getItem('userid'),
						nIsIncapacitated: nIncapacitated,
						nIsDeceased: nDeceased,
					},
					{ headers: { Authorization: `Bearer ${token}` } }
				)
				.then((res) => { })
				.catch((err) => {
					console.log(err);
				});

			const data = {
				nUserID: localStorage.getItem('userid'),
				sItemName: itemName,
				sItemNotes: itemNotes,
				nIsShared: 0,
				nIsTimeRelease: 0,
				...(rowid ? { nID: Number(rowid) } : {}),
			};

			axios
				.post(
					process.env.REACT_APP_SERVER + 'inventory/additem',
					data,
					{ headers: { Authorization: `Bearer ${token}` } }
				)
				.then((res) => {
					setItemName('');
					setItemNotes('');
					history.push(`/newDashboard/?${res.data[0].ID}`);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleNIncapacitatedChange = (event) => {
		setnIncapacitated(event.target.checked ? 1 : 0);
	};
	const handleNDeceasedChange = (event) => {
		setnDeceased(event.target.checked ? 1 : 0);
	};

	return (
		<>
			{/* <header className="header text-center custom-header">
				<Col>
					<div className="header-title">Item List</div>
					<div className="fixed-right">
						Logged in as: {localStorage.getItem('name')}
					</div>
				</Col>
			</header> */}
			<Header title="Item List" />
			<div
				aria-live="polite"
				aria-atomic="true"
				style={{
					position: 'relative',
					backgroundColor: 'red',
				}}
			>
				<Toast
					style={{
						position: 'absolute',
						top: 10,
						right: 10,
						backgroundColor: 'red',
					}}
					show={showTost}
					onClose={toggleTost}
				>
					<Toast.Header>
						<strong className="mr-auto">
							Sorry Wish Description Item cannot exceed 500 characters.
						</strong>
					</Toast.Header>
				</Toast>
			</div>

			<Row className="mx-auto  mobile-button back-logout-btn pt-2">
				<Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
					<Link
						to={rowid ? `/dashboard/?${rowid}` : '/dashboard'}
						className="btn btn-light font-weight-bold border  pl-3 pr-3 pt-2 pb-2"
					>
						Back
				</Link>
				</Col>
				<Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
					<Logout />
				</Col>
			</Row>

			<Row className="bg-white p-4 justify-content-center">
				<Col md={7}>
					<div className="text-right m-3">
						<Button
							className="mx-2 ml-auto btn-sm-height custom-btn"
							disabled={itemName && itemNotes ? false : true}
							onClick={submitNewItem}
						>
							Save
						</Button>
					</div>
					<Section1
						isLabelExist={false}
						nIncapacitated={nIncapacitated}
						handleNIncapacitatedChange={handleNIncapacitatedChange}
						nDeceased={nDeceased}
						handleNDeceasedChange={handleNDeceasedChange}
					/>
					<Section2
						// getAllSuggestions={getAllSuggestions}
						isInputDisabled={false}
						itemName={itemName}
						setItemName={setItemName}
						itemNotes={itemNotes}
						setItemNotes={setItemNotes}
						suggestionsData={suggestionsData}
						rowid={rowid}
						existingSharedContacts={existingSharedContacts}
					/>
				</Col>
			</Row>
		</>
	);
};
export default AddNewItem;
