import React from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import ChangePlanPage from "../updatePlanPage/ChangePlanPage";

const StripeElement = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    return (
        <Elements stripe={stripePromise}>
            <ChangePlanPage/>
        </Elements>
    );
};

export default StripeElement;