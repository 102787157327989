import React, {useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useHistory, Link} from 'react-router-dom'
import IdleTimer from "react-idle-timer";

const ReactTimeout = () => {
    const history = useHistory()
    const IdelTimerRef = useRef(null)

    const [handleTimeOut, setHandleTimeOut] = useState(false)
    const [seconds, setSeconds] = React.useState(10);

    React.useEffect(() => {
        if (seconds > 1 && handleTimeOut) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }

        if (seconds === 1) {
            history.push('/Intermediate-Page')
            setHandleTimeOut(false)
            handleLogout()
        }
    });


    const onIdle = () => {
        setSeconds(10)
        setHandleTimeOut(true)
    }

    const timeisOutClose = () => {
        setHandleTimeOut(false)
    }

    const handleLogout = () => {
        localStorage.setItem('isSessionExpired', 1);
    }


    return (
        <>
            {
                localStorage.getItem('login') === 'true'
                && <IdleTimer
                    ref={IdelTimerRef}
                    timeout={30 * 60000}
                    // timeout={2000}
                    onIdle={onIdle}
                />
            }

            <Modal
                show={handleTimeOut}
                onHide={timeisOutClose}
                backdrop="static"
                centered
                size="lg"
                className="HowToUse-Modal-Container"
            >
                <Modal.Header closeButton className={'modelHeader'} style={{fontFamily: 'Century'}}>
                    <div className="dashboard-modal-title">Do you wish to continue?</div>
                </Modal.Header>
                <Modal.Body style={{fontFamily: 'Century'}}>
                    You have no idle for 10 minutes. <br/>
                    Please click 'Extend Session' to continue, and 'Logout' to end your session.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie d-flex" onClick={timeisOutClose}><p
                        style={{fontFamily: 'Century', marginBottom: '0'}}>Extend Session - </p>&nbsp; {seconds} Seconds</Button>
                    <Link to='/Intermediate-Page'>
                        <Button className="btngenie"
                                style={{backgroundColor: "#0982e7", fontFamily: 'Century'}}
                                onClick={handleLogout}>Logout</Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReactTimeout;