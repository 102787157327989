import {Col, Row, Table} from "react-bootstrap";
import {Button, RadioGroup, TextField} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import React, {useEffect, useState} from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import axios from "axios";
import "./ViewNewWishShared.css"
import moment from "moment";

const ViewNewsharedWish = () => {
    const history = useHistory()
    const id = useParams()
    const [sharedewishes, setSharedWishes] = useState([])
    const [mediaDetails, setmediaDetails] = useState([])
    const decodedData = atob(id.EncodedData).split('/')

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + `getsharedwithdistributedtodetails?nUserID=${decodedData[0]}&nID=${decodedData[1]}`)
            .then((res) => {
                setSharedWishes(res.data)
            }).catch((err) => {
            console.log(err)
        })
        getMedia()
    }, [])

    const downloadImage = (media) => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userid');
        axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${media.s3filename}&nUserID=${userId}`,
            {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                let link = document.createElement('a');
                link.setAttribute('href', res.data.url);
                link.setAttribute('download', media.filename);
                link.click();
            })
    }

    const getMedia = () => {
        axios.get(process.env.REACT_APP_SERVER + `getmedianot?nInvItemID=${decodedData[1]}&nID=${decodedData[0]}`)
            .then((res) => {
                setmediaDetails(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const filterD = sharedewishes.find(r => r.id == decodedData[1]);

    const styles = {
        RadioBtn: {
            display: 'flex', flexDirection: 'row'
        },
        textfield: {
            maxWidth: '900px'
        }
    }

    const BackPage = () => {
        history.goBack()
    }

    return (
        <div className="Main_view_shere">
            <section className="ViewWishes-section">
                <Row className="mx-auto text-center back-logout-btn">
                    <Col
                        lg={12}
                        className="p-2 text-start profile-left-btn d-flex back_button"
                    >
                        <Button onClick={BackPage} className="btn custom-link btn-primary"
                                style={{backgroundColor: '#7030a0', color: 'white'}}>
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Button>
                    </Col>
                </Row>
                <div className="View-Wishes_content">
                    <div className="Should">
                        <h5 style={{marginBottom: '10px'}}><strong>{filterD?.fname} {filterD?.lname}:</strong> is
                            Sharing
                            With You</h5>
                        <h5 style={{marginBottom: '10px'}} className="withDescriptionTitle">
                            Release this Wish should I become
                        </h5>
                        <div className={'mb-3'}>
                            <div className={"d-flex align-items-center mb-1"}>
                                <h6 className="m-0">Incapaciated</h6>
                                <RadioGroup style={styles.RadioBtn}>
                                    <span className="ml-1">
                                      <Radio
                                          checked={filterD?.isincapacitated === 1}
                                          value="Immediately"
                                          color="default"
                                          name="radio-button-demo"
                                          inputProps={{"aria-label": "Immediately"}}
                                      />
                                      Yes
                                    </span>
                                                        <span>
                                      <Radio
                                          checked={filterD?.isincapacitated === 0}
                                          value="After"
                                          color="default"
                                          name="radio-button-demo"
                                          inputProps={{"aria-label": "After"}}
                                      />
                                      No
                                    </span>
                                                    </RadioGroup>
                                                </div>
                                                <div className={"d-flex align-items-center mb-1"}>
                                                    <h6 style={{width: "91px", margin: '0px'}}>Deceased</h6>
                                                    <RadioGroup style={styles.RadioBtn}>
                                    <span className="ml-1">
                                      <Radio
                                          checked={filterD?.isdeceased === 1}
                                          value="Immediately"
                                          color="default"
                                          name="radio-button-demo"
                                          inputProps={{"aria-label": "Immediately"}}
                                      />
                                      Yes
                                    </span>
                                                        <span>
                                      <Radio
                                          checked={filterD?.isdeceased === 0}
                                          value="After"
                                          color="default"
                                          name="radio-button-demo"
                                          inputProps={{"aria-label": "After"}}
                                      />
                                      No
                                    </span>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    <h5 className={"d-flex justify-content-left my-1 mr-auto withDescriptionTitle"}>Description</h5>
                    <div className={"d-flex justify-content-center withDescriptionField mb-3"}>
                        <TextField
                            style={styles.textfield}
                            id="itemName"
                            value={filterD?.itemname}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </div>
                    <h5 className={"d-flex justify-content-left my-1 mr-auto withDescriptionTitle"}>Notes</h5>
                    <div className={"d-flex justify-content-center withDescriptionField"}>
                        <div className="notescontent" dangerouslySetInnerHTML={{
                            __html: filterD?.itemnotes
                        }}>
                        </div>
                    </div>
                    <div className={'withDescriptionTitle'} style={{paddingTop: '14px'}}>
                        <h5>Attachments:</h5>
                        <Table responsive="sm" className={'mt-2'}>
                            <thead>
                            <tr style={{backgroundColor: '#7030a0', color: 'white'}}>
                                <th>File Name</th>
                                <th>File Size</th>
                                <th>Update Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                mediaDetails.map((media) =>
                                    <tr>
                                        <td>{media.filename}</td>
                                        <td>{media.filesize}</td>
                                        <td>
                                            {moment(media.created).format('d/M/YYYY')}
                                            <Button variant="contained" color="primary"
                                                    className="mx-2 btn-sm-height float-right"
                                                    onClick={() => downloadImage(media)}
                                            >Download</Button>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ViewNewsharedWish